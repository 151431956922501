import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMemo, useEffect, useCallback } from 'react';
import { useField, FormikValues, useFormikContext } from 'formik';
import {
  Icon,
  Step,
  Utils,
  FormCard,
  FlexLayout,
  ActionQueue,
  ButtonColors,
  FormCardTypes,
  SingleOptions,
  FormCardFields,
  FormCardRender,
  StandardButton,
  ValidationType,
  handleMutation,
  ActionQueueType,
  FormOptionsType,
  StepInstructions,
} from '@gv/triage-components';

import { Routes } from 'config';
import { clientTypeOptions } from 'types/data';
import { RootState, useAppSelector } from 'store';
import { useUpdatePetMutation } from 'store/api/clients';
import { hasNoneOption } from 'hooks/dynamic-form/helpers';
import { GreetingInfo } from 'components/cases/form/greeting';
import { joinRoutes, scrollCaseToError } from 'utils/helpers';
import { ClientField } from 'components/cases/form/client-field';
import { selectCurrentTask } from 'store/api/action-queue/selectors';
import {
  useFormEntities,
  DepartmentSelect,
  callOutcomeModel,
} from 'components/cases/form';
import {
  TextOutStep,
  CallOutcomeType,
  PetOwnerTemplate,
  MessageRecipientType,
} from 'types';
import {
  useSpeciesProps,
  useDynamicOptions,
  useOptionalHospitalData,
  useSchedulingMatrixProps,
} from 'hooks';
import {
  ClientTasksList,
  useClientTasksProps,
  AppointmentScheduled,
  SchedulingMatrixTable,
} from 'components';
import {
  useReferToPPH,
  useReferToOnCall,
  useReferToOtherEr,
  useCorporateOptions,
  useReferToCurrentEr,
  useSchedulingPaymentForm,
} from 'hooks/refer';

import { useOutcomeRulesValues } from '../useOutcomeRulesValues';

import { Aside } from './aside';
import * as Styles from './styles';
import { SubmitStep } from './submitStep';
import { DynamicCaseFormProps } from './types';
import {
  hasFieldType,
  getNextStepId,
  getNextOutcomeId,
  getSpeciesStepId,
  getShowDepartmentSelect,
  getDepartmentsSelectPath,
  getCorporateOptionsByOutcome,
} from './helpers';

const {
  Helpers: { fillObjectByPath },
  Object: { getValueByPath, shallowDifference },
} = Utils;

const {
  Cases: { Details },
  ActionCenter: { Comm, InComm },
} = Routes;

export const CaseDynamicForm = ({
  steps,
  isEdit,
  setDVM,
  isLoading,
  isTesting,
  activeStep,
  setService,
  hideFooter,
  setPayment,
  setReferral,
  onPlaceBack,
  stepQuestions,
  setActiveStep,
  onSubmitPress,
  setPassedSteps,
  onTransferMerge,
  setPaymentLinks,
  passedSteps = [],
  setValidationFields,
  passedStepsFieldValues,
}: DynamicCaseFormProps) => {
  const {
    values,
    resetForm,
    submitForm,
    validateForm,
    isSubmitting,
    initialValues,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<FormikValues>();
  const { hospitalId } = useFormEntities();
  const { hospital } = useOptionalHospitalData(hospitalId);
  const clientTasksProps = useClientTasksProps(isTesting);
  const dynamicOptions = useDynamicOptions({ isTesting });
  const referToPPHProps = useReferToPPH({ setService });
  const referToOtherErProps = useReferToOtherEr({ setReferral });
  const referToCurrentErProps = useReferToCurrentEr({ setReferral });
  const referToOnCallProps = useReferToOnCall({ setDVM });
  const { matricesList, matrixConfig } = useSchedulingMatrixProps(hospitalId);
  const { acceptableSpecies } = useSpeciesProps(hospitalId);
  const { ruleValues } = useOutcomeRulesValues({
    acceptance: referToCurrentErProps.acceptance,
    onCallDocsCount: referToOnCallProps.onCallDocProps.docsOptions?.length,
  });
  const { corporateGroupOptions, isCorporateGroupFetching } =
    useCorporateOptions(hospitalId);
  const { queryProps, isActiveHospitalPayment } = useSchedulingPaymentForm();

  const task = useAppSelector(selectCurrentTask);
  const { type } = task ?? {};

  const [updatePet, updateMutation] = useUpdatePetMutation();
  handleMutation({ ...updateMutation });

  const basePath = joinRoutes([Comm, Details.Consultation.Index], {});
  const addPetPath = joinRoutes([basePath, InComm.NewPet], {});
  const editPetPath = joinRoutes([basePath, 'pet'], {});
  const editClientPath = joinRoutes([basePath, InComm.EditClient], {});
  const medicalRecordsPath = `${basePath}${InComm.PetMedicalRecords}`;

  const isChatType = useMemo(() => type !== ActionQueueType.Voice, [type]);

  const [callOutComeTypes] = useField(callOutcomeModel.type);
  const isEmergency = callOutComeTypes.value === CallOutcomeType.Emergency;
  const isAppointment = callOutComeTypes.value === CallOutcomeType.Appointment;

  const currentActiveStep = useMemo(
    () => (activeStep ? steps[activeStep] : undefined),
    [activeStep, steps]
  );

  const { isFinal, outcome, subOutcome } = currentActiveStep ?? {};
  const isSubmitStep = !!isFinal;

  const widgetData = useMemo<Record<string, any>>(
    () => ({
      [FormCardTypes.Toxicity]: referToPPHProps,
      [FormCardTypes.OnCallDoc]: referToOnCallProps,
      [FormCardTypes.ReferToER]: referToCurrentErProps,
      [ValidationType.ReferToOtherER]: referToOtherErProps,
    }),
    [
      referToPPHProps,
      referToOnCallProps,
      referToOtherErProps,
      referToCurrentErProps,
    ]
  );

  const getFieldOptions = useCallback(
    (field: FormCardFields) => {
      const copy = { ...field };
      const { optionsType, formFieldProps, type: fieldType } = field;
      if (fieldType === FormCardTypes.PetInfo) {
        copy.editPetPath = editPetPath;
        copy.medicalRecordsPath = medicalRecordsPath;
      }
      if (optionsType) {
        copy.formFieldProps = {
          ...formFieldProps,
          ...(dynamicOptions[optionsType as FormOptionsType] ?? {}),
        };
      }
      if (hasNoneOption(formFieldProps?.name)) {
        copy.withNoneOption = true;
      }
      return copy;
    },
    [editPetPath, JSON.stringify(dynamicOptions)]
  );

  const currentStep = useMemo<Step | undefined>(
    () =>
      currentActiveStep && {
        ...currentActiveStep,
        widgets: currentActiveStep?.widgets?.map((widget) => {
          const showClientTasks = widget.id === SingleOptions.CancelAppointment;
          const isAppointmentScheduled =
            widget.id === SingleOptions.AppointmentDate;
          const additionalData = widget?.widgetType
            ? (widgetData[widget.widgetType] ?? {})
            : {};

          return {
            ...widget,
            ...additionalData,
            clientTasksList: showClientTasks ? (
              <ClientTasksList {...clientTasksProps} />
            ) : undefined,
            schedulingMartix: hasFieldType(
              FormCardTypes.SchedulingMatrix,
              widget
            ) ? (
              <SchedulingMatrixTable
                widgetView
                horizontalScroll
                matrices={matricesList}
                matrixConfig={matrixConfig}
              />
            ) : undefined,
            customNode: isAppointmentScheduled ? (
              <FlexLayout gap={16} flexDirection="column">
                <AppointmentScheduled
                  hideNotes
                  hideCheckProtocols
                  setPayment={setPayment}
                  hospitalPrices={queryProps.data}
                  clientTypeOptions={clientTypeOptions}
                  isCorporateFetching={isCorporateGroupFetching}
                  isActiveHospitalPayment={isActiveHospitalPayment}
                  corporateOptions={corporateGroupOptions?.scheduled}
                />
              </FlexLayout>
            ) : undefined,
            questions: widget?.questions?.map((question) => {
              const isPet = question.validationType === ValidationType.PetInfo;
              const isClient =
                question.validationType === ValidationType.ClientInfo;
              return {
                ...question,
                isEdit: isClient ? true : false,
                fields: question?.fields?.map((field: FormCardFields) =>
                  getFieldOptions(field)
                ),
                requiredFields: [
                  ...(question?.requiredFields ?? []),
                  ...(additionalData.requiredFields ?? []),
                ],
                addPath: isEdit
                  ? ''
                  : isClient
                    ? editClientPath
                    : isPet && hospital?.type !== 2
                      ? addPetPath
                      : '',
              };
            }),
          };
        }),
      },
    [
      isEdit,
      setPayment,
      matricesList,
      matrixConfig,
      queryProps.data,
      getFieldOptions,
      currentActiveStep,
      clientTasksProps.data,
      isActiveHospitalPayment,
      isCorporateGroupFetching,
      JSON.stringify(widgetData),
      corporateGroupOptions?.scheduled,
    ]
  );

  const activeStepIndex = useMemo(
    () => passedSteps.findIndex((item) => item.id === activeStep),
    [activeStep, passedSteps]
  );

  const showDepartmentSelect = useMemo(
    () => getShowDepartmentSelect({ hospital, selectedStep: currentStep }),
    [hospital, currentStep]
  );

  const corporateOptions = useMemo(
    () =>
      getCorporateOptionsByOutcome({
        corporateGroupOptions,
        selectedStep: currentStep,
      }),
    [corporateGroupOptions, currentStep]
  );

  const { required: stepRequiredFields, fieldsNames: currentStepFieldsNames } =
    useMemo(() => {
      const initial: {
        required: string[];
        fieldsNames: string[];
      } = { required: [], fieldsNames: [] };
      if (!currentStep) {
        return initial;
      }

      if (
        getShowDepartmentSelect({
          hospital,
          allOutcomes: true,
          selectedStep: currentStep,
        })
      ) {
        initial.fieldsNames.push(getDepartmentsSelectPath(currentStep));
      }

      return (
        currentStep?.widgets?.reduce((accum, widget) => {
          widget?.questions?.forEach((question: FormCardRender) => {
            if (question?.questionFields) {
              accum.fieldsNames = accum.fieldsNames.concat(
                question.questionFields
              );
            }

            const names =
              question?.fields
                ?.reduce<Array<string>>((sum, field) => {
                  sum.push(field?.formFieldProps?.name ?? '');
                  sum.push(field?.checkboxProps?.name ?? '');
                  return sum;
                }, [])
                ?.filter((item: string) => !!item) ?? [];

            accum.fieldsNames = accum.fieldsNames.concat(names);
            accum.required = accum.required.concat(
              question?.requiredFields ?? []
            );
          });
          return accum;
        }, initial) ?? initial
      );
    }, [currentStep]);

  const currentStepFieldValues = useMemo(() => {
    let stateCopy: Record<string, any> = {};

    currentStepFieldsNames.forEach((fieldName) => {
      const pathValue = getValueByPath(values, fieldName);
      stateCopy = fillObjectByPath(stateCopy, pathValue, fieldName);
    });

    return stateCopy;
  }, [values, currentStepFieldsNames]);

  const stepsToReplace = useMemo(() => {
    if (!passedSteps.length) {
      return [];
    }

    return passedSteps.map((step, idx) =>
      idx === activeStepIndex
        ? {
            ...step,
            fieldsState: currentStepFieldValues,
            stateCopy: JSON.stringify(shallowDifference(initialValues, values)),
          }
        : step
    );
  }, [
    values,
    passedSteps,
    initialValues,
    activeStepIndex,
    currentStepFieldValues,
  ]);

  const onNextStep = useCallback(async () => {
    if (currentStep?.isFinal) {
      return;
    }

    const errors = await validateForm(values);
    const stepErrors: string[] = [];
    const hasPetInfo = stepRequiredFields.some((name) => name.includes('pet.'));

    stepRequiredFields.forEach((requiredName) => {
      const err =
        Object.keys(errors).length && getValueByPath(errors, requiredName);
      if (err) {
        stepErrors.push(err);
      }
    });
    [
      ...currentStepFieldsNames,
      ...(hasPetInfo ? ['clientPets.0.name'] : []),
    ]?.forEach((name) => setFieldTouched(name, true));

    if (
      stepErrors.length ||
      (hasPetInfo && (errors?.pets || errors?.clientPets))
    ) {
      scrollCaseToError(errors);
      return;
    }

    const speciesRuleStepId = getSpeciesStepId({
      values,
      acceptableSpecies,
      speciesRules: currentActiveStep?.speciesRules,
    });

    const nextRuleStepId =
      speciesRuleStepId ??
      getNextStepId({
        passedStepsFieldValues,
        currentStepFieldValues,
        currentStep: currentActiveStep,
      });
    const nextStep = steps?.[nextRuleStepId];
    const nextOutcomeId = getNextOutcomeId({
      ruleValues,
      outcomeRules: nextStep?.outcomeRules,
    });
    const nextStepId = nextOutcomeId || nextRuleStepId;

    if (steps?.[nextStepId]) {
      if (steps[nextStepId]?.isFinal) {
        setFieldValue('submitRules', currentStep?.submitRules ?? []);
      }
      const nextPassed = passedSteps[activeStepIndex + 1];
      const nextNotSame = nextPassed && nextPassed?.id !== nextStepId;
      const filtered = stepsToReplace.filter(
        (step, idx) =>
          !step?.hideStep && (nextNotSame ? idx <= activeStepIndex : true)
      );
      setActiveStep(nextStepId);
      setPassedSteps?.(
        filtered.some((item) => item.id === nextStepId)
          ? filtered
          : [...filtered, steps[nextStepId]]
      );
    }
  }, [
    steps,
    values,
    ruleValues,
    setPassedSteps,
    stepsToReplace,
    activeStepIndex,
    currentActiveStep,
    acceptableSpecies,
    stepRequiredFields,
    currentStep?.isFinal,
    currentStepFieldsNames,
    passedStepsFieldValues,
    currentStepFieldValues,
  ]);

  const onInstruction = useCallback(
    (actionId: string) => {
      const instructionStep = steps[actionId];
      if (instructionStep && activeStepIndex >= 0) {
        const passed = [...passedSteps];
        passed.splice(activeStepIndex, 1, instructionStep);
        setPassedSteps(passed);
        setActiveStep(actionId);
      }
    },
    [steps, passedSteps, activeStepIndex, setPassedSteps, setActiveStep]
  );
  let isSubmitDisabled = false;
  if (isEmergency || isAppointment) {
    isSubmitDisabled = Boolean(
      !values.checkbox1 || !values.checkbox2 || !values.checkbox3
    );
  }

  useEffect(() => {
    if (activeStepIndex <= passedSteps.length - 1) {
      const idxWithRules = passedSteps.findIndex(
        (stp, indx) => indx >= activeStepIndex && !!stp.nextStep?.rules?.length
      );
      const newPassed = passedSteps.map((passed, idx) => ({
        ...passed,
        hideStep:
          idx > activeStepIndex &&
          passedSteps?.[passedSteps.length - 1].stateCopy !==
            JSON.stringify(shallowDifference(initialValues, values)) &&
          idxWithRules >= 0 &&
          idx > idxWithRules,
      }));
      setPassedSteps(newPassed);
    }
  }, [values]);

  const showNewClient = useMemo(() => {
    const hasClientWidget = !!currentStep?.widgets?.some(
      (widget) => !!(widget?.widgetType === FormCardTypes.ClientInfo)
    );
    return hasClientWidget && !isTesting && !values?.client_id;
  }, [values?.client_id, currentStep?.widgets, isTesting]);

  useEffect(() => {
    const hasErValidation = stepQuestions.some(
      (question) => question.validationType === ValidationType.ReferToER
    );
    const hasOnCallValidation = stepQuestions.some(
      (question) => question.validationType === ValidationType.ReferToOnCall
    );
    const names = widgetData[FormCardTypes.ReferToER]?.requiredFields;
    const onCallNames = widgetData[FormCardTypes.OnCallDoc]?.requiredFields;

    setValidationFields({
      [ValidationType.ReferToER]: hasErValidation ? names : [],
      [ValidationType.ReferToOnCall]: hasOnCallValidation ? onCallNames : [],
    });
  }, [activeStep, JSON.stringify(widgetData)]);

  useEffect(() => {
    if (outcome && subOutcome) {
      setFieldValue('callOutcome.type', outcome);
      setFieldValue(`callOutcome.${outcome}.type`, subOutcome);
    }
  }, [outcome, subOutcome]);

  const { isButtonHide } = useSelector(
    (state: RootState) => state.analyticsExport
  );

  const isEditFeedbackForm = location.pathname.includes('consultation/edit');
  const isDynamicFlowOrderChanged = process.env.REACT_APP_CHANGE_DYNAMIC_ORDER;

  const isHideBreed = isDynamicFlowOrderChanged
    ? JSON.parse(isDynamicFlowOrderChanged).some(
        (item: any) => item == hospitalId
      )
    : false;

  return (
    <Styles.Container>
      <Aside
        isLoading={isLoading}
        activeStep={activeStep}
        passedSteps={passedSteps}
        setActiveStep={setActiveStep}
        setPassedSteps={setPassedSteps}
        stepsToReplace={stepsToReplace}
      />

      <Styles.Main>
        <Styles.StyledList
          count={1}
          isLoading={isLoading}
          mainWrapperStyles={{
            gap: '24px',
            display: 'flex',
            flexDirection: 'column',
            padding: `0 ${isChatType ? 60 : 120}px 16px`,
          }}
          header={
            <Styles.Header isChat={isChatType}>
              {currentStep?.title === 'Greeting and call reason' ? null : (
                <h2>
                  {currentStep?.title === 'Submit case'
                    ? ' Select call outcome'
                    : currentStep?.title}
                </h2>
              )}
              {currentStep?.script && (
                <Styles.Script>{currentStep?.script}</Styles.Script>
              )}
            </Styles.Header>
          }
        >
          <>
            {activeStepIndex === 0 && (
              <Box mt={2}>
                <Styles.ParaDynamic2>
                  {currentStep?.widgets?.[0]?.questions?.[0]?.subtitle
                    ? currentStep?.widgets[0]?.questions[0]?.subtitle
                    : currentStep?.widgets?.[2]?.questions?.[0]?.subtitle}
                </Styles.ParaDynamic2>
              </Box>
            )}
            {showNewClient ? (
              <ClientField task={task ?? ({} as ActionQueue)} />
            ) : isSubmitStep ? (
              <SubmitStep />
            ) : (
              <FlexLayout flexDirection="column">
                {showDepartmentSelect && (
                  <DepartmentSelect
                    options={corporateOptions}
                    isDisabled={isCorporateGroupFetching}
                    name={getDepartmentsSelectPath(currentStep)}
                  />
                )}
                {currentStep?.widgets?.map(
                  ({ questions, widgetType, ...rest }, indx) => {
                    return (
                      <FormCard
                        key={indx}
                        isEdit={isEdit}
                        isTesting={isTesting}
                        onNextClick={onNextStep}
                        onPlaceBack={onPlaceBack}
                        isHideBreed={isHideBreed}
                        isEditDisable={hospital?.type === 2}
                        hospitalType={Number(hospital?.type)}
                        transferToBacklineProps={{
                          transfers: rest?.transfers ?? [],
                          onClick: () => onTransferMerge?.(true),
                        }}
                        onDeceasedChange={(payload) => {
                          if (values?.client_id && !isTesting) {
                            updatePet({
                              ...payload,
                              client_id: Number(values.client_id),
                            });
                          }
                        }}
                        render={
                          activeStepIndex === 0
                            ? questions.map((question, index) =>
                                index === 0
                                  ? { ...question, subtitle: '' }
                                  : question
                              )
                            : questions
                        }
                        paymentLinksProps={{
                          isInlineGrid: true,
                          grid: {
                            gap: '16px',
                            template: 'auto / auto',
                          },
                          onSendLinks: () => {
                            setPaymentLinks?.({
                              textOutStep: TextOutStep.TemplateInfo,
                              petOwnerTemplate: PetOwnerTemplate.PaymentLinks,
                              selectedRecipientType:
                                MessageRecipientType.PetOwner,
                            });
                          },
                        }}
                        {...rest}
                      />
                    );
                  }
                )}
              </FlexLayout>
            )}

            {activeStepIndex === 0 && (
              <GreetingInfo
                isDynamicFlow={true}
                complaintsValue={values.chief_complaints}
              />
            )}

            <StepInstructions
              steps={steps}
              onClick={onInstruction}
              instructions={currentStep?.instructions}
            />
          </>
        </Styles.StyledList>

        {!isLoading && !hideFooter && (
          <Styles.Footer
            isChat={isChatType}
            left={
              activeStepIndex > 0 ? (
                <Styles.BackButton
                  icon={<Icon.ChevronLeft />}
                  colorType={ButtonColors.Secondary}
                  onClick={() =>
                    setActiveStep(passedSteps[activeStepIndex - 1]?.id)
                  }
                />
              ) : undefined
            }
            right={
              isSubmitStep ? (
                <StandardButton
                  text="Submit"
                  colorType={ButtonColors.LightGreen}
                  disabled={
                    isEditFeedbackForm
                      ? isSubmitting
                      : isButtonHide || isSubmitDisabled
                  }
                  onClick={() => {
                    if (isTesting) {
                      resetForm();
                    } else if (onSubmitPress) {
                      onSubmitPress();
                    } else {
                      submitForm();
                    }
                  }}
                />
              ) : (
                <StandardButton
                  text="Next"
                  onClick={onNextStep}
                  disabled={
                    values?.problem_summary === null ||
                    values?.chief_complaints?.length === 0
                    // isSubmitting || showNewClient
                  }
                />
              )
            }
          />
        )}
      </Styles.Main>
    </Styles.Container>
  );
};
