import {
  ActionQueue,
  CustomInvoiceOption,
  ReferToErAcceptancePath,
  ReferToOnCallAcceptacePath,
} from '@gv/triage-components';

import { api } from 'api';
import Mutex from 'utils/mutex';
import { RootState } from 'store/store';
import { getSavedForm } from 'utils/storage';
import { initialCaseForm } from 'utils/initials';
import { setLoading } from 'store/slices/loader';
import { ActionQueueTypeNumber } from 'types/data';
import { createAppAsyncThunk } from 'store/helpers';
import { assessmentsRadioGridInputProps } from 'utils/config';
import {
  CreateCaseRequest,
  SubmitCaseRequest,
  UpdateCaseRequest,
} from 'api/cases/types';
import {
  isNumber,
  processCase,
  getQueueItemId,
  getCallOutcomePayload,
} from 'utils/helpers';
import {
  TaskPriority,
  SubmitCaseData,
  CaseSubmitRules,
  CallOutcomeType,
  EntityPortalType,
  CallOutcomeAppointment,
} from 'types';

import { updateForm, updateTask } from '../action-queue';
import { selectFormByTaskId } from '../action-queue/selectors';

import { Case, types, CasePayloadType, CreateUpdateCase } from './types';

export const booleanFormFields = [
  'isNew',
  'stable',
  'isMobile',
  'case_type',
  'has_action',
  'isClientDone',
  'isNameConfirm',
  'current_client',
  'is_incompleted',
  'isEmailConfirm',
  'isNumberConfirm',
];

/**
 *
 * @param task The ActionQueue
 * @param state The state
 * @param valuesData The values data to feed into
 * @param isSubmitted If the case is submitted
 * @param isDynamicFlow If the case is dynamic flow
 */
export const getData = async ({
  task,
  state,
  valuesData,
  isSubmitted,
  isDynamicFlow,
}: {
  valuesData: any;
  task?: ActionQueue;
  isSubmitted?: boolean;
  isDynamicFlow?: boolean;
  state: Partial<RootState>;
}) => {
  const values = { ...valuesData };
  if (values.email) {
    values.email = String(values.email).toLowerCase().trim();
  }
  const is_submitted = !!isSubmitted;

  const loginResponse = state?.auth?.user;

  const {
    sex,
    eta,
    age,
    pets,
    name,
    email,
    breed,
    pet_id,
    species,
    isMobile,
    cvt_plan,
    csr_plan,
    appetite,
    last_seen,
    case_type,
    client_id,
    has_action,
    departments,
    hospital_id,
    submitRules,
    callOutcome,
    client_name,
    call_outcome,
    current_step,
    country_code,
    client_phone,
    isMobileFalse,
    isNameConfirm,
    eta_to_clinic,
    customService,
    internal_notes,
    care_doctor_id,
    is_incompleted,
    isEmailConfirm,
    activity_level,
    current_client,
    confirmSpecies,
    technical_issue,
    follow_up_other,
    isNumberConfirm,
    client_hospital,
    problem_summary,
    follow_up_steps,
    max_opened_step,
    assessment_note,
    care_hospital_id,
    additional_phone,
    pain_level_other,
    additional_notes,
    medications_list,
    confirmEmergency,
    chief_complaints,
    brief_description,
    medications_notes,
    email_for_invoice,
    general_stability,
    client_local_time,
    deposit_invoice_id,
    case_report_review,
    priority_of_concern,
    triageLevelOfConcern,
    activity_level_other,
    respiratory_stability,
    neurological_stability,
    is_species_seen_confirm,
    chronial_medical_issues,
    is_emergency_fee_confirm,
    client_denied_er_form_notes,
    respiratory_stability_other,
    neurological_stability_other,
    specify_administrative_issue,
    pain_level_assessed_by_caller,
    respiratory_stability_abnormal,
    recommendation_Level_Of_Concern,
    ...restProps
  } = values;

  const attend_by = [...values.attend_by];

  if (
    loginResponse &&
    loginResponse.id &&
    attend_by &&
    attend_by.findIndex((val: any) => Number(val) === loginResponse.id) === -1
  ) {
    attend_by.push(loginResponse.id.toString());
  }

  let follow_up_steps_copy = follow_up_steps;

  if (
    follow_up_steps &&
    follow_up_steps.length > 0 &&
    (typeof follow_up_steps === 'string' || follow_up_steps instanceof String)
  ) {
    follow_up_steps_copy = JSON.parse(follow_up_steps as string);
  } else if (
    !follow_up_steps &&
    (typeof follow_up_steps === 'string' || follow_up_steps instanceof String)
  ) {
    follow_up_steps_copy = [];
  }

  let follow_up_cleanedArray =
    follow_up_steps_copy &&
    follow_up_steps_copy.filter((item: string) => {
      return !(
        item === 'No follow up needed' ||
        item === 'Clinic to call client' ||
        item === 'Client to call clinic'
      );
    });
  if (follow_up_cleanedArray && follow_up_cleanedArray.length > 0) {
    follow_up_steps_copy = follow_up_steps_copy.filter(
      (el: string) => !follow_up_cleanedArray.includes(el)
    );
  }

  if (follow_up_other) {
    follow_up_steps_copy.push(follow_up_other);
  }

  const isMobileField =
    isMobile && Number(isMobile) === 1
      ? 1
      : isMobileFalse && Number(isMobileFalse) === 1
        ? 0
        : isMobile;

  const isCustomHospital = care_hospital_id && !isNumber(care_hospital_id);
  const isCustomDoctor = care_hospital_id && !isNumber(care_doctor_id);

  const callOutcomePayload: {
    callOutcome: Partial<Case['callOutcome']>;
  } & Pick<Case, 'parentTaskId' | 'corporate_hospital_id'> = {
    callOutcome: null,
    corporate_hospital_id: null,
  };

  const payload = getCallOutcomePayload(callOutcome);
  if (payload) {
    const { parentTaskId, corporate_hospital_id, ...rest } = payload;

    callOutcomePayload.corporate_hospital_id = corporate_hospital_id;
    callOutcomePayload.callOutcome = rest;
    callOutcomePayload.parentTaskId = parentTaskId;
  }

  let data: any = {
    ...(isDynamicFlow ? restProps : {}),
    age,
    breed,
    appetite,
    assessment_note,
    departments: departments || [],
    chief_complaints: JSON.stringify(chief_complaints),
    ...callOutcomePayload,
    cvt_plan,
    csr_plan,
    client_id,
    client_name,
    current_step,
    country_code,
    client_phone,
    current_client,
    confirmSpecies,
    max_opened_step,
    confirmEmergency,
    client_local_time,
    email_for_invoice,
    chronial_medical_issues,
    client_denied_er_form_notes,
    ...(eta && { eta }),
    sex,
    name,
    species,
    last_seen,
    case_type,
    has_action,
    is_submitted,
    eta_to_clinic,
    customService,
    internal_notes,
    technical_issue,
    client_hospital,
    problem_summary,
    additional_phone,
    additional_notes,
    brief_description,
    medications_notes,
    general_stability,
    case_report_review,
    triageLevelOfConcern,
    is_species_seen_confirm,
    is_emergency_fee_confirm,
    email: email && email.trim(),
    respiratory_stability_abnormal,
    isMobile: Number(isMobileField),
    recommendation_Level_Of_Concern,
    isNameConfirm: Number(isNameConfirm),
    attend_by: JSON.stringify(attend_by),
    isEmailConfirm: Number(isEmailConfirm),
    isNumberConfirm: Number(isNumberConfirm),
    hospital_id: hospital_id ? Number(hospital_id) : null,
    is_incompleted: is_submitted ? false : is_incompleted,
    activity_level: activity_level_other || activity_level,
    price_id: deposit_invoice_id ? +deposit_invoice_id : undefined,
    pet_id: pets?.length ? pets[0] : pet_id ? Number(pet_id) : null,
    call_outcome: call_outcome?.length ? JSON.stringify(call_outcome) : '',
    respiratory_stability: respiratory_stability_other || respiratory_stability,
    priority_of_concern:
      priority_of_concern === '' ? null : priority_of_concern,
    neurological_stability:
      neurological_stability_other || neurological_stability,
    pain_level_assessed_by_caller:
      pain_level_other || pain_level_assessed_by_caller,
    custom_care_doctor:
      isCustomDoctor && care_doctor_id?.length ? care_doctor_id : null,
    care_doctor_id:
      !isCustomDoctor && care_doctor_id?.length ? Number(care_doctor_id) : null,
    custom_care_hospital:
      isCustomHospital && care_hospital_id?.length ? care_hospital_id : null,
    care_hospital_id:
      !isCustomHospital && care_hospital_id?.length
        ? Number(care_hospital_id)
        : null,
    specify_administrative_issue: specify_administrative_issue?.length
      ? JSON.stringify(specify_administrative_issue)
      : null,
    // follow_up_steps: follow_up_steps && follow_up_steps.length ? JSON.stringify(follow_up_steps) : "",
    follow_up_steps:
      follow_up_steps_copy && follow_up_steps_copy?.length
        ? JSON.stringify(follow_up_steps_copy)
        : '',
    deposit_invoice_id:
      deposit_invoice_id === CustomInvoiceOption.Custom || !deposit_invoice_id
        ? undefined
        : Number(deposit_invoice_id),
    medications_list: JSON.stringify(
      Array.isArray(medications_list) && medications_list?.length
        ? medications_list.map((value: any) => ({
            medications_brand_name: value,
          }))
        : []
    ),
  };

  data.rules = [CaseSubmitRules.CreateTask, CaseSubmitRules.SendEmail];

  if (typeof data.case_type !== 'number') {
    data.case_type = task?.type && ActionQueueTypeNumber[task.type];
  }

  if (pets?.length) {
    data.pets = pets;
  }

  if (data?.customService?.length > 0 && !data?.invoiceId) {
    try {
      const service = await api.hospitalServices.createHospitalService({
        name: data.customService,
        hospitalId: data.hospital_id,
      });

      data.veterinary_service_id = service.data.id;
      delete data?.customService;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  for (const row of assessmentsRadioGridInputProps.rows) {
    const { fieldName } = row;
    const value = data[fieldName];
    if (typeof value === 'string' && value.length === 0) {
      data[fieldName] = null;
    }
  }

  if (isDynamicFlow) {
    data.rules = submitRules ?? [];
    data.own_fields = valuesData?.own_fields ?? {};
    const acceptanceState: Record<string, any> = {};
    const acceptanceStateItems = [
      ...Object.values(ReferToErAcceptancePath),
      ...Object.values(ReferToOnCallAcceptacePath),
    ];

    acceptanceStateItems.forEach((key: string) => {
      const [acceptanceName] = key.split('.').reverse();
      if (acceptanceName) {
        acceptanceState[acceptanceName] =
          valuesData?.acceptance_state?.[acceptanceName] ?? '';
      }
    });
    data.acceptance_state = acceptanceState;
  }

  return data;
};

const allowedProps = [
  'pets',
  'pet_id',
  'csr_plan',
  'cvt_plan',
  'appetite',
  'price_id',
  'last_seen',
  'client_id',
  'own_fields',
  'callReason',
  'callOutcome',
  'parentTaskId',
  'isNameConfirm',
  'care_doctor_id',
  'isEmailConfirm',
  'activity_level',
  'internal_notes',
  'emergency_type',
  'current_client',
  'additinal_notes',
  'problem_summary',
  'assessment_note',
  'isNumberConfirm',
  'technical_issue',
  'care_hospital_id',
  'acceptance_state',
  'additional_notes',
  'medications_list',
  'chief_complaints',
  'appointment_type',
  'email_for_invoice',
  'general_stability',
  'client_issue_type',
  'brief_description',
  'custom_care_doctor',
  'deposit_invoice_id',
  'case_report_review',
  'priority_of_concern',
  'client_denied_er_form_notes',
  'custom_care_hospital',
  'veterinary_service_id',
  'respiratory_stability',
  'corporate_hospital_id',
  'neurological_stability',
  'chronial_medical_issues',
  'is_species_seen_confirm',
  'is_emergency_fee_confirm',
  'specify_administrative_issue',
  'pain_level_assessed_by_caller',
];

export const parseDefaultValue = (value: any) => {
  if (value === '') {
    return null;
  }
  return value;
};

export const rewriteProps = (data: Record<string, any>) =>
  allowedProps.reduce<Record<string, any>>(
    (newData, key) => ({
      ...newData,
      [key]:
        data[key] ||
        parseDefaultValue((initialCaseForm() as Record<string, any>)[key]),
    }),
    {}
  );

export const getPayloadByType = (
  data: any,
  type: CasePayloadType
):
  | CreateCaseRequest
  | SubmitCaseRequest['data']
  | UpdateCaseRequest['data'] => {
  const {
    pets,
    rules,
    pet_id,
    case_type,
    client_id,
    hospital_id,
    client_phone,
    deposit_invoice_id,
  } = data;
  const payload: Record<string, any> = { ...data };
  const { details, type: outcomeType } = payload.callOutcome ?? {};
  const { datetime, appointment } = details ?? {};

  switch (type) {
    case CasePayloadType.Submit: {
      let submitPayload: SubmitCaseRequest['data'] = { rules };
      if (
        appointment &&
        rules.includes(CaseSubmitRules.CreateTask) &&
        outcomeType === CallOutcomeType.Appointment &&
        appointment === CallOutcomeAppointment.Scheduled
      ) {
        submitPayload = {
          ...submitPayload,
          appointment: {
            date: datetime,
            paymentPriceId: deposit_invoice_id,
          },
          task: {
            priority: TaskPriority.High,
            portalType: EntityPortalType.Team,
          },
        };
      }
      return submitPayload;
    }
    case CasePayloadType.Update: {
      delete payload.rules;
      return {
        ...rewriteProps(payload),
        callOutcome: !outcomeType || !details ? undefined : payload.callOutcome,
      };
    }
    case CasePayloadType.Create:
      return {
        caseType: case_type,
        petId: pet_id ?? undefined,
        hospitalId: Number(hospital_id),
        pets: pets?.length ? pets : undefined,
        clientPhone: client_phone || undefined,
        clientId: client_id ? Number(client_id) : undefined,
      };

    default:
      return payload;
  }
};

export const fillSavedFormIfExist = (
  values: any,
  id: string,
  userId?: number
) => {
  if (!userId) {
    return values;
  }
  const form = getSavedForm(id, userId);
  if (form) {
    return {
      ...values,
      ...form,
    };
  }
  return values;
};

const updateCaseIdHistory = async (props: CreateUpdateCase, dispatch: any) => {
  const { task, values } = props;

  if (!values.id) {
    return undefined;
  }

  console.log('task.roomId', task.roomId);

  const data = {
    caseId: Number(values.id),
    channelName: task.channelName ?? task.channel_name,
    clientId: values.client_id ? Number(values.client_id) : null,
    roomId:
      typeof task.roomId === 'object'
        ? (task.roomId as { clientId: number })?.clientId
        : task.roomId,
  };

  try {
    const response = await api.cases.updateCaseChatChannel(data);
    const updatedTask = response.data.data.updatedHistory;
    if (updatedTask) {
      const { c_id, name, case_id, room_id, channelName, channel_name } =
        updatedTask;
      const payload: Partial<ActionQueue> = {
        c_id,
        case_id,
        channelName,
        channel_name,
        c_name: name,
        roomId: room_id,
      };
      dispatch(updateTask({ changes: payload }));
    }
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const createCase = createAppAsyncThunk(
  types.createCase,
  async (props: CreateUpdateCase, { dispatch, getState }) => {
    const state = getState();
    dispatch(setLoading(true));
    const { task, values, isClientDone, isDynamicFlow } = props;

    try {
      const data = await getData({
        task,
        state,
        isDynamicFlow,
        valuesData: values,
      });

      const response = await api.cases.createCase(
        getPayloadByType(data, CasePayloadType.Create)
      );
      console.log('createdCase');
      let caseForm = fillSavedFormIfExist(
        processCase(response.data.data),
        getQueueItemId(task),
        state?.auth?.user?.id
      );
      if (isClientDone) {
        caseForm.isClientDone = true;
      }

      await dispatch(updateForm({ task, form: caseForm }));
      await updateCaseIdHistory({ task, values: caseForm }, dispatch);
      dispatch(setLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setLoading(false));
      return Promise.reject(error);
    }
  }
);

export const updateCase = createAppAsyncThunk(
  types.updateCase,
  async (props: CreateUpdateCase, { dispatch, getState }) => {
    const state = getState();
    const {
      task,
      values,
      silent,
      isSubmitted,
      hideLoading,
      isClientDone,
      isDynamicFlow,
    } = props;
    if (!hideLoading) {
      dispatch(setLoading(true));
    }
    const form = selectFormByTaskId(getQueueItemId(task))(state);

    try {
      let response;
      const data = await getData({
        task,
        state,
        isSubmitted,
        isDynamicFlow,
        valuesData: { ...values, brief_description: values.problem_summary },
      });

      const caseId = values.id ?? form?.id;

      if (caseId) {
        data.case_id = caseId;
        response = await api.cases.updateCase({
          caseId,
          data: getPayloadByType(data, CasePayloadType.Update),
        });
        console.log('updated case', caseId);

        let caseForm = fillSavedFormIfExist(
          processCase(response.data.data),
          getQueueItemId(task),
          state?.auth?.user?.id
        );
        if (isClientDone) {
          caseForm.isClientDone = true;
        }

        if (!silent) {
          await updateCaseIdHistory({ task, values: caseForm }, dispatch);
          await dispatch(updateForm({ task, form: caseForm }));
        }
      }
      if (!hideLoading) {
        dispatch(setLoading(false));
      }
    } catch (error) {
      console.error(error);
      if (!hideLoading) {
        dispatch(setLoading(false));
      }
      return Promise.reject(error);
    }
  }
);

export const submitCase = createAppAsyncThunk(
  types.submitCase,
  async (props: CreateUpdateCase, { dispatch, getState }) => {
    const state = getState();
    dispatch(setLoading(true));
    const { task, values, isSubmitted, isClientDone, isDynamicFlow } = props;
    const form = selectFormByTaskId(getQueueItemId(task))(state);

    try {
      const data = await getData({
        task,
        state,
        isSubmitted,
        isDynamicFlow,
        valuesData: values,
      });

      const caseId = values.id ?? form?.id;

      if (caseId) {
        data.case_id = caseId;
        const response = await api.cases.submitCase({
          caseId,
          data: getPayloadByType(
            data,
            CasePayloadType.Submit
          ) as SubmitCaseData,
        });
        console.log('submited case', caseId);

        let caseForm = fillSavedFormIfExist(
          processCase(response.data.data.caseForm),
          getQueueItemId(task),
          state?.auth?.user?.id
        );
        if (isClientDone) {
          caseForm.isClientDone = true;
        }
        await dispatch(updateForm({ task, form: caseForm }));
      }
    } catch (error) {
      console.error(error);
      dispatch(setLoading(false));
      return Promise.reject(error);
    }

    dispatch(setLoading(false));
  }
);

export const createUpdateSubmit = createAppAsyncThunk(
  types.createUpdateSubmit,
  async (props: CreateUpdateCase, { dispatch }) => {
    let releaser;
    try {
      const { values } = props;
      let caseId = values.id;
      releaser = await Mutex.acquire(`submit-case-${caseId || 'new'}`);
      if (!caseId) {
        await dispatch(createCase(props)).unwrap();
      }

      await dispatch(updateCase(props)).unwrap();
      await dispatch(submitCase(props)).unwrap();
    } finally {
      releaser?.();
    }
  }
);
