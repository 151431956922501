import { useSelector } from 'react-redux';
import { useMemo, useState, useEffect } from 'react';
import { Formik, FormikValues, useFormikContext } from 'formik';
import {
  Row,
  Col,
  Dialog,
  isMobile,
  isDesktop,
  InputField,
  DialogType,
  FlexLayout,
  ButtonTypes,
  ButtonColors,
  StandardButton,
  RadioFieldGroup,
  ButtonStyleTypes,
  StandardButtonWithRef,
} from '@gv/triage-components';

import { api } from 'api';
import { Config } from 'config';
import { CallOutcomeType } from 'types';
import { InternalReportField } from 'components';
import { useCorporateOptions } from 'hooks/refer';
import { surveyModal } from 'components/cases/form/config';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { setIsShowFeedback } from 'store/slices/analytics-export';
import { useDetailsQuery as useClientQuery } from 'store/api/clients';
import { selectCurrentTaskForm } from 'store/api/action-queue/selectors';
import { CallOutcomeCheckboxes } from 'components/cases/form/call-outcome/checkboxes';
import {
  CallOutcomeRefer,
  CallOutcomeAction,
} from 'components/cases/form/call-outcome';
import {
  useFormEntities,
  callOutcomeModel,
  CheckProtocolsInfo,
} from 'components/cases/form';

import * as Styles from './styles';
import { FormValues } from './types';
import { getInitialValues } from './config';

export const SubmitStep = () => {
  const mobile = isMobile();
  const desktop = isDesktop();
  const dispatch = useAppDispatch();
  const [isAdminAlert, setAdminAlert] = useState(false);
  const { isShowFeedback } = useSelector(
    (state: RootState) => state.analyticsExport
  );
  const { values, setValues, setFieldValue } = useFormikContext<FormikValues>();
  const { clientId, hospital, hospitalId } = useFormEntities();
  const { corporateGroupOptions, isCorporateGroupFetching } =
    useCorporateOptions(hospitalId);

  const showFeedback = async () => {
    const response =
      await api.customerFeedback.checkTypeFormHospital(hospitalId);
    dispatch(setIsShowFeedback(response.data?.data?.status));
  };

  const caseForm = useAppSelector(selectCurrentTaskForm);

  const { data: client } = useClientQuery(
    { hospitalId, id: clientId!, allowDeleted: true },
    {
      skip: !clientId,
    }
  );
  const {
    hideCallOutcomeTypes,
    blockedCallOutcomeTypes,
    hospitalCallOutcomeTitles,
    hasAccessToTransferBackline,
  } = useMemo(
    () => ({
      hideCallOutcomeTypes: Config.helpers.getHideCallOutcomeTypes(hospitalId),
      hospitalCallOutcomeTitles:
        Config.helpers.getCallOutcomeTitles(hospitalId),
      blockedCallOutcomeTypes:
        Config.helpers.getBlockedCallOutcomeTypes(hospitalId),
      hasAccessToTransferBackline: Config.helpers.featureAvailableForHospital(
        hospitalId,
        'CALL_OUTCOME_TRANSFER_TO_BACKLINE'
      ),
    }),
    [hospitalId]
  );

  useEffect(() => {
    showFeedback();
    if (caseForm?.client === undefined) {
      setFieldValue(
        surveyModal.feedback_email,
        client?.emails && client?.emails[0]
      );
    } else {
      setFieldValue(surveyModal.feedback_email, caseForm?.client?.email);
    }
  }, []);
  return (
    <div>
      <FlexLayout gap={24} flexDirection="column">
        <div>
          <RadioFieldGroup
            label=""
            name={callOutcomeModel.type}
            onChange={(event) => {
              setAdminAlert(false);
              if (event === CallOutcomeType.Administrative) {
                setAdminAlert(true);
              }
            }}
            items={[
              {
                label: 'Appointment',
                value: CallOutcomeType.Appointment,
                hasAccess:
                  hospital &&
                  hospital.speciality !== 'Emergency' &&
                  hospital.type !== 2,
              },
              {
                label: 'Emergency',
                value: CallOutcomeType.Emergency,
                hasAccess: hospital && hospital.type !== 2,
              },
              {
                label: 'Administrative',
                value: CallOutcomeType.Administrative,
                hasAccess:
                  hospital &&
                  hospital.type !== 2 &&
                  !blockedCallOutcomeTypes?.includes(
                    CallOutcomeType.Administrative
                  ),
              },
              {
                value: 'transferToBackline',
                hasAccess: hasAccessToTransferBackline,
                label:
                  hospitalCallOutcomeTitles?.transferToBackline ??
                  'Transfer to backline',
              },

              {
                value: 'referTorDVM',
                label: 'Refer to rDVM',
                hasAccess:
                  hospital &&
                  (hospital.speciality === 'Emergency' ||
                    hospital.speciality === 'Emergency & Specialty') &&
                  !hideCallOutcomeTypes?.includes(
                    CallOutcomeType.ReferTorDVM
                  ) &&
                  hospital.type !== 2,
              },
              {
                label: 'Behavior',
                value: CallOutcomeType.Behavior,
                hasAccess: hospital && hospital.type === 2,
              },
              {
                label: 'Shelter Clinic',
                value: CallOutcomeType.ShelterClinic,
                hasAccess: hospital && hospital.type === 2,
              },
              {
                label: 'Refer to General Practice',
                hasAccess: hospital && hospital.type === 2,
                value: CallOutcomeType.ReferToGeneralPractice,
              },
              {
                label: 'Refer to Emergency',
                value: CallOutcomeType.ReferToEmergency,
                hasAccess: hospital && hospital.type === 2,
              },
              {
                label: 'Shelter Admin',
                value: CallOutcomeType.ShelterAdmin,
                hasAccess: hospital && hospital.type === 2,
              },
            ]}
          />
        </div>

        {isShowFeedback && (
          <div
            style={{
              marginTop: '24px',
            }}
          >
            <Row>
              <Col col={6}>
                <InputField
                  placeholder="Add email"
                  type="textarea-autosize"
                  value={values?.survey?.email}
                  label="Email for sending survey"
                  name={surveyModal.feedback_email}
                />
              </Col>
            </Row>
          </div>
        )}

        <Dialog
          confirmText="Close"
          title="Please Note"
          buttons={(close) => (
            <StandardButton fullWidth text="Close" onClick={close} />
          )}
          text={
            <>
              <p>You have selected Administrative as the call outcome.</p>
            </>
          }
          open={
            isAdminAlert &&
            Config?.hideEmergencyCallOutcome
              ?.split(',')
              ?.includes(values.hospital_id + '')
          }
        />
        <CheckProtocolsInfo hospitalType="other">
          <CallOutcomeAction
            corporateOptions={corporateGroupOptions}
            isCorporateFetching={isCorporateGroupFetching}
          />

          <CallOutcomeRefer
            corporateOptions={corporateGroupOptions}
            isCorporateFetching={isCorporateGroupFetching}
          />
        </CheckProtocolsInfo>
        <CallOutcomeCheckboxes />
      </FlexLayout>
      Please click submit if consultation is finished. If you have any technical
      issues please report using internal reporting found{' '}
      <Dialog
        title="Internal reporting"
        closeOnDocumentClick={false}
        width={desktop ? '760px' : '90vw'}
        trigger={
          <StandardButtonWithRef
            transparent
            padding="0"
            text="here."
            styleType={ButtonStyleTypes.Ghost}
          />
        }
      >
        {(close, _, Buttons) => (
          <Formik
            initialValues={getInitialValues(values)}
            onSubmit={(formValues: FormValues) => {
              setValues((prevValues) => ({ ...prevValues, ...formValues }));
              close();
            }}
          >
            {({ dirty, isSubmitting }) => (
              <Styles.Form>
                <InternalReportField />

                <Buttons type={DialogType.Plain}>
                  <StandardButton
                    text="Close"
                    onClick={close}
                    fullWidth={mobile}
                  />
                  <StandardButton
                    text="Confirm"
                    fullWidth={mobile}
                    type={ButtonTypes.Submit}
                    disabled={!dirty || isSubmitting}
                    colorType={ButtonColors.Secondary}
                  />
                </Buttons>
              </Styles.Form>
            )}
          </Formik>
        )}
      </Dialog>
    </div>
  );
};
