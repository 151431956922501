import { useMemo } from 'react';
import { Row, Col, List, ListHeader } from '@gv/triage-components';

import { Case } from 'types';
import { useDetailsQuery } from 'store/api/hospitals';
import { getCaseFormSelectedPets } from 'utils/helpers';
import { titles, PetCard, PetsTemplate } from 'components/pet-card';
import { useDetailsQuery as useClientQuery } from 'store/api/clients';
import {
  shelterTitles,
  PetShelterCard,
  PetsShelterTemplate,
} from 'components/pet-card/pet-shelter-card';

export const PetInfo = ({
  pets,
  petId,
  clientId,
  hospitalId,
}: {
  pets: Case['pets'];
  petId: Case['pet_id'];
  clientId: Case['client_id'];
  hospitalId: Case['hospital_id'];
}) => {
  const { data: client } = useClientQuery(
    {
      allowDeleted: true,
      id: String(clientId),
      hospitalId: hospitalId ? String(hospitalId) : undefined,
    },
    {
      skip: !clientId,
    }
  );
  const hospitalQuery = useDetailsQuery(String(hospitalId), {
    skip: !hospitalId,
  });

  const listPets = useMemo(
    () => getCaseFormSelectedPets(client?.pets, pets, petId),
    [client?.pets, petId, pets]
  );

  if (!listPets) {
    return null;
  }

  return (
    <Row>
      <Col>
        <p className="semibold">Pet info</p>

        {hospitalQuery?.data?.type === 2 ? (
          <List
            count={listPets.length}
            mainWrapperStyles={{ padding: '0' }}
            header={
              <ListHeader
                padding="8px 0px"
                titles={shelterTitles}
                template={PetsShelterTemplate.desktop}
                tabletTemplate={PetsShelterTemplate.desktop}
              />
            }
          >
            {listPets.map((pet) => (
              <PetShelterCard pet={pet} key={pet.id} />
            ))}
          </List>
        ) : (
          <List
            count={listPets.length}
            mainWrapperStyles={{ padding: '0' }}
            header={
              <ListHeader
                titles={titles}
                padding="8px 0px"
                template={PetsTemplate.desktop}
                tabletTemplate={PetsTemplate.desktop}
              />
            }
          >
            {listPets.map((pet) => (
              <PetCard pet={pet} key={pet.id} />
            ))}
          </List>
        )}
      </Col>
    </Row>
  );
};
