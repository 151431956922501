import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { isLogoutAction } from '../auth';

import {
  ChatData,
  ChatSlice,
  AddMessage,
  SetMessages,
  BasePayload,
  RemoveMessage,
  UpdateMessage,
} from './types';

const initialState: ChatSlice = {};

export const chatMessagesSlice = createSlice({
  initialState,
  name: 'chatMessages',
  extraReducers: (builder) => {
    builder.addMatcher(isLogoutAction, (state) => {
      Object.keys(state).forEach((key) => {
        delete state[key];
      });
    });
  },
  reducers: {
    deleteMessages: (state, { payload }: PayloadAction<BasePayload>) => {
      delete state[payload.channelName];
    },
    setMessages: (state, { payload }: PayloadAction<SetMessages>) => {
      state[payload.channelName] = payload.data;
    },
    addMessage: (state, { payload }: PayloadAction<AddMessage>) => {
      state[payload.channelName].messages.push(payload.message);
    },
    removeMessage: (state, { payload }: PayloadAction<RemoveMessage>) => {
      if (state[payload.channelName]?.messages) {
        state[payload.channelName].messages = state[
          payload.channelName
        ].messages.filter(({ id }) => id !== payload.id);
      }
    },
    updateMessage: (state, { payload }: PayloadAction<UpdateMessage>) => {
      const { id, channelName, ...changes } = payload;

      state[channelName].messages = state[channelName].messages.map(
        (message) => (message.id === id ? { ...message, ...changes } : message)
      );
    },
    addOldMessages: (state, { payload }: PayloadAction<SetMessages>) => {
      const { data, channelName } = payload;
      const { messages } = state[channelName];

      state[channelName].nextId = data.nextId;
      state[channelName].prevId = data.prevId;
      state[payload.channelName].messages = [...data.messages, ...messages];
    },
  },
});

const {
  reducer: chatMessagesReducer,
  actions: {
    addMessage,
    setMessages,
    removeMessage,
    updateMessage,
    deleteMessages,
    addOldMessages,
  },
} = chatMessagesSlice;

const chatStore = (store: RootState) => store?.chatMessages;

export const selectChatMessages = (channelName: string = '') =>
  createSelector([chatStore], (store) => {
    const data: ChatData = (store ?? {})[channelName] ?? { messages: [] };
    const messages = data.messages.filter((message) => !!message);

    if (messages.length !== data.messages.length) {
      return {
        ...data,
        messages,
      };
    }
    return data;
  });

export {
  addMessage,
  setMessages,
  updateMessage,
  removeMessage,
  deleteMessages,
  addOldMessages,
  chatMessagesReducer,
};
