import { useMemo, useState, useEffect } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { Row, Col, Utils, UserRole, InputField } from '@gv/triage-components';

import { api } from 'api';
import { useAppSelector } from 'store';
import { Complaints } from 'components/cases';
import { selectAuthUser } from 'store/slices/auth';
import { selectCurrentTaskForm } from 'store/api/action-queue/selectors';

import * as Styles from './styles';

export const GreetingInfo = (props: any) => {
  const { isDynamicFlow, complaintsValue } = props;
  const { setFieldValue } = useFormikContext<FormikValues>();
  const caseForm: any = useAppSelector(selectCurrentTaskForm);
  const user = useAppSelector(selectAuthUser);
  const [data, setData] = useState<any>([]);

  const updateUser = useMemo(() => {
    return {
      ...user,
      ...Utils.Helpers.fullNameComponents(user?.name),
      role: user?.roles.map((role) => UserRole[role] ?? '').join(', '),
    };
  }, [user]);

  useEffect(() => {
    const getChiefComplaintsData = async () => {
      const res: any = await api.greeting.chiefComplaintsCategories();
      setData(res?.data?.data || []);
    };
    getChiefComplaintsData();
  }, []);

  return (
    <Styles.Container>
      {isDynamicFlow ? null : (
        <>
          <Styles.Para>
            Hi, Thank you for calling into the {caseForm?.referring_practice},
            my name is {updateUser?.name} and I am a {updateUser?.role}.
          </Styles.Para>
          <Styles.Para2>How can I help you?</Styles.Para2>
        </>
      )}
      <Row>
        <Col>
          <InputField
            name="problem_summary"
            label="Problem summary"
            type="textarea-autosize"
            requiredText="(Required)"
            placeholder="Describe problem"
            onChange={(e) => {
              setFieldValue('problem_summary', e.target.value);
              setFieldValue('brief_description', e.target.value);
            }}
          />
        </Col>
      </Row>
      <Complaints dataValues={data} complaintsValue={complaintsValue} />
    </Styles.Container>
  );
};
