import { useContext, useCallback } from 'react';
import {
  useAlert,
  Messages,
  ActionQueue,
  PriorityType,
  PlaceBackProps,
  ActionQueueType,
  CardConversationStatus,
} from '@gv/triage-components';

import { api } from 'api';
import { useAppDispatch } from 'store';
import { setTaskIsLoading } from 'store/api/action-queue';
import { getCallPhone, activeCallProps } from 'utils/helpers';
import { CallContext } from 'pages/action-center/comm/context';
import { createCase, updateCase } from 'store/api/cases/thunks';
import { QueueCardProps } from 'pages/action-center/comm/queue/types';

export const usePlaceBackInQueue = () => {
  const dispatch = useAppDispatch();
  const { handleError, showErrorAlert, showSuccessAlert } = useAlert();
  const { form, endTask, activeCall } = useContext(CallContext);
  const isCallMuted = activeCall?.isMuted();
  const activeCallId = getCallPhone(activeCall);

  const getBaseProps = useCallback(
    (row: ActionQueue) => {
      const isActiveTask = activeCallId === row?.roomId;
      const baseProps =
        row &&
        activeCallProps(
          row,
          row?.type === ActionQueueType.Video ||
            row?.type === ActionQueueType.Chat
            ? true
            : isActiveTask,
          form.values
        );

      if (
        baseProps &&
        baseProps?.conversationStatus === CardConversationStatus.Active
      ) {
        if (
          activeCallId &&
          !isActiveTask &&
          row?.type === ActionQueueType.Voice
        ) {
          baseProps.conversationStatus = CardConversationStatus.Holding;
        } else {
          baseProps.isMuted = isCallMuted ?? false;
        }
      }
      return baseProps;
    },
    [form.values, activeCallId, isCallMuted]
  );

  const getAllowPlaceBack = useCallback(
    (baseProps?: Partial<QueueCardProps>) =>
      baseProps?.conversationStatus === CardConversationStatus.Active ||
      baseProps?.conversationStatus === CardConversationStatus.Connecting,
    []
  );

  const onPlaceBack = async (
    queueItem: ActionQueue,
    values: PlaceBackProps
  ) => {
    if (form.values.client_id && queueItem) {
      try {
        const isDynamicFlow = queueItem.h_is_dynamic_flow_enabled;
        dispatch(setTaskIsLoading({ task: queueItem, isLoading: true }));
        if (!form.values.id) {
          await dispatch(
            createCase({ isDynamicFlow, task: queueItem, values: form.values })
          ).unwrap();
        }
        await dispatch(
          updateCase({
            isDynamicFlow,
            task: queueItem,
            values: {
              ...form.values,
              brief_description: form.values.problem_summary,
            },
          })
        ).unwrap();

        let payload = { ...values };
        if (payload.priority === PriorityType.Admin) {
          payload.priority = undefined;
        }

        form.onSaveCaseForm({
          ...form.values,
          queueCardMood: payload.mood,
          problem_summary: payload.notes,
          chief_complaints: payload.issues,
          priority_of_concern: payload.priority,
        });
        if (
          (queueItem.type === ActionQueueType.Voice ||
            queueItem.type === ActionQueueType.Video) &&
          queueItem.roomId
        ) {
          await api.comm.placeBackCall({
            roomId: queueItem.roomId,
            ...payload,
          });
          await endTask({ completely: true });
          showSuccessAlert(Messages.CALL_PLACED_BACK);
        } else if (
          (queueItem.type === ActionQueueType.SMS ||
            queueItem.type === ActionQueueType.Chat) &&
          queueItem.channelName
        ) {
          await api.comm.placeBackChat({
            channelName: queueItem.channelName,
            ...payload,
          });
          await endTask({ completely: true });
          showSuccessAlert(Messages.CHAT_PLACED_BACK);
        }
      } catch (error) {
        handleError(error);
      } finally {
        dispatch(setTaskIsLoading({ task: queueItem, isLoading: false }));
      }
    } else {
      showErrorAlert(Messages.PLEASE_ASSOCIATE_CLIENT);
    }
  };

  return { onPlaceBack, getBaseProps, getAllowPlaceBack };
};
