import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { generateUrl } from 'utils/helpers';
import { apiLoaderQuery } from 'store/query';

const tagType = 'Employe Bitwerx';

export const employeBitwerxApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiLoaderQuery,
  reducerPath: 'employeBitwerxApi',
  endpoints: (build) => ({
    list: build.query<any[], any>({
      providesTags: () => [tagType],
      transformResponse: (response: any) => response.data.employes,
      query: (data: any) => ({
        method: 'get',
        url: generateUrl(URL.GET_BITWERX_EMPLOYE_MASTER_LIST, { id: data.id }),
      }),
    }),
  }),
});

export const { useListQuery } = employeBitwerxApi;
