import { Utils } from '@gv/triage-components';

import { URL } from 'api/constants';
import { apiInstance } from 'api/instance';

const {
  Helpers: { offset },
} = Utils;

export const exportReportData = (count: any, search: string, filters?: any) =>
  apiInstance.get(
    Utils.Query.addParamsToUrl(URL.EXPORT_REPORT_ADMIN_HOSPITAL, {
      search,
      limit: count,
      type: filters?.type.length === 0 ? null : filters?.type,
      state: filters?.state.length === 0 ? null : filters?.state,
      status: filters?.status.length === 0 ? null : filters?.status,
      speciality: filters?.speciality.length === 0 ? null : filters?.speciality,
      protocol_level:
        filters?.protocolLevel.length === 0 ? null : filters?.protocolLevel,
      filters:
        filters?.services.length === 0
          ? null
          : JSON.stringify({ services: filters?.services }),
    }),
    { responseType: 'blob' }
  );

export const exportReportDataForTeam = (
  count: any,
  search: string,
  page: number
) =>
  apiInstance.get(
    Utils.Query.addParamsToUrl(URL.EXPORT_REPORT_TEAM_HOSPITAL, {
      limit: count,
      hospital: true,
      searchKeyword: search,
      offset: offset(page!, count),
    }),
    { responseType: 'blob' }
  );

export const exportReportClientData = (
  count: any,
  id: number | string | undefined,
  search: string
) =>
  apiInstance.get(
    Utils.Query.addParamsToUrl(URL.EXPORT_REPORT_CLIENT_HOSPITAL, {
      search,
      limit: count,
      hospitalId: id,
    }),
    { responseType: 'blob' }
  );
