import { Pet, ListCard } from '@gv/triage-components';
import { formatDate } from '@gv/triage-components/dist/utils/date';

import {
  PetsCells,
  PetsShelterTemplate,
  // prepareShelterCardValues,
} from './helpers';

export const shelterTitles = [
  'Pet',
  'Species',
  'Date of Birth',
  'Breed',
  'Adoption Date',
  'Gender',
  'Medical Records',
];

export const PetShelterCard = ({ pet }: { pet: Pet }) => {
  // const values = prepareShelterCardValues(pet);

  return (
    <ListCard
      padding="24px 16px"
      mobileHeaders={shelterTitles}
      cellsMobile={PetsCells.mobile}
      template={PetsShelterTemplate.desktop}
      mobileTemplate={PetsShelterTemplate.mobile}
      values={[
        pet?.name ?? '',
        pet?.species ?? '',
        formatDate(pet?.date_of_birth, 'MMM DD, YYYY'),
        pet?.breed ?? '',
        pet?.adoption_date !== null
          ? formatDate(pet?.adoption_date, 'MMM DD, YYYY')
          : 'N/A',
        pet?.gender ?? '',
        pet?.medical_records ? (
          <a
            target="_blank"
            key={pet.medical_records}
            rel="noopener noreferrer"
            href={pet.medical_records}
          >
            Medical Record
          </a>
        ) : (
          'No records available'
        ),
      ]}
    />
  );
};

export { PetsShelterTemplate };
