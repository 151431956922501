import axios from 'axios';

import { getToken } from 'utils/storage';
import { setLoading } from 'store/slices/loader';

import { URL } from '../constants';
import { ChatHistoryRequest, ChatHistoryResponse } from '../../types';

const backendURL = process.env.REACT_APP_SUBSCRIPTION_URL;

export const getSubscriptionManagementDetails = async (
  id: string | number | undefined,
  token: any,
  dispatch: any
) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get(
      `${backendURL}/${URL.GET_SUBSCRIPTION_MANAGEMENT}/${id}/plan`,
      {
        headers: {
          hospital_id: id,
          Authorization: token,
        },
      }
    );
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error fetching subscription details:', error);
    throw error;
  }
};

export const subscriptionManagementDetails = (
  values: any,
  token: any,
  id: string | number | undefined
) =>
  axios.post(`${backendURL}/${URL.GET_SUBSCRIPTION_MANAGEMENT}/plan`, values, {
    headers: {
      hospital_id: id,
      Authorization: token,
    },
  });

export const subscriptionManagementDetailsUpdate = (
  id: string | number | undefined,
  values: any,
  token: any
) =>
  axios.put(
    `${backendURL}/${URL.GET_SUBSCRIPTION_MANAGEMENT}/plan/${id}`,
    values,
    {
      headers: {
        hospital_id: id,
        Authorization: token,
      },
    }
  );

export const updateChatHistory = (
  room_id: string,
  data: {
    status: number;
    end_by: number;
    case_id: number;
    details?: string;
    hospital_id: number;
  }
) => {
  return axios.put(
    `${backendURL}/${URL.CHAT_HISTORY.replace('{roomId}', room_id)}`,
    {
      room_id: room_id,
      status: data.status,
      case_id: data.case_id,
      team_user_id: data.end_by,
      details: data.details ?? '',
    },
    {
      headers: {
        hospital_id: data.hospital_id,
        Authorization: getToken('accessToken'),
      },
    }
  );
};

export const endChat = (
  room_id: string,
  data: {
    status: number;
    end_by: number;
    case_id: number;
    hospital_id: number;
  }
) =>
  axios.post(
    `${backendURL}/chat/end`,
    {
      room_id: room_id,
      status: data.status,
      end_by: data.end_by,
      case_id: data.case_id,
    },
    {
      headers: {
        hospital_id: data.hospital_id,
        Authorization: getToken('accessToken'),
      },
    }
  );

export const getChatHistory = (
  teamId: string | number,
  params: ChatHistoryRequest
): Promise<{ data: ChatHistoryResponse }> =>
  axios.get(`${backendURL}/team/${teamId}/chat/history`, {
    params,
    headers: {
      hospital_id: params.hospital_id,
      Authorization: getToken('accessToken'),
    },
  });

export const sendFeedbackEmailVoice = (
  hospital_id: string | undefined,
  caseId: string
): Promise<{ data: any }> =>
  axios.post(
    `${backendURL}/cases/send-call-history-email/${caseId}`,
    {},
    {
      headers: {
        hospital_id,
        Authorization: getToken('accessToken'),
      },
    }
  );
