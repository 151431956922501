import { FormikValues } from 'formik';
import {
  Utils,
  Client,
  FieldType,
  FormCardTypes,
  DFRoutingType,
  SingleOptions,
  DFWidgetGroup,
  DFStepItemType,
  Recommendation,
  ReferToErAcceptancePath,
  ReferToOnCallAcceptacePath,
} from '@gv/triage-components';

import { getClientEmails } from 'utils/helpers';
import { GetFormResponseData } from 'store/api/dynamic-forms/types';

import {
  FormCallOutcome,
  CallOutcomeType,
  DynamicFormData,
  CallOutcomeDeadlineIssue,
  CallOutcomeAdministrative,
} from '../types';

import { getClientPhonesInitialValues } from './helpers';

const { joinStrings, mergeObjects } = Utils.Helpers;

export const initialCallOutcome = (): FormCallOutcome => {
  const etaField = {
    datetime: new Date().toISOString(),
  };
  return {
    type: '',
    [CallOutcomeType.Behavior]: {
      type: '',
    },
    [CallOutcomeType.ReferTorDVM]: {
      type: '',
    },
    [CallOutcomeType.ShelterAdmin]: {
      type: '',
    },
    [CallOutcomeType.ShelterClinic]: {
      type: '',
    },
    [CallOutcomeType.ReferToEmergency]: {
      type: '',
    },
    [CallOutcomeType.TransferToBackline]: {
      type: '',
    },
    [CallOutcomeType.NoFollowUpRequired]: {
      type: '',
    },
    [CallOutcomeType.ReferToGeneralPractice]: {
      type: '',
    },
    [CallOutcomeType.DeadlineIssue]: {
      type: CallOutcomeDeadlineIssue.DeadlineIssue,
    },
    [CallOutcomeType.Administrative]: {
      type: CallOutcomeAdministrative.Call,
      call: {
        outcomes: [],
      },
    },
    [CallOutcomeType.Emergency]: {
      type: '',
      referToPPH: {
        accepted: 'true',
      },
      referToOnCall: {
        accepted: 'true',
      },
      referToEr: {
        accepted: 'true',
        ...etaField,
      },
    },
    [CallOutcomeType.Appointment]: {
      type: '',
      declined: {
        reason: '',
      },
      scheduled: {
        ...etaField,
        clientType: [],
      },
      recommended: {
        recommendation: Recommendation.HospitalToSchedule,
      },
    },
  };
};

export const initialCaseForm = () => ({
  sex: '',
  age: '',
  pets: [],
  name: '',
  email: '',
  breed: '',
  isMobile: 0,
  species: '',
  isNew: true,
  appetite: '',
  case_type: 0,
  pet_id: null,
  stable: false,
  client_id: '',
  attend_by: [],
  sms_status: '',
  created_at: '',
  last_seen: null,
  departments: [],
  hospital_id: '',
  client_name: '',
  isNameConfirm: 0,
  call_outcome: [],
  country_code: '',
  client_phone: '',
  isEmailConfirm: 0,
  eta_to_clinic: '',
  has_action: false,
  activity_level: '',
  isNumberConfirm: 0,
  current_step: null,
  activity_type: '0',
  client_hospital: '',
  problem_summary: '',
  is_submitted: false,
  follow_up_steps: [],
  internal_notes: null,
  care_hospital_id: '',
  care_doctor_id: null,
  is_incompleted: true,
  medications_list: [],
  additional_phone: '',
  hasClientZipCode: '',
  current_client: null,
  chief_complaints: [],
  checkedConditions: '',
  email_for_invoice: '',
  general_stability: '',
  technical_issue: null,
  medications_notes: '',
  max_opened_step: null,
  client_local_time: '',
  deposit_invoice_id: '',
  checkedMedications: '',
  referring_practice: '',
  priority_of_concern: '',
  triageLevelOfConcern: 0,
  case_report_review: null,
  respiratory_stability: '',
  isWarningModalShown: false,
  neurological_stability: '',
  chronial_medical_issues: '',
  showInternalReporting: false,
  is_species_seen_confirm: false,
  isActiveHospitalPayment: false,
  is_emergency_fee_confirm: false,
  client_denied_er_form_notes: '',
  callOutcome: initialCallOutcome(),
  pain_level_assessed_by_caller: '',
  respiratory_stability_abnormal: '',
  recommendation_Level_Of_Concern: '',
  survey: {
    feedback_email: '',
  },
});

const acceptanceStateItems = [
  ...(Object.values(ReferToErAcceptancePath) as string[]),
  ...(Object.values(ReferToOnCallAcceptacePath) as string[]),
];

const initialAcceptanceState = acceptanceStateItems.reduce<
  Record<string, string>
>((sum, key) => {
  const [acceptanceName] = key.split('.').reverse();
  sum[acceptanceName] = '';
  return sum;
}, {});

export const dynamicFormInitialProps: FormikValues = {
  pet: {},
  own_fields: {},
  clientPets: [],
  survey: {
    feedback_email: '',
  },
  acceptance_state: initialAcceptanceState,
  client: {
    name: '',
    city: '',
    state: '',
    phones: [],
    emails: [],
    address: '',
    zip_code: '',
    id: undefined,
  },
};

export const getDynamicFormInitialValues = (
  values: FormikValues,
  client?: Client
) => {
  const copy = mergeObjects(dynamicFormInitialProps, values);

  if (typeof copy.isNumberConfirm !== 'boolean') {
    copy.isNumberConfirm = false;
  }
  if (typeof copy.isNameConfirm !== 'boolean') {
    copy.isNameConfirm = false;
  }

  if (!client) {
    return copy;
  }

  const {
    id,
    city,
    name,
    state,
    emails,
    address,
    zip_code,
    addresses,
    pets = [],
  } = client;

  const { phones, home_phones } = getClientPhonesInitialValues(client);
  copy.client = {
    ...copy.client,
    id,
    city,
    state,
    zip_code,
    name: name ?? '',
    phones: [...(phones ?? []), ...(home_phones ?? [])],
    emails: emails?.length === 0 ? undefined : getClientEmails(client),
    address: joinStrings([address, addresses?.join(', ')], ', ', null),
  };
  copy.clientPets = pets;

  if (pets.length) {
    copy.pet = pets[0];
  }
  if (!copy?.own_fields) {
    copy.own_fields = {};
  }
  if (!copy?.acceptance_state) {
    copy.acceptance_state = {};
  }
  if (copy?.survey?.feedback_email === '') {
    const email = getClientEmails(client);
    copy.survey.feedback_email = email[0];
  }

  acceptanceStateItems.forEach((key: string) => {
    const [acceptanceName] = key.split('.').reverse();
    if (!copy?.acceptance_state?.[acceptanceName]) {
      copy.acceptance_state[acceptanceName] = '';
    }
  });

  return copy;
};

export const submitStepId = 'Submit step_1697195523573';

export const getInitialBuilderForm = (): GetFormResponseData => {
  const editFieldId = Date.now().toString();

  const data: DynamicFormData = {
    sectionsOrder: ['Introduction'],
    sections: {
      Introduction: {
        initial: true,
        id: 'Introduction',
        title: 'Introduction',
        steps: ['Introduction_Greeting and call reason'],
      },
    },
    steps: {
      [submitStepId]: {
        nextStep: {},
        isFinal: true,
        id: submitStepId,
        title: 'Submit case',
        type: DFStepItemType.Step,
      },

      'Introduction_Greeting and call reason': {
        initialStep: true,
        type: DFStepItemType.Step,
        title: 'Greeting and call reason',
        id: 'Introduction_Greeting and call reason',
        nextStep: {
          default: '',
        },
        conditionRules: {
          type: DFRoutingType.LineLinkage,
        },
        widgets: [
          {
            id: 'Call reason',
            systemTitle: 'Call reason',
            widgetType: FormCardTypes.RadioGroup,
            script:
              'Hello, thank you for calling to hospital. My name is ... How can I help you?',
            questions: [
              {
                icon: 'Dropdown',
                isRequiredDisabled: true,
                systemTitle: 'Call reason',
                questionFields: ['callReason'],
                requiredFields: ['callReason'],
                systemRequired: ['callReason'],
                fields: [
                  {
                    id: editFieldId,
                    isHideDivider: true,
                    type: 'buttonsGroup',
                    label: 'Call reason',
                    formFieldProps: {
                      name: 'callReason',
                      items: [
                        {
                          text: '',
                          value: '',
                          label: '',
                        },
                      ],
                    },
                  },
                ],
                builderFields: [
                  {
                    fieldType: 'input',
                    fieldProps: {
                      name: 'questionOrScript',
                      type: 'textarea-autosize',
                      label: 'Add question or script',
                      placeholder: 'Add question or script',
                    },
                    selectFieldProps: {
                      editFieldId,
                      name: 'viewFieldType',
                      label: String.fromCharCode(160),
                      options: [
                        {
                          label: 'Dropdown',
                          value: 'onlyValueSelect',
                        },
                        {
                          value: 'radioGroup',
                          label: 'Radio buttons',
                        },
                        {
                          label: 'Buttons',
                          value: 'buttonsGroup',
                        },
                      ],
                    },
                  },
                  {
                    editFieldId,
                    options: [''],
                    fieldType: 'selectGroup',
                    fieldProps: {
                      name: 'options',
                      label: 'Add call reason',
                      placeholder: 'Add reason',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  };

  return {
    data,
    name: '',
    type: '',
    hospitalId: undefined,
  };
};

export const initialTestData = {
  own_fields: {},
  clientId: null,
  client_id: null,
  isNameConfirm: false,
  isNumberConfirm: false,
  acceptance_state: initialAcceptanceState,
  client: {
    id: null,
    city: 'Linden',
    zip_code: '07036',
    name: 'Test Client',
    state: 'New Jersey',
    emails: ['test.user@example.com'],
    address: '4140 Parker Rd. Allentown',
    phones: ['+16098753063', '+16098753064'],
  },
  clientPets: [
    {
      status: 1,
      id: 17282586,
      name: 'Daisy',
      app_version: 1,
      hospital_id: 495,
      app_language: 'en',
      is_deceased: false,
      species: 'Canine (Dog)',
      breed: 'Boston Terrier',
      gender: 'Female Intact',
      date_of_birth: '1532739209704',
      record_created_at: '2023-10-25T16:59:19.571Z',
    },
  ],
  pet: {
    status: 1,
    id: 17282586,
    name: 'Daisy',
    app_version: 1,
    hospital_id: 495,
    app_language: 'en',
    is_deceased: false,
    species: 'Canine (Dog)',
    breed: 'Boston Terrier',
    gender: 'Female Intact',
    created_at: '1698253159571',
    updated_at: '1698253159571',
    date_of_birth: '1532739209704',
    record_created_at: '2023-10-25T16:59:19.571Z',
  },
};

export const getDepartmentSelectWidget = (
  count?: number,
  navigatePath?: string
) => ({
  group: DFWidgetGroup.CallOutcome,
  id: SingleOptions.CorporateHospitals,
  systemTitle: SingleOptions.CorporateHospitals,
  questions: [
    {
      icon: 'Calendar',
      isDeletable: false,
      isHideFooter: true,
      systemRequired: [],
      requiredFields: [],
      isSelectVisible: false,
      isRequiredDisabled: true,
      questionFields: ['corporate_hospital_id'],
      systemTitle: SingleOptions.CorporateHospitals,
      fields: [
        {
          isHideDivider: true,
          type: FormCardTypes.CustomNode,
        },
      ],

      builderFields: [
        {
          count: count ?? 0,
          navigatePath: navigatePath ?? '',
          fieldType: FieldType.CorporateHospitals,
        },
      ],
    },
  ],
});
