import { useMemo } from 'react';
import { formatDate } from '@gv/triage-components/dist/utils/date';
import {
  List,
  Icon,
  Utils,
  Checkbox,
  ListCard,
  isMobile,
  isDesktop,
  EditButton,
  FlexLayout,
  useNavigate,
  ButtonColors,
  EllipsisText,
  handleMutation,
  StandardButton,
} from '@gv/triage-components';

import { useHospitalParams } from 'hooks';
import { useUpdatePetMutation } from 'store/api/clients';

import * as Styles from './styles';
import { PetsListProps, PetListGlobalProps } from './types';
import { PetsCells, PetsCells2, PetsTemplate, PetsTemplate2 } from './config';

const {
  Helpers: { textValue },
  Date: { getFormattedAge },
} = Utils;

const mobileTitles = ['Species', 'Age', 'Breed', 'Gender'];
const mobileTitles2 = [
  'Species',
  'Date of birth',
  'Adoption Date',
  'Breed',
  'Gender',
  'Medical Records',
];

export const PetsListGlobal = ({
  data,
  clientId,
  editPath,
  prefixRow,
  className,
  hospitalQuery,
  forceMobileView,
  medicalRecordsPath,
  createHospitalTaskView,
  hasEditPermission = true,
}: PetListGlobalProps) => {
  const mobile = isMobile();
  const desktop = isDesktop();
  const navigate = useNavigate();
  const [update, mutation] = useUpdatePetMutation();

  handleMutation(mutation);

  const hasMedicalRecords = !!medicalRecordsPath;

  const { titles, template, cellsMobile } = useMemo(() => {
    /* eslint-disable @typescript-eslint/no-shadow */
    let titles = ['Pet', 'Species', 'Age', 'Breed', 'Gender', 'Deceased', ''];
    let template = PetsTemplate.default.desktop;
    let cellsMobile = PetsCells.mobile;
    /* eslint-enable */

    if (hasMedicalRecords) {
      template = template.replace('24px', '64px');

      const [first, ...rest] = cellsMobile;
      cellsMobile = [{ ...first, col: '1/5' }, ...rest];
    }

    if (prefixRow?.header && hospitalQuery?.data?.type !== 2) {
      titles = [...prefixRow.header, ...titles];
    }
    if (createHospitalTaskView && hospitalQuery?.data?.type !== 2) {
      template = PetsTemplate.petOwner.desktop;
      cellsMobile = PetsCells.petOwner;
    }
    if (prefixRow?.template && hospitalQuery?.data?.type !== 2) {
      template = `${prefixRow.template} ${template}`;
    }

    return { titles, template, cellsMobile };
  }, [
    hasMedicalRecords,
    prefixRow?.header,
    prefixRow?.template,
    createHospitalTaskView,
    hospitalQuery,
  ]);

  const { titles2, template2, cellsMobile2 } = useMemo(() => {
    /* eslint-disable @typescript-eslint/no-shadow */
    let titles2 = [
      'Pet',
      'Species',
      'Date of birth',
      'Adoption Date',
      'Breed',
      'Gender',
      'Medical Records',
      'Deceased',
      '',
    ];
    let template2 = PetsTemplate2.default.desktop;
    let cellsMobile2 = PetsCells2.mobile;
    /* eslint-enable */

    if (hasMedicalRecords) {
      template2 = template2.replace('24px', '64px');

      const [first, ...rest] = cellsMobile2;
      cellsMobile2 = [{ ...first, col: '1/5' }, ...rest];
    }

    if (prefixRow?.header && hospitalQuery?.data?.type === 2) {
      titles2 = [...prefixRow.header, ...titles2];
    }
    if (createHospitalTaskView && hospitalQuery?.data?.type === 2) {
      template2 = PetsTemplate2.petOwner.desktop;
      cellsMobile2 = PetsCells2.petOwner;
    }
    if (prefixRow?.template && hospitalQuery?.data?.type === 2) {
      template2 = `${prefixRow.template} ${template2}`;
    }

    return { titles2, template2, cellsMobile2 };
  }, [
    hasMedicalRecords,
    prefixRow?.header,
    prefixRow?.template,
    createHospitalTaskView,
    hospitalQuery,
  ]);

  const isMobileView = forceMobileView || mobile;
  const mobProps = isMobileView ? { gap: 16, padding: '16px' } : {};

  return (
    <Styles.ListWrap className={className}>
      <List
        count={data?.length || 0}
        mainWrapperStyles={{ padding: '0' }}
        header={
          createHospitalTaskView || forceMobileView ? undefined : (
            <Styles.Header
              titles={hospitalQuery?.data?.type === 2 ? titles2 : titles}
              template={hospitalQuery?.data?.type === 2 ? template2 : template}
            />
          )
        }
      >
        {!!data?.length &&
          data.map((pet, index) => {
            const name = (
              <FlexLayout gap={8}>
                {prefixRow?.values &&
                  (isMobileView || !desktop) &&
                  prefixRow.values(pet)}
                <EllipsisText className="semibold">
                  {textValue(pet.name)}
                </EllipsisText>
              </FlexLayout>
            );
            const species = textValue(pet?.species);

            const buttons = (
              <FlexLayout gap={16} justifyContent="flex-end">
                {hasEditPermission && (
                  <EditButton
                    onClick={
                      editPath ? () => navigate(editPath(pet.id)) : undefined
                    }
                    disabled={
                      pet.is_deceased ||
                      !editPath ||
                      hospitalQuery?.data?.type === 2
                    }
                  />
                )}

                {medicalRecordsPath && (
                  <StandardButton
                    padding="0"
                    icon={<Icon.Case />}
                    tooltip="Medical Records"
                    colorType={ButtonColors.Transparent}
                    onClick={() => navigate(medicalRecordsPath(pet.id))}
                  />
                )}
              </FlexLayout>
            );

            let values = [name, species, buttons];

            if (!createHospitalTaskView && hospitalQuery?.data?.type !== 2) {
              const age = getFormattedAge(pet?.date_of_birth);
              const breed = textValue(pet?.breed);
              const gender = textValue(pet?.gender);

              const deceased = (
                <Checkbox
                  id={`deceased-${pet.id}`}
                  checked={pet?.is_deceased}
                  disabled={mutation.isLoading || !hasEditPermission}
                  label={!desktop || forceMobileView ? 'Deceased' : ''}
                  onChange={() => {
                    update({
                      pet_id: pet.id,
                      client_id: Number(clientId),
                      is_deceased: !pet.is_deceased,
                    });
                  }}
                />
              );

              values.splice(2, 0, age, breed, gender, deceased);
            }

            if (!createHospitalTaskView && hospitalQuery?.data?.type === 2) {
              const age = formatDate(pet?.date_of_birth, 'MMM DD, YYYY') || '';
              const adoptionDate =
                pet?.adoption_date !== null
                  ? formatDate(pet?.adoption_date, 'MMM DD, YYYY')
                  : 'N/A';
              const breed = textValue(pet?.breed);
              const gender = textValue(pet?.gender);
              const medicalRecords = pet?.medical_records;

              const deceased = (
                <Checkbox
                  id={`deceased-${pet.id}`}
                  checked={pet?.is_deceased}
                  disabled={mutation.isLoading || !hasEditPermission}
                  label={!desktop || forceMobileView ? 'Deceased' : ''}
                  onChange={() => {
                    update({
                      pet_id: pet.id,
                      client_id: Number(clientId),
                      is_deceased: !pet.is_deceased,
                    });
                  }}
                />
              );

              values.splice(
                2,
                0,
                age ?? 'N/A',
                adoptionDate ?? 'N/A',
                breed,
                gender,
                medicalRecords ? (
                  <a
                    target="_blank"
                    key={pet.medical_records}
                    rel="noopener noreferrer"
                    href={pet.medical_records}
                  >
                    Medical Record
                  </a>
                ) : (
                  'No records available'
                ),
                deceased
              );
            }

            if (prefixRow?.values && !forceMobileView && desktop) {
              values = [...prefixRow.values(pet), ...values];
            }

            let mobileTemplate;
            if (createHospitalTaskView) {
              if (hospitalQuery?.data?.type === 2) {
                mobileTemplate = PetsTemplate2.petOwner.mobile;
              }
              if (hospitalQuery?.data?.type !== 2) {
                mobileTemplate = PetsTemplate.petOwner.mobile;
              }
            }

            if (!createHospitalTaskView) {
              if (hospitalQuery?.data?.type === 2) {
                mobileTemplate = PetsTemplate2.default.mobile;
              }
              if (hospitalQuery?.data?.type !== 2) {
                mobileTemplate = PetsTemplate.default.mobile;
              }
            }

            return (
              <>
                {hospitalQuery?.data?.type === 2 ? (
                  <ListCard
                    {...mobProps}
                    isPropagation
                    values={values}
                    template={template2}
                    key={pet.id || index}
                    cellsMobile={cellsMobile2}
                    cellsTablet={cellsMobile2}
                    disabled={pet?.is_deceased}
                    mobileTemplate={mobileTemplate}
                    tabletTemplate={mobileTemplate}
                    forceMobileView={forceMobileView}
                    gap={createHospitalTaskView ? 16 : undefined}
                    padding={createHospitalTaskView ? '16px' : undefined}
                    mobileHeaders={
                      createHospitalTaskView
                        ? undefined
                        : prefixRow
                          ? ['', ...mobileTitles2]
                          : ['Name', ...mobileTitles2]
                    }
                  />
                ) : (
                  <ListCard
                    {...mobProps}
                    isPropagation
                    values={values}
                    template={template}
                    key={pet.id || index}
                    cellsMobile={cellsMobile}
                    cellsTablet={cellsMobile}
                    disabled={pet?.is_deceased}
                    mobileTemplate={mobileTemplate}
                    tabletTemplate={mobileTemplate}
                    forceMobileView={forceMobileView}
                    gap={createHospitalTaskView ? 16 : undefined}
                    padding={createHospitalTaskView ? '16px' : undefined}
                    mobileHeaders={
                      createHospitalTaskView
                        ? undefined
                        : prefixRow
                          ? ['', ...mobileTitles]
                          : ['Name', ...mobileTitles]
                    }
                  />
                )}
              </>
            );
          })}
      </List>
    </Styles.ListWrap>
  );
};

export const PetsList = (props: PetsListProps) => {
  const { clientId } = useHospitalParams();
  return <PetsListGlobal clientId={clientId} {...props} />;
};
