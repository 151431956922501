import axios from 'axios';

import { setLoading } from 'store/slices/loader';

import { URL } from '../constants';

const backendURL = process.env.REACT_APP_SUBSCRIPTION_URL;

export const getSubscribersDetails = async (
  id: string | number | undefined,
  token: any,
  page: number,
  pageSize: number,
  searchQuery: string,
  startDate: Date | string | null,
  endDate: Date | string | null
) => {
  try {
    const response = await axios.get(
      `${backendURL}/${URL.GET_SUBSCRIPTION_MANAGEMENT}/${id}/subscription/history/?page=${page}&pageSize=${pageSize}&range[createdAt][start]=${startDate}&range[createdAt][end]=${endDate}&commonSearch=${searchQuery}`,
      {
        headers: {
          hospital_id: id,
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error fetching subscription details:', error);
    throw error;
  }
};

export const getSubscribersEarnings = async (
  id: string | number | undefined,
  token: any,
  startDate: Date | string | null,
  endDate: Date | string | null
) => {
  try {
    const response = await axios.get(
      `${backendURL}/${URL.GET_SUBSCRIPTION_MANAGEMENT}/${id}/subscription/earning/?range[createdAt][start]=${startDate}&range[createdAt][end]=${endDate}`,
      {
        headers: {
          hospital_id: id,
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error fetching subscription details:', error);
    throw error;
  }
};

export const getProfileDetails = async (
  id: string | number | undefined,
  token: any,
  dispatch: any,
  userId: string
) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get(
      `${backendURL}/${URL.GET_SUBSCRIPTION_PROFILE_DETAILS}/${userId}`,
      {
        headers: {
          hospital_id: id,
          Authorization: token,
        },
      }
    );
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error fetching subscription details:', error);
    throw error;
  }
};

export const getProfileDetailRequests = async (
  id: string | number | undefined,
  token: any,
  dispatch: any,
  userId: string
) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get(
      `${backendURL}/${URL.GET_SUBSCRIPTION_PROFILE_REQUEST}/${userId}`,
      {
        headers: {
          hospital_id: id,
          Authorization: token,
        },
      }
    );
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error fetching subscription details:', error);
    throw error;
  }
};

export const getTranscationDetails = async (
  id: string | number | undefined,
  token: any,
  dispatch: any,
  subscriptionId: string
) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get(
      `${backendURL}/${URL.GET_SUBSCRIPTION_MANAGEMENT}/${id}/subscription/${subscriptionId}/history/details`,
      {
        headers: {
          hospital_id: id,
          Authorization: token,
        },
      }
    );
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error fetching subscription details:', error);
    throw error;
  }
};
