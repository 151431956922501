import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { RoomOptionsDefaults } from '@ably/chat';
import { ChatRoomProvider, ChatClientProvider } from '@ably/chat/react';

import { CommContext } from 'context/comm';
import { useAppSelector } from 'store/hooks';
import { ChatSms } from 'components/chat/sms';
import { Chat as ChatSection } from 'components';
import { ChatProps } from 'components/chat/types';
import { selectCurrentTask } from 'store/api/action-queue/selectors';

const WrappedChat = (props: ChatProps): JSX.Element => {
  const currentTask = props.task || useAppSelector(selectCurrentTask);
  const { chatClient } = useContext(CommContext);
  return chatClient ? (
    <ChatClientProvider client={chatClient}>
      <ChatRoomProvider
        options={RoomOptionsDefaults}
        id={currentTask?.channelName ?? ''}
      >
        <ChatSection task={currentTask} {...props} />
      </ChatRoomProvider>
    </ChatClientProvider>
  ) : (
    <></>
  );
};

export const Chat = styled(WrappedChat)<{
  fullWidth?: boolean;
  isDynamicFlow?: boolean;
}>`
  min-width: 320px;

  ${({ theme, fullWidth }) => css`
    border-right: 1px solid ${theme.config.border};
    width: ${theme.responsive.isTabletUp && !fullWidth ? '40%' : '100%'};
  `}

  ${({ theme, isDynamicFlow }) =>
    !theme.responsive.isMobile && isDynamicFlow && 'width: 30%;'};
`;

export const ChatMessages = styled(ChatSms)<{ isDynamicFlow?: boolean }>`
  min-width: 320px;

  ${({ theme }) => css`
    border-right: 1px solid ${theme.config.border};
    width: ${theme.responsive.isTabletUp ? '40%' : '100%'};
  `}

  ${({ theme, isDynamicFlow }) =>
    !theme.responsive.isMobile && isDynamicFlow && 'width: 30%;'};
`;
