import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { generateUrl } from 'utils/helpers';
import { apiLoaderQuery } from 'store/query';

const tagType = 'Provider Bitwerx';

export const providerBitwerxApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiLoaderQuery,
  reducerPath: 'providerBitwerxApi',
  endpoints: (build) => ({
    list: build.query<any[], any>({
      providesTags: () => [tagType],
      transformResponse: (response: any) => response.data.providers,
      query: (data: any) => ({
        method: 'get',
        url: generateUrl(URL.GET_BITWERX_PROVIDER_MASTER_LIST, { id: data.id }),
      }),
    }),
  }),
});

export const { useListQuery } = providerBitwerxApi;
