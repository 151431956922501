import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { providesList } from 'utils/query-cache';
import { NurseStatus, TeamMemberUser } from 'types';

import {
  ListData,
  ListResponse,
  UserNurseStatus,
  ListRequestQuery,
  UpdateUserRequest,
  UserDetailsRequest,
  UserDetailsResponse,
  OnlineListRequestQuery,
  UserNurseStatusResponse,
} from './types';

const { addParamsToUrl } = Utils.Query;
const { filterEmptyFields } = Utils.Object;

const tagType = 'Users';
const tagStatus = 'Status';
const tagTypes = [tagType, tagStatus];

export const usersApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'usersApi',
  endpoints: (build) => ({
    pushSubscribe: build.mutation<void, PushSubscription>({
      query: (data) => ({
        data,
        method: 'post',
        url: URL.NOTIFICATIONS_SUBSCRIBE,
      }),
    }),

    pushUnsubscribe: build.mutation<void, PushSubscription>({
      query: (data) => ({
        data,
        method: 'post',
        url: URL.NOTIFICATIONS_UNSUBSCRIBE,
      }),
    }),

    updateStatus: build.mutation<void, NurseStatus>({
      invalidatesTags: [tagStatus],
      query: (status) => ({
        method: 'post',
        data: { status },
        url: URL.USER_STATUS_UPDATE,
      }),
    }),
    update: build.mutation<void, UpdateUserRequest>({
      invalidatesTags: [tagType],
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.USER.replace('{id}', String(id)),
      }),
    }),

    details: build.query<TeamMemberUser, UserDetailsRequest>({
      providesTags: [tagType],
      transformResponse: (response: UserDetailsResponse) => response.data,
      query: ({ id }) => ({
        method: 'get',
        url: URL.USER.replace('{id}', String(id)),
      }),
    }),
    status: build.query<UserNurseStatus | undefined, void>({
      providesTags: [tagStatus],
      transformResponse: (response: UserNurseStatusResponse) => response.data,
      query: () => {
        return {
          method: 'get',
          url: URL.USER_STATUS,
        };
      },
    }),
    online: build.query<ListData, OnlineListRequestQuery>({
      transformResponse: (response: ListResponse) => response.data,
      providesTags: (result) => providesList(tagType)(result?.users),
      query: ({ page = 1, limit = 20 }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const searchParams = {
          limit,
          offset,
        };
        const url = addParamsToUrl(URL.USERS_ONLINE, searchParams);

        return {
          url,
          method: 'get',
        };
      },
    }),
    list: build.query<ListData, ListRequestQuery>({
      transformResponse: (response: ListResponse) => response.data,
      providesTags: (result) => providesList(tagType)(result?.users),
      query: ({
        search,
        roleIds,
        statuses,
        page = 1,
        limit = 20,
        activityStatus,
      }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const filters = filterEmptyFields({
          roleIds,
          statuses,
          activityStatus,
        });
        const searchParams = {
          limit,
          offset,
          search,
          filters:
            Object.keys(filters).length > 0 ? JSON.stringify(filters) : null,
        };
        const url = addParamsToUrl(URL.USERS_LIST, searchParams);

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useListQuery,
  useOnlineQuery,
  useStatusQuery,
  useDetailsQuery,
  useLazyListQuery,
  useUpdateMutation,
  useLazyStatusQuery,
  useLazyOnlineQuery,
  useUpdateStatusMutation,
  usePushSubscribeMutation,
  usePushUnsubscribeMutation,
} = usersApi;
