import { useRef, useMemo, useEffect } from 'react';
import { useField, FormikValues, useFormikContext } from 'formik';
import {
  Col,
  Row,
  Utils,
  Details,
  Stepper,
  isDesktop,
  FormFooter,
  InputField,
  ButtonColors,
  UserRoleType,
  CorporateType,
  StandardButton,
  ButtonStyleTypes,
  SectionHeaderExpandable,
} from '@gv/triage-components';

import { Config, Routes, cvtRoles } from 'config';
import { selectHasRoles } from 'store/slices/auth';
import { assessmentsFieldsNames } from 'types/data';
import { useAppDispatch, useAppSelector } from 'store';
import { assessmentRequired, getIsSmsInputVisible } from 'utils/config';
import { scrollCaseToError, getSelectedCallOutcome } from 'utils/helpers';
import { baseSteps, discardSteps } from 'store/slices/case-form-step/config';
import {
  CallTab,
  FormCallOutcome,
  CallOutcomeType,
  ConsultationStep,
} from 'types';
import {
  back,
  next,
  changeStep,
  changeSteps,
  selectCaseFormStep,
} from 'store/slices/case-form-step';

import { PetInfo } from '../pet-info';
import { ClientInfo } from '../client-info';
import { useShowProtocols } from '../use-show-protocols';
import { SMSNotificationsField } from '../fields/sms-notifications-field';
import {
  Concern,
  HospitalFields,
  InternalReporting,
  StabilityAssessment,
} from '../fields';

import { Header } from './header';
import * as Styles from './styles';
import { PetField } from './pet-field';
import { Greetings } from './greetings';
import { GreetingInfo } from './greeting';
import { ClientField } from './client-field';
import { CaseMainInfo } from './case-main-info';
import { useFormEntities } from './use-form-entities';
import { useCaseFormSteps } from './use-case-form-steps';
import {
  CaseFormProps,
  CaseFormTextOutProps,
  CorporateOptionsProps,
} from './types';
import {
  stepTitle,
  surveyModal,
  stepTitleMobile,
  callOutcomeModel,
} from './config';
import {
  CallOutcomeMain,
  DepartmentSelect,
  CheckProtocolsInfo,
} from './call-outcome';

const { Addend } = Routes.ActionCenter.InComm;
const basePath = `${Routes.ActionCenter.Comm}/${CallTab.Consultation}`;

export const CaseForm = ({
  task,
  isEdit,
  setDVM,
  setPayment,
  setService,
  setReferral,
  onSubmitPress,
  footerLeftSlot,
  containerWidth,
  footerSubmitSlot,
  onDeadlineSubmit,
  isDeadlineLoading,
  footerAsMobileOnWidth,
}: CaseFormProps) => {
  const hasTask = Boolean(task);
  useCaseFormSteps(hasTask);
  const dispatch = useAppDispatch();
  const {
    values,
    errors,
    touched,
    isValid,
    setTouched,
    submitForm,
    isSubmitting,
    setFieldTouched,
  } = useFormikContext<FormikValues>();
  const formInner = useRef<HTMLDivElement | null>(null);
  const isCSR = useAppSelector(selectHasRoles([UserRoleType.CSR]));
  const isCVT = useAppSelector(selectHasRoles(cvtRoles));
  const {
    formId,
    client,
    clientId,
    hospital,
    hospitalId,
    isLastStep,
    isMainHospital,
    ...props
  } = useFormEntities();
  const showProtocols = useShowProtocols(values);
  const {
    stepsList,
    currentStep,
    maxOpenedStep,
    currentStepIndex = 0,
    maxOpenedStepIndex = 0,
  } = useAppSelector(selectCaseFormStep(formId));
  const isAssessmentRequired = assessmentRequired(values, isCVT);
  const selectedCallOutcome = getSelectedCallOutcome(values.callOutcome);

  const showHospitalFields =
    !hospital?.corporate_type ||
    hospital?.corporate_type !== CorporateType.Main;
  const isSmsInputVisible = getIsSmsInputVisible(hospital, isEdit);

  useEffect(() => {
    console.log({ stepsList, currentStep, maxOpenedStep });
  }, [currentStep, maxOpenedStep, stepsList]);
  const [callOutComeTypes] = useField(callOutcomeModel.type);
  const isEmergency = callOutComeTypes.value === CallOutcomeType.Emergency;
  const isAppointment = callOutComeTypes.value === CallOutcomeType.Appointment;

  const desktop = isDesktop();
  const steps = useMemo(() => {
    if (!stepsList) {
      return [];
    }
    const titles = !desktop ? stepTitleMobile : stepTitle;
    return stepsList.map((step) => titles[step]);
  }, [stepsList, desktop]);

  useEffect(() => {
    if (formInner.current) {
      formInner.current.scrollTop = 0;
    }
  }, [currentStep]);

  const backStep = (step?: ConsultationStep) => {
    setTouched({});
    dispatch(back({ step, formId }));
  };

  const validateStep = (step: ConsultationStep) => {
    if (currentStep !== step) {
      const currentIndex = stepsList.indexOf(currentStep);
      const index = stepsList.indexOf(step);
      return currentIndex >= index;
    }
    return true;
  };

  const backToStepIfNeeded = (step: ConsultationStep) => {
    if (currentStep !== step) {
      backStep(step);
    }
  };

  const nextStep = async (step?: ConsultationStep, isJump?: boolean) => {
    const isZipCodeRequired = Config.helpers.featureAvailableForHospital(
      hospitalId,
      'REQUIRED_CLIENT_ZIP_CODE'
    );

    if (validateStep(ConsultationStep.PetOwner) && errors.client_id) {
      backToStepIfNeeded(ConsultationStep.PetOwner);
      setFieldTouched('client_id');
      scrollCaseToError(errors);
      return;
    }

    if (
      validateStep(ConsultationStep.PetOwner) &&
      (errors.current_client || errors.sms_status)
    ) {
      backToStepIfNeeded(ConsultationStep.PetOwner);

      if (errors.sms_status) {
        setFieldTouched('sms_status');
      }
      if (errors.current_client) {
        setFieldTouched('current_client');
      }

      scrollCaseToError(errors);
      return;
    }

    if (validateStep(ConsultationStep.PetOwner) && errors.pets) {
      backToStepIfNeeded(ConsultationStep.PetOwner);
      setFieldTouched('pets');
      scrollCaseToError(errors);
      return;
    }

    if (
      validateStep(ConsultationStep.PetOwner) &&
      isZipCodeRequired &&
      errors?.hasClientZipCode
    ) {
      backToStepIfNeeded(ConsultationStep.PetOwner);
      setFieldTouched('hasClientZipCode');
      scrollCaseToError(errors);
      return;
    }

    if (
      validateStep(ConsultationStep.PetOwner) &&
      (errors.chief_complaints || errors.problem_summary)
    ) {
      backToStepIfNeeded(ConsultationStep.PetOwner);
      setFieldTouched('chief_complaints');
      setFieldTouched('problem_summary');
      scrollCaseToError(errors);
      return;
    }

    const assessmentErrors = Utils.Object.pick(errors, assessmentsFieldsNames);

    if (
      validateStep(ConsultationStep.ProblemSummary) &&
      Object.values(assessmentErrors).length > 0
    ) {
      backToStepIfNeeded(ConsultationStep.ProblemSummary);
      setTouched({
        ...touched,
        ...Object.fromEntries(
          Object.keys(assessmentErrors).map((fieldName) => [fieldName, true])
        ),
      });
      return;
    }

    const callOutcomeErrors = errors?.callOutcome as
      | FormCallOutcome
      | undefined;

    if (validateStep(ConsultationStep.CallOutcome) && callOutcomeErrors?.type) {
      backToStepIfNeeded(ConsultationStep.CallOutcome);
      setFieldTouched(callOutcomeModel.type);
      scrollCaseToError(errors);
      return;
    }
    setFieldTouched(surveyModal.feedback_email);
    setFieldTouched('problem_summary');
    setFieldTouched('chief_complaints');

    const subTypeError = callOutcomeErrors && Object.keys(callOutcomeErrors)[0];

    if (validateStep(ConsultationStep.CallOutcome) && subTypeError) {
      backToStepIfNeeded(ConsultationStep.CallOutcome);
      const isAdministrative = subTypeError === CallOutcomeType.Administrative;
      setFieldTouched(
        `callOutcome.${subTypeError}.${isAdministrative ? 'call.outcomes' : 'type'}`
      );
      scrollCaseToError(errors);
      return;
    }

    if (currentStep === ConsultationStep.CallOutcome) {
      backToStepIfNeeded(ConsultationStep.CallOutcome);
      if (errors.deposit_invoice_id) {
        setFieldTouched('deposit_invoice_id');
        scrollCaseToError(errors);
        return;
      }

      if (errors.customService) {
        setFieldTouched('customService');
        scrollCaseToError(errors);
        return;
      }

      if (errors.email_for_invoice) {
        setFieldTouched('email_for_invoice');
        scrollCaseToError(errors);
        return;
      }

      if (errors.is_emergency_fee_confirm || errors.is_species_seen_confirm) {
        if (errors.is_emergency_fee_confirm) {
          setFieldTouched('is_emergency_fee_confirm');
        }

        if (errors.is_species_seen_confirm) {
          setFieldTouched('is_species_seen_confirm');
        }

        scrollCaseToError(errors);
        return;
      }
    }

    if (isLastStep && !isJump) {
      if (isValid) {
        if (onSubmitPress) {
          onSubmitPress();
        } else {
          await submitForm();
          scrollCaseToError(errors);
        }
      } else {
        await submitForm();
      }
    } else {
      setTouched({});
      if (step) {
        console.log('[changeStep]: change step in nextStep', { step, formId });
        dispatch(changeStep({ step, formId }));
      } else {
        dispatch(next({ formId }));
      }
    }
  };
  const checkingOutCome =
    isEmergency || isAppointment
      ? !values.checkbox1 || !values.checkbox2 || !values.checkbox3
      : false;
  const isSubmitDisabled = Boolean(
    isSubmitting ||
      (isLastStep &&
        showProtocols &&
        (!values.checkProtocolsConfirm || checkingOutCome) &&
        ((isMainHospital && selectedCallOutcome?.subType) ||
          isMainHospital === false))
  );
  const nextButton = () => {
    if (currentStep === ConsultationStep.DeadlineIssue && onDeadlineSubmit) {
      return (
        <StandardButton
          text="Save and close"
          disabled={isDeadlineLoading}
          onClick={() => onDeadlineSubmit(values)}
        />
      );
    }

    if (isLastStep && footerSubmitSlot) {
      return footerSubmitSlot(nextStep);
    }

    return (
      <StandardButton
        onClick={() => nextStep()}
        disabled={isSubmitDisabled}
        text={isLastStep ? 'Submit' : 'Next'}
      />
    );
  };

  const footerRightSlot = currentStep !== ConsultationStep.Greeting && (
    <>
      <StandardButton
        text="Back"
        onClick={() => backStep()}
        colorType={ButtonColors.Secondary}
      />

      {nextButton()}
    </>
  );

  const onDeadline = () => {
    dispatch(
      changeSteps({
        formId,
        steps: discardSteps,
        step: ConsultationStep.DeadlineIssue,
      })
    );
  };

  const getStepElement = () => {
    switch (currentStep) {
      case ConsultationStep.Greeting:
        return (
          <>
            <Greetings />

            <Styles.ButtonsGrid hasTask={hasTask}>
              <StandardButton
                fullWidth
                text="Consultation"
                padding="16px 12px"
                colorType={ButtonColors.LightGrey}
                styleType={ButtonStyleTypes.Outline}
                onClick={() => {
                  dispatch(
                    changeSteps({
                      formId,
                      steps: baseSteps,
                      step: ConsultationStep.Greeting,
                    })
                  );
                  nextStep();
                }}
              />

              {task && (
                <>
                  <StandardButton
                    fullWidth
                    padding="16px 12px"
                    text="Case search & addend"
                    to={`${basePath}${Addend.Home}`}
                    colorType={ButtonColors.LightGrey}
                    styleType={ButtonStyleTypes.Outline}
                  />

                  <StandardButton
                    fullWidth
                    padding="16px 12px"
                    onClick={onDeadline}
                    text="Deadline issue"
                    colorType={ButtonColors.LightGrey}
                    styleType={ButtonStyleTypes.Outline}
                  />
                </>
              )}
            </Styles.ButtonsGrid>
          </>
        );
      case ConsultationStep.PetOwner:
        return (
          <div>
            <GreetingInfo
              isDynamicFlow={false}
              complaintsValue={values.chief_complaints}
            />
            {task ? (
              <ClientField task={task} />
            ) : (
              <ClientInfo clientId={clientId} />
            )}

            {isSmsInputVisible && <SMSNotificationsField />}

            {showHospitalFields && <HospitalFields />}

            {task ? (
              <PetField
                name="pets"
                task={task}
                client={client}
                editPath={Routes.ActionCenter.InComm.EditPet}
                basePath={`${Routes.ActionCenter.Comm}/${CallTab.Consultation}`}
                medicalRecordsPath={
                  Routes.ActionCenter.InComm.PetMedicalRecords
                }
              />
            ) : (
              <PetInfo
                pets={values.pets}
                clientId={clientId}
                petId={values.pet_id}
                hospitalId={values.hospital_id}
              />
            )}
          </div>
        );

      case ConsultationStep.ProblemSummary:
        return (
          <div>
            <Concern isCSR={isCSR} />
            <Row>
              <Col>
                <InputField
                  name="csr_plan"
                  placeholder="Add note"
                  type="textarea-autosize"
                  label="Plan for the CSR"
                />
              </Col>
            </Row>

            {isAssessmentRequired && (
              <>
                <StabilityAssessment isEdit={Boolean(!task)} />

                <Row>
                  <Col>
                    <InputField
                      label="Assessment"
                      name="assessment_note"
                      placeholder="Add note"
                      type="textarea-autosize"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <InputField
                      name="cvt_plan"
                      label="Plan for CVT"
                      placeholder="Add note"
                      type="textarea-autosize"
                    />
                  </Col>
                </Row>
              </>
            )}
          </div>
        );
      case ConsultationStep.CallOutcome:
        return (
          <CallOutcomeMain
            setDVM={setDVM}
            setPayment={setPayment}
            setService={setService}
            setReferral={setReferral}
          />
        );
      case ConsultationStep.DeadlineIssue:
        return (
          <Row>
            <Col>
              <InputField
                label="Notes"
                name="deadlineNote"
                placeholder="Add notes"
                type="textarea-autosize"
              />
            </Col>
          </Row>
        );
    }
  };

  return (
    <Details {...props}>
      <Styles.Form>
        <Styles.Inner ref={formInner}>
          {currentStep === ConsultationStep.Greeting && <Header />}

          <Styles.CaseInner>
            {currentStep !== ConsultationStep.Greeting &&
              currentStep !== ConsultationStep.PetOwner &&
              currentStep !== ConsultationStep.DeadlineIssue && (
                <CaseMainInfo />
              )}

            {stepsList && (
              <SectionHeaderExpandable
                title={
                  <Stepper
                    steps={steps}
                    current={currentStepIndex}
                    active={maxOpenedStepIndex}
                    onChange={(step) => {
                      const stepValue = stepsList[step];
                      if (step > currentStepIndex) {
                        nextStep(stepValue, true);
                      } else {
                        backStep(stepValue);
                      }
                    }}
                  />
                }
              />
            )}

            {getStepElement()}
          </Styles.CaseInner>

          {isLastStep && <InternalReporting />}
        </Styles.Inner>

        {(footerLeftSlot || footerRightSlot) && (
          <FormFooter
            left={footerLeftSlot}
            right={footerRightSlot}
            asMobile={
              containerWidth && footerAsMobileOnWidth
                ? containerWidth > 0 && containerWidth <= footerAsMobileOnWidth
                : undefined
            }
          />
        )}
      </Styles.Form>
    </Details>
  );
};

export {
  useFormEntities,
  useCaseFormSteps,
  DepartmentSelect,
  callOutcomeModel,
  CheckProtocolsInfo,
};
export type { CaseFormTextOutProps, CorporateOptionsProps };
