import { Image } from '@gv/triage-components';
import styled, { css } from 'styled-components';

export const Content = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
`;

export const MediaWrapper = styled.div<{ width: number; height: number }>`
  display: flex;
  overflow: hidden;
  cursor: pointer;

  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    background: ${theme.colors.black.tertiary.hover};
  `}
`;

export const Img = styled(Image)`
  z-index: inherit;
`;

export const Thumbnail = styled.div<{ width: number; height: number }>`
  position: relative;
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
`;

export const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    background: ${theme.colors.black.primary.standard}7F;

    svg {
      transform: scale(1.5);
      path {
        fill: ${theme.colors.white.primary.standard};
      }
    }
  `}
`;
