export const PetsTemplate = {
  petOwner: { mobile: 'repeat(2, 1fr)', desktop: 'repeat(2, 1fr) 24px' },
  default: { mobile: 'repeat(6, 1fr)', desktop: 'repeat(5, 1fr) 64px 24px' },
};

export const PetsCells = {
  petOwner: [
    { row: '1/2', col: '1/3' },
    { row: '2/3', col: '1/2' },
    { row: '2/3', col: '2/3' },
  ],
  mobile: [
    { row: '1/2', col: '1/6' },

    { row: '2/3', col: '5/7' },
    { row: '2/3', col: '3/5' },
    { row: '2/3', col: '1/3' },

    { row: '3/4', col: '1/4' },
    { row: '3/4', col: '4/7' },

    { row: '1/2', col: '5/7' },
  ],
};

export const PetsTemplate2 = {
  petOwner: { mobile: 'repeat(2, 1fr)', desktop: 'repeat(2, 1fr) 24px' },
  default: { mobile: 'repeat(8, 1fr)', desktop: 'repeat(7, 1fr) 64px 24px' },
};

export const PetsCells2 = {
  petOwner: [
    { row: '1/2', col: '1/3' },
    { row: '2/3', col: '1/2' },
    { row: '2/3', col: '2/3' },
  ],
  mobile: [
    { row: '1/2', col: '1/4' },
    { row: '2/3', col: '7/9' },

    { row: '2/3', col: '3/5' },
    { row: '2/3', col: '5/7' },
    { row: '2/3', col: '1/3' },
    { row: '3/4', col: '1/5' },

    { row: '4/5', col: '1/9' },
    { row: '3/4', col: '5/9' },

    { row: '1/2', col: '4/9' },
  ],
};
