import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiLoaderQuery } from 'store/query';

const tagType = 'Hospital bitwerx';
export const hospitalBirwerxApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiLoaderQuery,
  reducerPath: 'hospitalBitwerx',
  endpoints: (build) => ({
    get: build.query<any[], void>({
      providesTags: [tagType],
      transformResponse: (response: any) => response.data,
      query: () => ({
        method: 'get',
        url: URL.BITWERX_PRACTICES,
      }),
    }),
    updateHospital: build.mutation<any, any>({
      invalidatesTags: [tagType],
      transformResponse: (response: any) => response,
      query: (data: any) => ({
        data,
        method: 'post',
        url: URL.ASSIGN_BITWERX,
      }),
    }),
    getHospital: build.query<any[], any>({
      providesTags: [tagType],
      transformResponse: (response: any) => response.data,
      query: (data: any) => ({
        method: 'get',
        url: URL.CHECK_BITWERX_PRACTICES + `/${data.id}`,
      }),
    }),
    deleteHospital: build.mutation<any, any>({
      invalidatesTags: [tagType],
      transformResponse: (response: any) => response,
      query: (data: any) => ({
        method: 'post',
        url: URL.UNASSIGN_BITWERX,
        data: { hospital_id: data.id },
      }),
    }),
  }),
});
export const {
  useGetQuery,
  useGetHospitalQuery,
  useDeleteHospitalMutation,
  useUpdateHospitalMutation,
} = hospitalBirwerxApi;
