import { useEffect } from 'react';
import { useField, FormikValues, useFormikContext } from 'formik';
import { FlexLayout, InputField, CheckboxField } from '@gv/triage-components';

import { useAppDispatch } from 'store';
import { useShowProtocols } from 'components/cases';
import { useFormEntities } from 'components/cases/form';
import { setIsButtonHide } from 'store/slices/analytics-export';

import * as Styles from './styles';
import { CheckProtocolsInfoProps } from './types';

export const CheckProtocolsInfo = ({
  children,
  hideNotes,
  hideCheckProtocols,
  hospitalType = 'main',
}: CheckProtocolsInfoProps) => {
  const { values } = useFormikContext<FormikValues>();
  const [confirm] = useField('checkProtocolsConfirm');

  const { isMainHospital } = useFormEntities();
  const showProtocols = useShowProtocols(values);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      showProtocols &&
      !hideCheckProtocols &&
      ((!isMainHospital && hospitalType === 'other') ||
        (isMainHospital && hospitalType === 'main'))
    ) {
      dispatch(setIsButtonHide(!confirm.value));
    }
  }, [confirm.value]);

  const notesField =
    hospitalType === 'main' && !hideNotes ? (
      <InputField
        label="Notes"
        placeholder="Add note"
        name="additional_notes"
        type="textarea-autosize"
      />
    ) : null;

  if (
    showProtocols &&
    !hideCheckProtocols &&
    ((!isMainHospital && hospitalType === 'other') ||
      (isMainHospital && hospitalType === 'main'))
  ) {
    return (
      <FlexLayout gap={24} flexDirection="column">
        <Styles.Info>
          <FlexLayout flexDirection="column">
            <FlexLayout justifyContent="space-between">
              <p className="semibold">Check hospital protocols</p>
            </FlexLayout>

            <p>Please follow the hospital protocols, on the right sidebar.</p>
          </FlexLayout>

          <CheckboxField
            name="checkProtocolsConfirm"
            label="Please confirm that you checked hospital protocols."
          />
        </Styles.Info>

        <Styles.ChildrenWrapper disabled={!confirm.value}>
          {children}
          {notesField}
        </Styles.ChildrenWrapper>
      </FlexLayout>
    );
  } else {
    return (
      <>
        {children}
        {notesField}
      </>
    );
  }
};
