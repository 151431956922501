import { Middleware, configureStore, combineReducers } from '@reduxjs/toolkit';

import { ivrApi } from './api/ivr';
import { usersApi } from './api/users';
import { commonApi } from './api/common';
import { callReducer } from './slices/call';
import { invoicesApi } from './api/invoices';
import { feedbackApi } from './api/feedback';
import { contactsApi } from './api/contacts';
import { paymentsApi } from './api/payments';
import { discountApi } from './api/discount';
import { userInfoApi } from './api/user-info';
import { analyticsApi } from './api/analytics';
import { onTheWayApi } from './api/on-the-way';
import { marketingApi } from './api/marketing';
import { phoneTreeApi } from './api/phone-tree';
import { billingApi } from './api/mock-billing';
import { teamApi, teamSlice } from './api/team';
import { badgesReducer } from './slices/badges';
import { loaderReducer } from './slices/loader';
import { recordingsApi } from './api/recordings';
import { acceptanceApi } from './api/acceptance';
import { gvMessagesApi } from './api/gv-messages';
import { ringGroupsApi } from './api/ring-groups';
import { casesApi, casesSlice } from './api/cases';
import { medicationsApi } from './api/medications';
import { chatsApi, chatsSlice } from './api/chats';
import { types, authReducer } from './slices/auth';
import { gvWorkspaceApi } from './api/gv-workspace';
import { officeChatsApi } from './api/office-chats';
import { roomBitwerxApi } from './api/rooms-bitwerx';
import { dynamicFormsApi } from './api/dynamic-forms';
import { breedsApi, breedsSlice } from './api/breeds';
import { nursesApi, nursesSlice } from './api/nurses';
import { ivrGreetingsApi } from './api/ivr-greetings';
import { statesApi, statesSlice } from './api/states';
import { subscriptionsApi } from './api/subscriptions';
import { hospitalTasksApi } from './api/hospital-tasks';
import { breedsBitwerxApi } from './api/breeds-bitwerx';
import { genderBitwerxApi } from './api/genders-bitwerx';
import { speciesApi, speciesSlice } from './api/species';
import { exploreApi, exploreSlice } from './api/explore';
import { gvMessagesReducer } from './slices/gv-messages';
import { clientsApi, clientsSlice } from './api/clients';
import { gendersApi, gendersSlice } from './api/genders';
import { corporateGroupApi } from './api/corporate-group';
import { gvChatsApi, gvChatsSlice } from './api/gv-chats';
import { speciesBitwerxApi } from './api/species-bitwerx';
import { employeBitwerxApi } from './api/employes-bitwerx';
import { hospitalBirwerxApi } from './api/hospital-bitwerx';
import { analyticsExportApi } from './api/analytics-export';
import { smsNotificationApi } from './api/sms-notification';
import { transferOptionsApi } from './api/transfer-options';
import { hospitalPricingApi } from './api/hospital-pricing';
import { subscriptionsSlice } from './slices/subscriptions';
import { providerBitwerxApi } from './api/providers-bitwerx';
import { caseLogsApi, caseLogsSlice } from './api/case-logs';
import { chatMessagesReducer } from './slices/chat-messages';
import { schedulingMatrixApi } from './api/scheduling-matrix';
import { hospitalServicesApi } from './api/hospital-services';
import { communicationLogApi } from './api/communication-log';
import { caseFormStepReducer } from './slices/case-form-step';
import { packageTemplatesApi } from './api/package-templates';
import { workflowsApi, workflowsSlice } from './api/workflows';
import { timeZonesApi, timeZonesSlice } from './api/timezones';
import { hospitalsApi, hospitalsSlice } from './api/hospitals';
import { callNotificationsApi } from './api/call-notifications';
import { callFlowsApi, callFlowsSlice } from './api/call-flows';
import { caseNotificationsApi } from './api/case-notifications';
import { voicemailApi, voicemailSlice } from './api/voicemails';
import { greetingApi, greetingSlice } from './api/case-greeting';
import { internalTeamTasksApi } from './api/internal-team-tasks';
import { clinicDvmsApi, clinicDvmsSlice } from './api/clinic-dvms';
import { gvContactsApi, gvContactsSlice } from './api/gv-contacts';
import { analyticsExportReducer } from './slices/analytics-export';
import { dynamicFormStepReducer } from './slices/dynamic-form-step';
import { actionItemApi, actionItemSlice } from './api/action-items';
import { actionQueueApi, actionQueueSlice } from './api/action-queue';
import { permissionsApi, permissionsSlice } from './api/role-permissions';
import { pricingApi, pricingSlice, priceServicesSlice } from './api/pricing';
import { onCallProtocolApi, onCallProtocolSlice } from './api/on-call-protocol';
import {
  chiefComplaintsApi,
  chiefComplaintsSlice,
} from './api/chief-complaints';
import {
  walkthroughReducer,
  walkthroughListenerMiddleware,
} from './slices/walkthrough';
import {
  hospitalsReferralsApi,
  hospitalReferralsSlice,
} from './api/hospital-referrals';
import {
  erServicesApi,
  erServicesSlice,
  erSubservicesSlice,
} from './api/er-services';
import {
  calendarApi,
  visitHoursApi,
  clinicHoursApi,
  onCallHoursApi,
  appointmentApi,
  virtualExamsApi,
  forwardingHoursApi,
  appointmentHoursApi,
} from './api/calendar';
/**
 * Array of all API objects used in the application.
 */
const apis = [
  ivrApi,
  teamApi,
  chatsApi,
  usersApi,
  casesApi,
  commonApi,
  nursesApi,
  statesApi,
  breedsApi,
  billingApi,
  gvChatsApi,
  clientsApi,
  pricingApi,
  speciesApi,
  gendersApi,
  exploreApi,
  feedbackApi,
  invoicesApi,
  greetingApi,
  caseLogsApi,
  userInfoApi,
  onTheWayApi,
  calendarApi,
  paymentsApi,
  discountApi,
  contactsApi,
  timeZonesApi,
  hospitalsApi,
  phoneTreeApi,
  callFlowsApi,
  voicemailApi,
  analyticsApi,
  marketingApi,
  workflowsApi,
  gvMessagesApi,
  gvContactsApi,
  visitHoursApi,
  clinicDvmsApi,
  acceptanceApi,
  recordingsApi,
  ringGroupsApi,
  erServicesApi,
  actionItemApi,
  gvWorkspaceApi,
  onCallHoursApi,
  clinicHoursApi,
  actionQueueApi,
  permissionsApi,
  medicationsApi,
  officeChatsApi,
  virtualExamsApi,
  appointmentApi,
  appointmentHoursApi,
  ivrGreetingsApi,
  dynamicFormsApi,
  subscriptionsApi,
  hospitalTasksApi,
  corporateGroupApi,
  onCallProtocolApi,
  smsNotificationApi,
  analyticsExportApi,
  chiefComplaintsApi,
  forwardingHoursApi,
  transferOptionsApi,
  hospitalPricingApi,
  hospitalServicesApi,
  schedulingMatrixApi,
  communicationLogApi,
  packageTemplatesApi,
  internalTeamTasksApi,
  callNotificationsApi,
  caseNotificationsApi,
  hospitalsReferralsApi,
  providerBitwerxApi,
  hospitalBirwerxApi,
  speciesBitwerxApi,
  breedsBitwerxApi,
  genderBitwerxApi,
  roomBitwerxApi,
  employeBitwerxApi,
];

/**
 * Combines all the reducers into a single root reducer.
 */
const appReducer = combineReducers({
  ...Object.fromEntries(apis.map((api) => [api.reducerPath, api.reducer])),
  auth: authReducer,
  call: callReducer,
  loader: loaderReducer,
  badges: badgesReducer,
  gvMessages: gvMessagesReducer,
  walkthrough: walkthroughReducer,
  caseFormStep: caseFormStepReducer,
  chatMessages: chatMessagesReducer,
  [teamSlice.name]: teamSlice.reducer,
  [casesSlice.name]: casesSlice.reducer,
  [chatsSlice.name]: chatsSlice.reducer,
  dynamicFormStep: dynamicFormStepReducer,
  analyticsExport: analyticsExportReducer,
  [breedsSlice.name]: breedsSlice.reducer,
  [statesSlice.name]: statesSlice.reducer,
  [nursesSlice.name]: nursesSlice.reducer,
  [speciesSlice.name]: speciesSlice.reducer,
  [clientsSlice.name]: clientsSlice.reducer,
  [pricingSlice.name]: pricingSlice.reducer,
  [gendersSlice.name]: gendersSlice.reducer,
  [exploreSlice.name]: exploreSlice.reducer,
  [gvChatsSlice.name]: gvChatsSlice.reducer,
  [greetingSlice.name]: greetingSlice.reducer,
  [caseLogsSlice.name]: caseLogsSlice.reducer,
  [timeZonesSlice.name]: timeZonesSlice.reducer,
  [callFlowsSlice.name]: callFlowsSlice.reducer,
  [hospitalsSlice.name]: hospitalsSlice.reducer,
  [voicemailSlice.name]: voicemailSlice.reducer,
  [workflowsSlice.name]: workflowsSlice.reducer,
  [gvContactsSlice.name]: gvContactsSlice.reducer,
  [actionItemSlice.name]: actionItemSlice.reducer,
  [clinicDvmsSlice.name]: clinicDvmsSlice.reducer,
  [erServicesSlice.name]: erServicesSlice.reducer,
  [actionQueueSlice.name]: actionQueueSlice.reducer,
  [permissionsSlice.name]: permissionsSlice.reducer,
  [subscriptionsSlice.name]: subscriptionsSlice.reducer,
  [erSubservicesSlice.name]: erSubservicesSlice.reducer,
  [priceServicesSlice.name]: priceServicesSlice.reducer,
  [onCallProtocolSlice.name]: onCallProtocolSlice.reducer,
  [chiefComplaintsSlice.name]: chiefComplaintsSlice.reducer,
  [hospitalReferralsSlice.name]: hospitalReferralsSlice.reducer,
});

/**
 * Resets the state of all API reducers.
 *
 * @param dispatch - The Redux dispatch function
 */
export const resetApiState = (dispatch: any) => {
  apis.forEach((api) => dispatch(api.util.resetApiState()));
};

/**
 * Root reducer with special handling for logout action.
 *
 * @param state - The current state
 * @param action - The action to handle
 * @returns The new state
 */
const rootReducer = (state: any, action: any) => {
  if (action.type === types.logout) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

/**
 * Middleware array including API middlewares and custom middlewares.
 */
const middlewares: Middleware[] = [...apis, walkthroughListenerMiddleware].map(
  (api) => api.middleware
);

/**
 * Configures the Redux store with the root reducer and middleware.
 */
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

/**
 * Root state type derived from the store.
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * Dispatch type derived from the store.
 */
export type AppDispatch = typeof store.dispatch;
