import styled, { css } from 'styled-components';
import { FlexLayout } from '@gv/triage-components';

export const Content = styled(FlexLayout)<{ asSidePanel: boolean }>(
  ({ theme, asSidePanel }) => ({
    gap: '24px',
    padding: '16px',
    boxShadow: `inset ${asSidePanel ? 1 : 0}px -1px 0 ${theme.config.border}`,
  })
);

export const Container = styled(FlexLayout)<{ asSidePanel: boolean }>`
  width: 100%;
  height: 100%;

  ${({ theme, asSidePanel }) => css`
    box-shadow: ${asSidePanel
      ? `inset 1px 0 0 ${theme.config.border}`
      : 'none'};
  `}
`;

export const PlayerContainer = styled(FlexLayout)`
  width: 100%;
`;
