import { useMemo, useState } from 'react';
import { Icon, Events, isMobile, ActionQueueType } from '@gv/triage-components';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { Routes } from 'config';
import { Chat } from 'pages/action-center/comm/chat/styles';

import * as Styles from './styles';
import { CaseEventsProps } from './types';

export const CaseEvents = ({
  logs,
  currentCase,
  setSelectedEvent,
}: CaseEventsProps): JSX.Element => {
  const [openedEvents, setOpenedEvents] = useState<number[]>(
    logs.length > 0 ? [logs[0].caseId!] : []
  );

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mobile = isMobile();

  const sizes = useMemo(() => {
    const size = mobile ? 120 : 180;
    return { width: size, height: size };
  }, [mobile]);

  const task = useMemo(() => {
    return {
      type: ActionQueueType.Chat,
      c_id: currentCase?.client_id,
      from: currentCase?.client_phone,
      h_is_dynamic_flow_enabled: false,
      c_name: currentCase?.client_name,
      channelName: currentCase?.chatHistory?.[0]?.room_id,
      status: parseInt(currentCase?.chatHistory?.[0]?.status ?? '0'),
    };
  }, [currentCase?.client_id]);

  return (
    <Styles.Content>
      {logs.length > 0 ? (
        <Events
          cases={logs}
          opened={openedEvents}
          setOpened={setOpenedEvents}
          baseCaseLink={Routes.Cases.Home + '/'}
          onClick={(event) => setSelectedEvent(event)}
        />
      ) : currentCase?.chatHistory[0]?.file ? (
        <div>
          <Dialog fullWidth open={open} maxWidth="lg" onClose={handleClose}>
            <DialogTitle>Video Recording</DialogTitle>
            <DialogContent>
              <video
                controls
                autoPlay
                width="100%"
                src={currentCase?.chatHistory[0]?.file}
              >
                Your browser does not support the video tag.
              </video>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={handleClose}
                sx={{
                  textTransform: 'none',
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Styles.MediaWrapper {...sizes} onClick={handleClickOpen}>
            <Styles.Thumbnail {...sizes}>
              {currentCase?.chatHistory[0]?.file && (
                <Styles.Img
                  {...sizes}
                  objectFit="cover"
                  alt={'video call recording'}
                  src={currentCase?.chatHistory[0]?.file}
                />
              )}

              <Styles.Overlay>
                <Icon.Play />
              </Styles.Overlay>
            </Styles.Thumbnail>
          </Styles.MediaWrapper>
        </div>
      ) : currentCase?.chatHistory?.[0]?.status ? (
        <Chat task={task} fullWidth={true} initialLoadByComponent={true} />
      ) : (
        'No events yet'
      )}
    </Styles.Content>
  );
};
