import axios from 'axios';
import { Utils } from '@gv/triage-components';

import { apiInstance } from 'api/instance';
import { setLoading } from 'store/slices/loader';

import { URL } from '../constants';

const backendURL = process.env.REACT_APP_SUBSCRIPTION_URL;

export const updateVideoCallUser = async (
  id: string | number | undefined,
  hospital_id: string | number | undefined,
  values: any,
  token: any,
  dispatch: any
) => {
  try {
    const response = await axios.put(
      `${backendURL}/${URL.UPDATE_USER_VIDEO_CALL}/${id}`,
      values,
      {
        headers: {
          Authorization: token,
          hospital_id: hospital_id,
        },
      }
    );
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error updating video call user details:', error);
    throw error;
  }
};

export const assignVideoCall = async (
  room_id: string,
  user_name: string | number | undefined,
  user_id: string | number | undefined
) => {
  apiInstance.get(
    Utils.Query.addParamsToUrl(URL.ASSIGN_TO_VIDEO_CALL, {
      To: room_id,
      name: `${user_name}_${user_id}`,
    })
  );
};

export const endVideoCall = async (
  id: string | number | undefined,
  values: any,
  token: any,
  dispatch: any
) => {
  try {
    const response = await axios.post(
      `${backendURL}/${URL.END_VIDEO_CALL}`,
      values,
      {
        headers: {
          hospital_id: id,
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    console.error('Error updating video call user details:', error);
    throw error;
  }
};
