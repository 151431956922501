import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Loader,
  Details,
  FormFooter,
  BackButton,
  useNavigate,
  ButtonTypes,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

// import { useAppSelector } from 'store';
// import { useGetHospitalQuery } from 'store/api/hospital-bitwerx';
// import { selectCurrentTask } from 'store/api/action-queue/selectors';

import * as CommonStyles from '../../styles';

import { PetFormProps } from './types';
import { PetFields } from './petFields';
import { PetFieldsBitwerx } from './petFieldBitwerx';
import { ageValidate, validationSchema } from './config';

const PetForm = ({
  isEdit,
  onSubmit,
  isFetching,
  initialValues,
}: PetFormProps) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  // const task = useAppSelector(selectCurrentTask);
  // const { data } = useGetHospitalQuery({ id: task?.h_id });
  const [isPracticeSync, setPracticeSync] = useState<boolean>(false);

  useEffect(() => {
    setPracticeSync(false);
  }, []);

  // useEffect(() => {
  //   setPracticeSync(false);
  //   const getBitwerxStatus = async (response: any) => {
  //     const bitwerx_practice_id = response?.response?.bitwerx_practice_id;
  //     if (bitwerx_practice_id) {
  //       setPracticeSync(true);
  //     }
  //   };
  //   getBitwerxStatus(data);
  // }, [data]);
  return (
    <Details isLoading={isFetching}>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        validate={ageValidate}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting }) => (
          <CommonStyles.Form>
            <Row>
              <Col>
                <BackButton text={isEdit ? 'Edit Pet' : 'New Pet'} />
              </Col>
            </Row>

            <CommonStyles.Inner>
              {!isPracticeSync && <PetFields setLoading={setLoading} />}
              {isPracticeSync && <PetFieldsBitwerx setLoading={setLoading} />}
            </CommonStyles.Inner>

            <FormFooter
              right={
                <>
                  <StandardButton
                    text="Cancel"
                    onClick={() => navigate(-1)}
                    colorType={ButtonColors.Secondary}
                  />

                  <StandardButton
                    text="Save"
                    type={ButtonTypes.Submit}
                    disabled={!dirty || isSubmitting}
                  />
                </>
              }
            />

            <Loader absolutePosition isLoading={isLoading} />
          </CommonStyles.Form>
        )}
      </Formik>
    </Details>
  );
};

export { PetForm };
