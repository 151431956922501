import { createSelector } from '@reduxjs/toolkit';
import { QueueTab, ActionQueue, ActionQueueType } from '@gv/triage-components';

import { RootState } from 'store/store';
import {
  isMineTask,
  getQueueItemId,
  isMineInitializingTask,
} from 'utils/helpers';

import { ActionQueueState } from './types';

export const isSame = (
  task: ActionQueue,
  currentTaskId: ActionQueueState['currentTaskId']
) => {
  return getQueueItemId(task) === currentTaskId;
};

const actionQueueStore = (store: RootState) => store?.actionQueue;

export const selectActionQueueFromStore = (
  store: ActionQueueState,
  queueTab: QueueTab
) => {
  const id = store.receiverId;
  console.log('store.entities', store.entities);
  let items = Object.values(store.entities).filter(
    (entity: ActionQueue | undefined) => {
      let mine = isMineTask(entity, id);
      if (!entity || entity.call_transfered) {
        return false;
      }

      const connected = !!entity?.connected;
      const disconnected = entity?.disconnected;
      const initializing = !!entity.initializing;
      const initializingByMe =
        initializing && isMineInitializingTask(entity, id);

      switch (queueTab) {
        case QueueTab.Queue:
          if (initializing && !initializingByMe) {
            return false;
          }
          return !connected && !disconnected;
        case QueueTab.Team:
          if (initializing && !initializingByMe) {
            return true;
          }
          return connected && !disconnected && !mine;
        case QueueTab.Active:
          return (connected || disconnected) && mine;
        default:
          return false;
      }
    }
  );
  return items as ActionQueue[];
};

export const selectActionQueue = (queueTab: QueueTab) =>
  createSelector([actionQueueStore], (store) => {
    if (!store) {
      return [];
    }
    return selectActionQueueFromStore(store, queueTab);
  });

export const selectCurrentTasks = createSelector(
  [actionQueueStore],
  (store) => {
    const { currentTasks, currentTaskId } = store ?? {};
    if (!currentTasks) {
      return [];
    }
    return [
      ...currentTasks.map((task) => ({
        ...task,
        $isCurrentTask: currentTaskId ? isSame(task, currentTaskId) : false,
      })),
    ].sort((task) => (task.$isCurrentTask ? -1 : 0));
  }
);

const getCurrentTask = (store?: ActionQueueState) => {
  const { currentTasks, currentTaskId } = store ?? {};
  if (!currentTaskId || !currentTasks || !currentTasks.length) {
    return undefined;
  }
  const task = currentTasks.find((item) => isSame(item, currentTaskId));
  if (!task) {
    return undefined;
  }
  return {
    ...task,
    $isCurrentTask: true,
  };
};

export const selectCurrentTask = createSelector([actionQueueStore], (store) =>
  getCurrentTask(store)
);

export const selectTaskByCSID = (csid?: string) =>
  createSelector([actionQueueStore], (store) => {
    if (!csid || !store) {
      return undefined;
    }
    const { currentTasks } = store ?? {};
    return currentTasks.find((item) => item.CallSID === csid);
  });

export const selectCurrentTaskForm = createSelector(
  [actionQueueStore],
  (store) => {
    const { currentTaskId } = store ?? {};
    if (!currentTaskId) {
      return undefined;
    }
    return store?.taskForms[currentTaskId];
  }
);

export const selectCurrentTaskId = createSelector(
  [actionQueueStore],
  (store) => store?.currentTaskId
);

export const selectTaskForResponse = createSelector(
  [actionQueueStore],
  (store) => {
    const { currentTasks, taskIdForResponse } = store ?? {};
    if (!taskIdForResponse || !currentTasks || !currentTasks.length) {
      return undefined;
    }
    const task = currentTasks.find((item) => isSame(item, taskIdForResponse));
    return task;
  }
);

export const selectCurrentCallFlowForm = createSelector(
  [actionQueueStore],
  (store) => {
    const { currentTaskId } = store ?? {};
    if (!currentTaskId) {
      return undefined;
    }
    return store?.callFlowForms[currentTaskId];
  }
);

export const selectOutboundIsBlocked = createSelector(
  [actionQueueStore],
  (store) => {
    if (!store) {
      return false;
    }
    const task = getCurrentTask(store);
    return task && task.isConnectedByMe && !task.isHolding;
  }
);

export const selectTasksByType = (queueTab: QueueTab) =>
  createSelector([actionQueueStore], (store) => {
    return (
      selectActionQueueFromStore(store, queueTab)?.reduce(
        (result, task) => {
          const { type } = task;

          if (type in result) {
            result[type].push(task);
          } else {
            result[type] = [task];
          }

          return result;
        },
        {} as { [key in ActionQueueType]: ActionQueue[] }
      ) ?? {}
    );
  });

export const selectTaskIsLoading = (task?: ActionQueue) =>
  createSelector([actionQueueStore], (store) => {
    if (!store || !task) {
      return false;
    }
    const { loadingTasks } = store;
    const id = getQueueItemId(task);
    return loadingTasks.includes(id);
  });

export const selectLoadingTasks = createSelector(
  [actionQueueStore],
  (store) => {
    return new Set(store?.loadingTasks ?? []);
  }
);

export const selectTaskByCaseId = (caseId?: number | string) =>
  createSelector([actionQueueStore], (store) => {
    if (!store || !caseId) {
      return undefined;
    }
    const { entities } = store;
    return Object.values(entities).find(
      (task) => String(task?.case_id) === String(caseId)
    );
  });

export const selectFormByTaskId = (taskId?: string) =>
  createSelector([actionQueueStore], (store) => {
    if (!store || !taskId) {
      return undefined;
    }
    const { taskForms } = store;
    return taskForms[taskId];
  });

export const selectIsSetClientInProgress = (task: ActionQueue) =>
  createSelector(
    [actionQueueStore],
    (store) =>
      store?.clientSetInProgress.includes(getQueueItemId(task)) ?? false
  );

export const selectHasInQueue = (task?: ActionQueue) =>
  createSelector([actionQueueStore], (store) => {
    if (!task) {
      return false;
    }
    return store?.ids.includes(getQueueItemId(task)) ?? false;
  });

export const selectIsTaskSwitching = createSelector(
  [actionQueueStore],
  (store) => store.isTaskSwitching
);

export const selectDvmCallForm = createSelector(
  [actionQueueStore],
  (store) => store.transferCallForm
);
