import dayjs from 'dayjs';
import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { acceptanceApi, acceptanceTags } from 'store/api/acceptance';

import { generateItems } from '../helpers';
import {
  EventsResponse,
  AppointmentListRequest,
  AppointmentDeleteRequest,
  VirtualExamsCreateRequest,
} from '../types';

import JSONData from './calendar.json';
import { AppointmentListResponse } from './types';

const tagTypes = ['Appointments'];
const { data: calendarData } = JSONData;

export const appointmentApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'appointmentApi',
  endpoints: (build) => ({
    delete: build.mutation<void, AppointmentDeleteRequest>({
      invalidatesTags: tagTypes,
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        dispatch(acceptanceApi.util.invalidateTags(acceptanceTags));
      },
      query: ({ id, slot, docId, notify, groupId, message, hospitalId }) => ({
        method: 'delete',
        url: Utils.Query.addParamsToUrl(`${URL.APPOINTMENT_DELETE}`, {
          id,
          message,
          doctor_id: docId,
          group_id: groupId,
          notification: notify,
          hospital_id: hospitalId,
          slot: JSON.stringify(slot),
        }),
      }),
    }),

    list: build.query<EventsResponse, AppointmentListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: AppointmentListResponse, meta, arg) => {
        const data: any = response?.data || {};
        if (arg.localTimezone) {
          data.timezone = { time_zone_code: dayjs.tz.guess() };
        }
        return generateItems(calendarData, true, arg.end);
      },
      query: ({ ids, end, start, hospitalId, includeDoctorInfo }) => ({
        method: 'get',
        url: Utils.Query.addParamsToUrl(URL.APPOINTMENT_LIST, {
          end_date: end,
          start_date: start,
          includeDoctorInfo,
          hospital_id: hospitalId,
          doctor_ids: JSON.stringify(ids),
        }),
      }),
    }),

    create: build.mutation<void, VirtualExamsCreateRequest>({
      invalidatesTags: tagTypes,
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        dispatch(acceptanceApi.util.invalidateTags(acceptanceTags));
      },
      query: ({
        slot,
        docId,
        every,
        endDate,
        startDate,
        hospitalId,
        clinicDays,
        description,
        time_zone_code,
        ...props
      }) => ({
        method: 'post',
        url: URL.APPOINTMENT_SCHEDULE,
        data: {
          time_zone_code,
          doctor_id: docId,
          end_date: endDate,
          every: String(every),
          start_date: startDate,
          hospital_id: String(hospitalId),
          time_slots: JSON.stringify(slot),
          clinic_days: JSON.stringify(clinicDays),
          description:
            description === '' || description === null
              ? undefined
              : description,
          ...props,
        },
      }),
    }),
  }),
});

export { tagTypes as appointmentTags };
