import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { isLogoutAction } from '../auth';

import { CallData } from './types';

const initialState: CallData = {
  isOnCall: false,
  isOnVideoCall: false,
  activeVideoCall: null,
  isOutboundCall: false,
  isVideoCallEnded: false,
  isVideoCallSubmitted: true,
};

export const callSlice = createSlice({
  initialState,
  name: 'callSlice',
  extraReducers: (builder) => {
    builder.addMatcher(isLogoutAction, (state) => {
      Object.entries(initialState).forEach((item) => {
        const [key, value] = item;
        // @ts-ignore
        state[key] = value;
      });
    });
  },
  reducers: {
    setOnCall: (state, { payload }: PayloadAction<boolean>) => {
      state.isOnCall = payload;
    },
    setOnVideoCall: (state, { payload }: PayloadAction<boolean>) => {
      state.isOnVideoCall = payload;
    },
    setActiveVideoCall: (state, { payload }: PayloadAction<any>) => {
      state.activeVideoCall = payload;
    },
    setOutboundCall: (state, { payload }: PayloadAction<boolean>) => {
      state.isOutboundCall = payload;
    },
    setVideoCallEnded: (state, { payload }: PayloadAction<boolean>) => {
      state.isVideoCallEnded = payload;
    },
    setVideoCallSubmitted: (state, { payload }: PayloadAction<boolean>) => {
      state.isVideoCallSubmitted = payload;
    },
  },
});

const {
  reducer: callReducer,
  actions: {
    setOnCall,
    setOnVideoCall,
    setOutboundCall,
    setVideoCallEnded,
    setActiveVideoCall,
    setVideoCallSubmitted,
  },
} = callSlice;

const callStore = (store: RootState) => store?.call;

export const selectIsOnCall = () =>
  createSelector([callStore], (store) => store?.isOnCall ?? false);

export const selectIsOutboundCall = () =>
  createSelector([callStore], (store) => store?.isOutboundCall ?? false);

export {
  setOnCall,
  callReducer,
  setOnVideoCall,
  setOutboundCall,
  setVideoCallEnded,
  setActiveVideoCall,
  setVideoCallSubmitted,
};
