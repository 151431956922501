import dayjs, { Dayjs } from 'dayjs';
import { Call } from '@twilio/voice-sdk';
import { generatePath } from 'react-router-dom';
import scrollIntoView from 'scroll-into-view-if-needed';
import { FormikValues, FormikErrors, FieldInputProps } from 'formik';
import {
  Pet,
  Icon,
  Utils,
  State,
  Client,
  Nullable,
  FileType,
  useAlert,
  Hospital,
  SimpleDays,
  TriageUser,
  UserStatus,
  UploadFile,
  FlexLayout,
  DialogType,
  ChatMessage,
  ActionQueue,
  DialogProps,
  PriorityType,
  ERSubService,
  SelectOption,
  CorporateType,
  PlaceBackProps,
  ActionQueueType,
  WorkingHoursUtils,
  WalkinDropoffValues,
  ConversationCardProps,
  AvatarPlaceholderType,
  CardConversationStatus,
  VideoCallParticipantAudio,
} from '@gv/triage-components';

import { api } from 'api';
import { gvFee, Config, Routes } from 'config';
import { AuthClient } from 'store/slices/auth/types';
import { useUpdateMutation } from 'store/api/clients';
import { Participant } from 'context/video-comm/types';
import { UnreadCountData } from 'store/api/gv-chats/types';
import { HospitalListData } from 'store/api/hospitals/types';
import { ActionItemData } from 'store/api/action-items/types';
import {
  ActionQueueTypeNumber,
  assessmentsFieldsNames,
  ActionQueueTypeFromNumber,
} from 'types/data';
import {
  Case,
  CallTab,
  TaskType,
  TaskClass,
  PassedStep,
  CallOutcome,
  NurseStatus,
  ChiefComplaint,
  CallOutcomeType,
  FormCallOutcome,
  ConsultationStep,
  ExtendedTaskClass,
  CallFlowFolderName,
  CallOutcomeDetails,
  SpecialityShortName,
  SubscriptionPackage,
  CallOutcomeEmergency,
  HospitalTasksCountData,
  CallOutcomeDetailsType,
  FormCallOutcomeDetails,
  CallOutcomeAppointment,
  CallOutcomeDetailsTypes,
  WalkinDropoffFormValues,
} from 'types';

import * as Styles from './styles';
import { assessmentsRadioGridInputProps } from './config';
import { initialCaseForm, initialCallOutcome } from './initials';

const {
  Date: { formatDate },
  String: { replaceAll },
  Quill: { convertHtmlToDelta },
  Validation: { isValidPhoneNumber },
  Helpers: {
    getPlural,
    textValue,
    joinStrings,
    formatPrice,
    formatPhone,
    mergeObjects,
    transformPhone,
  },
} = Utils;

export const innerPath = (path: string, parent: string): string =>
  path.replace(parent, '');

export const conversationName = (user?: TriageUser): string => {
  return `${user?.name}_${user?.id}`;
};

interface ConversationProps extends Omit<ConversationCardProps, 'queueTab'> {
  disabledReason: string;
}

export const converstationProps = (queue: ActionQueue): ConversationProps => {
  const tags = [];
  if (queue.h_short_state) {
    tags.push(queue.h_short_state);
  } else if (queue.h_state) {
    tags.push(queue.h_state);
  }
  if (queue.h_speciality) {
    tags.push(SpecialityShortName[queue.h_speciality] || queue.h_speciality);
  }
  // TODO: uncomment later
  // if (queue.h_protocol_level) {
  //   tags.push(ProtocolLevelLabels[queue.h_protocol_level]);
  // }
  let waiting: number | undefined;
  let started: number | undefined;
  const {
    type,
    start_time,
    place_back_time,
    disconnected_at,
    not_allowed_reason,
  } = queue;

  if (disconnected_at) {
    started = disconnected_at;
    waiting = start_time;
  } else if (place_back_time) {
    started = place_back_time;
    waiting = start_time;
  } else {
    started = start_time;
  }
  const connected = queue.isConnectedByMe && !queue.call_transfered;

  const teamMemberComponents = queue.assignTo?.split('_');
  teamMemberComponents?.pop();
  const teamMember = teamMemberComponents?.join('_');
  let reason = not_allowed_reason ? String(not_allowed_reason) : '';
  return {
    started,
    waiting,
    teamMember,
    incoming: true, // TODO: set false when need to use place a call
    phone: queue.from,
    notes: queue.notes,
    moodId: queue.mood,
    ivr: queue.ivr_name,
    issues: queue.issues,
    disabledReason: reason,
    conversationType: type,
    priority: queue.priority,
    clientName: queue.c_name,
    hospitalName: queue.h_name,
    disabled: !queue.is_allow_to_handle, // TODO: change when new types will be supported
    tags: tags.length ? tags : undefined,
    disconnectedAt: queue.disconnected_at,
    callStarted: connected ? queue.picked_at : undefined,
  };
};

export const activeCallProps = (
  queue: ActionQueue,
  isActive?: boolean,
  formValues?: FormikValues
) => {
  const {
    from,
    type,
    c_id,
    mood,
    notes,
    h_name,
    c_name,
    issues,
    priority,
    $finished,
    picked_at,
    isHolding,
    $isOnHold,
    putOnHoldAt,
    disconnected,
    initializing,
    hold_time_sec,
    $isCurrentTask,
    isConnectedByMe,
    disconnected_at,
    call_transfered,
    picked_timestamp,
  } = queue;

  let status: CardConversationStatus;
  const notConnected =
    type === ActionQueueType.Chat ? false : isConnectedByMe === false;
  if ($isCurrentTask) {
    if (initializing && notConnected) {
      status = CardConversationStatus.Connecting;
    } else if (notConnected || call_transfered) {
      status = CardConversationStatus.Ended;
    } else if ($isOnHold || isHolding) {
      status = CardConversationStatus.Holding;
    } else {
      status = CardConversationStatus.Active;
    }
  } else if (notConnected) {
    status = CardConversationStatus.EndedHolding;
  } else {
    status = CardConversationStatus.Holding;
  }

  const isVoice = type === ActionQueueType.Voice;
  const isVideo = type === ActionQueueType.Video;

  let usedPriority = priority;
  let usedNote = notes;
  let usedMood = mood;
  let usedIssues = issues;

  if (
    isActive ||
    (!isVoice && !isVideo && status === CardConversationStatus.Active)
  ) {
    if (
      formValues?.priority_of_concern &&
      (!usedPriority || usedPriority !== formValues.priority_of_concern)
    ) {
      usedPriority = formValues.priority_of_concern;
    }

    if (typeof formValues?.problem_summary === 'string') {
      usedNote = formValues.problem_summary;
    }

    if (!usedMood && formValues?.queueCardMood) {
      usedMood = formValues.queueCardMood;
    }

    if (formValues?.chief_complaints) {
      usedIssues = formValues.chief_complaints;
    }
  }

  const initialValues: PlaceBackProps = {};
  initialValues.priority = usedPriority ?? PriorityType.Admin;

  if (usedIssues) {
    initialValues.issues = usedIssues;
  }

  if (usedMood) {
    initialValues.mood = usedMood;
  }
  if (typeof usedNote === 'string') {
    initialValues.notes = usedNote;
  }
  let started = picked_at ?? picked_timestamp;
  if (started && hold_time_sec && (isVoice || isVideo)) {
    started += hold_time_sec * 1000;
  }

  console.log('activeCallProps', {
    started,
    phone: from,
    isMuted: false,
    hospitalName: h_name,
    conversationType: type,
    clientStatus: 'Pet owner', // TODO: maybe handle role
    name: c_id ? c_name : null,
    conversationStatus: status,
    isEndedByClient: disconnected,
    initialPlaceBackValues: initialValues,
    finished: $finished || putOnHoldAt || disconnected_at,
    route: `${Routes.ActionCenter.Comm}/${CallTab.Consultation}${Routes.ActionCenter.InComm.AddClient}`,
  });

  return {
    started,
    phone: from,
    isMuted: false,
    hospitalName: h_name,
    conversationType: type,
    clientStatus: 'Pet owner', // TODO: maybe handle role
    name: c_id ? c_name : null,
    conversationStatus: status,
    isEndedByClient: disconnected,
    initialPlaceBackValues: initialValues,
    finished: $finished || putOnHoldAt || disconnected_at,
    route: `${Routes.ActionCenter.Comm}/${CallTab.Consultation}${Routes.ActionCenter.InComm.AddClient}`,
  };
};

export const processCallOutcome = (
  form: Pick<Case, 'callOutcome' | 'parentTaskId'>
): FormCallOutcome => {
  const { callOutcome, parentTaskId } = form;

  const initialCO = initialCallOutcome();

  if (!callOutcome || !callOutcome.type || !callOutcome.details) {
    return initialCO;
  }

  const { type, details, doctorId, hospitalId, hospitalRefId } = callOutcome;

  const typeName = details[type];
  const subTypeName = Object.values(details).find((value) =>
    CallOutcomeDetailsTypes.includes(value as CallOutcomeDetailsType)
  );

  const { datetime, ...restDetails } = details;

  if (datetime) {
    const diffs = dayjs.duration(dayjs(datetime).diff(dayjs()));

    restDetails.hours = diffs.hours();
    restDetails.minutes = diffs.minutes();
  }

  return {
    ...initialCO,
    type,
    [type]: {
      ...initialCO[type],
      ...(typeName && {
        type: subTypeName,
        [typeName]: {
          ...restDetails,
          ...(doctorId && { doctorId: String(doctorId) }),
          ...(hospitalId && { hospitalId: String(hospitalId) }),
          ...(parentTaskId && { parentTaskId: String(parentTaskId) }),
          ...(hospitalRefId && { hospitalRefId: String(hospitalRefId) }),
          ...('accepted' in details && { accepted: String(details.accepted) }),
        },
      }),
    },
  };
};

export const processCase = (form: any): any => {
  if (!form) {
    return form;
  }

  const caseForm = { ...form };

  const parse = (key: string) => {
    caseForm[key] =
      caseForm[key] && caseForm[key].length > 0
        ? JSON.parse(caseForm[key])
        : [];
  };

  parse('call_outcome');
  parse('medications_list');
  parse('follow_up_steps');
  parse('specify_administrative_issue');

  caseForm.medications_list = caseForm.medications_list?.map(
    (item: any) => item.medications_brand_name
  );

  const follow_up_cleanedArray =
    caseForm.follow_up_steps &&
    caseForm.follow_up_steps.length > 0 &&
    caseForm.follow_up_steps.filter((item: string) => {
      return !(
        item === 'No follow up needed' ||
        item === 'Clinic to call client' ||
        item === 'Client to call clinic'
      );
    });
  if (follow_up_cleanedArray && follow_up_cleanedArray.length > 0) {
    caseForm.follow_up_steps = caseForm.follow_up_steps.filter(
      (el: string) => !follow_up_cleanedArray.includes(el)
    );
  }

  caseForm.follow_up_other =
    follow_up_cleanedArray && follow_up_cleanedArray.length > 0
      ? follow_up_cleanedArray[0]
      : '';

  if (caseForm.care_hospital_id) {
    caseForm.care_hospital_id = String(caseForm.care_hospital_id);
  } else if (caseForm.custom_care_hospital) {
    caseForm.care_hospital_id = caseForm.custom_care_hospital;
  }

  if (caseForm.care_doctor_id) {
    caseForm.care_doctor_id = String(caseForm.care_doctor_id);
  } else if (caseForm.custom_care_doctor) {
    caseForm.care_doctor_id = caseForm.custom_care_doctor;
  }

  if (!caseForm.priority_of_concern) {
    caseForm.priority_of_concern = '';
  }

  caseForm.callOutcome = processCallOutcome(caseForm);

  for (const row of assessmentsRadioGridInputProps.rows) {
    const { fieldName } = row;
    const value = caseForm[fieldName];
    if (!value) {
      caseForm[fieldName] = '';
    }
  }

  if (!caseForm.pets) {
    caseForm.pets = caseForm.pet_id ? [caseForm.pet_id] : [];
  }

  if (caseForm.is_emergency_fee_confirm === null) {
    caseForm.is_emergency_fee_confirm = false;
  }

  if (caseForm.is_species_seen_confirm === null) {
    caseForm.is_species_seen_confirm = false;
  }

  return caseForm;
};

export const getCasePreview = (form: any) => {
  if (!form) {
    return form;
  }

  const caseForm = { ...form };

  const parse = (key: string) => {
    caseForm[key] =
      caseForm[key] && caseForm[key].length > 0
        ? JSON.parse(caseForm[key])
        : [];
  };

  parse('call_outcome');
  parse('medications_list');
  parse('follow_up_steps');
  parse('chief_complaints');

  return caseForm;
};

export const getCaseFormSelectedPets = (
  clientPets: Client['pets'] | undefined,
  caseFormPets: Case['pets'],
  caseFormPetId?: Case['pet_id']
): Pet[] | undefined => {
  if (caseFormPets) {
    return clientPets?.filter((item) => caseFormPets?.includes(item.id));
  } else if (caseFormPetId) {
    const pet = clientPets?.find((item) => item.id === caseFormPetId);
    if (pet) {
      return [pet];
    }
  }
};

type CaseSummaryProps = {
  full?: boolean;
  client?: Client;
  task?: ActionQueue;
  form: Record<string, any>;
};

export const getCaseSummary = ({
  task,
  form,
  full,
  client,
}: CaseSummaryProps) => {
  let summary: string[] = [];

  if (client) {
    summary.push(`Client’s name: ${client.name}`);
  } else if (task?.c_name) {
    summary.push(`Client’s name: ${task.c_name}`);
  }
  const phone = client?.phone ?? task?.from;
  if (phone) {
    summary.push(`Phone number: ${Utils.Helpers.formatPhone(phone)}`);
  }
  if (client?.pets.length && (form.pet_id || form.pets)) {
    const pets = getCaseFormSelectedPets(client.pets, form.pets, form.pet_id);
    if (pets) {
      pets.forEach((pet, index) => {
        const petInfo = [];

        if (pet.name.length) {
          petInfo.push(pet.name);
        }
        if (pet.species.length) {
          petInfo.push(pet.species);
        }
        if (pet.date_of_birth) {
          const formattedDate = Utils.Date.getFormattedAge(
            pet.date_of_birth,
            ''
          );
          if (formattedDate) {
            petInfo.push(`${formattedDate} old`);
          }
        }
        if (pet.gender) {
          petInfo.push(pet.gender);
        }
        if (pet.is_deceased) {
          petInfo.push('Deceased');
        }

        if (petInfo.length) {
          summary.push(
            `Pet info${pets.length > 1 ? ` ${index + 1}` : ''}: ${petInfo.join(
              ', '
            )}`
          );
        }
      });
    }
  }
  if (form.chief_complaints?.length) {
    const chiefComplaints = form.chief_complaints.join(', ');
    summary.push(
      full ? `Chief complaints: ${chiefComplaints}` : chiefComplaints
    );
  }

  if (full && form.problem_summary) {
    summary.push(`Subjective / Problem summary: ${form.problem_summary}`);
  }

  if (form.call_outcome?.length) {
    const callOutcome = form.call_outcome.join(', ');
    summary.push(full ? `Call outcome: ${callOutcome}` : callOutcome);
  }

  return summary.join('\n');
};

export const selectOptionsWithNone = (
  options: SelectOption[],
  noneLabel = 'None'
) => [{ value: '', label: noneLabel }, ...options];

export function parseArray(value?: string | string[] | null) {
  if (value) {
    if (typeof value === 'string' && value.length > 0) {
      return JSON.parse(value);
    }
    if (Array.isArray(value)) {
      return value;
    }
  }

  return [];
}

export const complaintsOptionsFactory =
  (chiefComplaints?: ChiefComplaint[]): (() => SelectOption[]) =>
  (): SelectOption[] =>
    chiefComplaints
      ? Utils.Helpers.sortSelectOptions(
          chiefComplaints.map((item) => ({
            value: item.name,
            label: item.name,
          }))
        )
      : [];

export const hospitalsOptionsFactory =
  (hospitals?: HospitalListData): (() => SelectOption[]) =>
  (): SelectOption[] =>
    hospitals
      ? Utils.Helpers.sortSelectOptions(
          hospitals.hospitals.map((item) => ({
            label: item.name,
            value: String(item.id),
          }))
        )
      : [];

export const getQueueItemId = (item: ActionQueue): string =>
  item.channelName ?? item.channel_name ?? item.roomId;

export const getActionCenterCaseForm = (
  queueItem: ActionQueue,
  user: TriageUser
): Record<string, any> => {
  const { type, from, h_id, c_id, notes, h_name, c_name, c_email } = queueItem;

  return {
    ...initialCaseForm(),
    client_id: c_id,
    hospital_id: h_id,
    client_phone: from,
    client_name: c_name,
    referring_practice: h_name,
    GV_team_member: user?.name,
    problem_summary: notes ?? '',
    care_hospital_id: String(h_id),
    case_type: ActionQueueTypeNumber[type],
    email: c_email && c_email !== 'null' ? c_email : null,
  };
};

export const fetchActionCenterCaseForm = async (
  queueItem: ActionQueue,
  user: TriageUser
) => {
  let { type, case_id } = queueItem;
  if (case_id && Number(case_id) > 0) {
    if (typeof type === 'number') {
      type = ActionQueueTypeFromNumber[type];
    }
    try {
      const caseForm = await api.cases.fetchCase(case_id, 1, type);
      return caseForm;
    } catch (error) {
      console.error(error);
    }
  }
  return getActionCenterCaseForm(queueItem, user);
};

export const isNumber = (value?: any) => {
  if (!value) {
    return false;
  }
  if (typeof value === 'string' && !value.length) {
    return false;
  }
  return !isNaN(Number(value));
};

export const uploadMultimedia = async (file: File) =>
  Utils.Helpers.uploadMultimedia(file, api.upload.multimedia);

export const getProfilePicPayload = async <
  T extends { profile_pic?: string | UploadFile },
>(
  values: T,
  handleError: ReturnType<typeof useAlert>['handleError']
): Promise<T | undefined> =>
  Utils.Helpers.getProfilePicPayload(
    values,
    handleError,
    api.upload.multimedia
  );

export const handleWalkinDropoffPayload = <
  T extends WalkinDropoffValues & WalkinDropoffFormValues,
>({
  walkin_status,
  dropoff_status,
  walkin_new_client,
  dropoff_new_client,
  walkin_current_client,
  dropoff_current_client,
  ...rest
}: T) => {
  const walkinStatus =
    walkin_status === 'null'
      ? null
      : Utils.Helpers.stringToBoolean(walkin_status);
  const dropoffStatus =
    dropoff_status === 'null'
      ? null
      : Utils.Helpers.stringToBoolean(dropoff_status);

  return {
    ...(typeof walkinStatus === 'boolean' && {
      walkin_accepted: walkinStatus,
      ...(walkinStatus
        ? { walkin_new_client, walkin_current_client }
        : { walkin_new_client: false, walkin_current_client: false }),
    }),
    ...(typeof dropoffStatus === 'boolean' && {
      dropoff_accepted: dropoffStatus,
      ...(dropoffStatus
        ? { dropoff_new_client, dropoff_current_client }
        : { dropoff_new_client: false, dropoff_current_client: false }),
    }),
    ...rest,
  };
};

export const getSelectedCallOutcome = (
  values?: any
):
  | {
      mainType: CallOutcomeType;
      subType?: CallOutcomeDetailsType;
      details?: FormCallOutcomeDetails;
    }
  | undefined => {
  if (!values) {
    return;
  }
  const { type } = values;
  if (!type) {
    return;
  }
  const details = values[type];

  var response = {
    mainType: type,
    ...(details &&
      'type' in details &&
      details.type && {
        subType: details.type,
        details: details[
          details.type as unknown as keyof typeof details
        ] as unknown as FormCallOutcomeDetails,
      }),
  };
  if (
    type === CallOutcomeType.Emergency &&
    (details?.type === CallOutcomeEmergency.Inbound ||
      details?.type === CallOutcomeEmergency.ClientDeclined)
  ) {
    response.details = response.details || {};
    response.details.datetime =
      details?.referToEr?.datetime ?? response.details.datetime;
  }
  return response;
};

export const getCallOutcomePayload = (
  values: FormCallOutcome
): Partial<CallOutcome> | null => {
  const selectedCallOutcome = getSelectedCallOutcome(values);

  if (!selectedCallOutcome) {
    return null;
  }

  const { subType, mainType, details = {} } = selectedCallOutcome;

  const {
    hours,
    reason,
    minutes,
    accepted,
    doctorId,
    hospitalId,
    parentTaskId,
    hospitalRefId,
    corporate_hospital_id,
    ...rest
  } = details;

  const acceptedValue =
    typeof accepted === 'string'
      ? Utils.Helpers.stringToBoolean(accepted)
      : null;

  let detailsPayload: CallOutcomeDetails = {
    [mainType]: subType,
  };

  if (typeof acceptedValue === 'boolean') {
    detailsPayload.accepted = acceptedValue;

    if (acceptedValue) {
      detailsPayload = { ...detailsPayload, ...rest };
    }
  } else {
    detailsPayload = { ...detailsPayload, ...rest };
  }

  if (reason) {
    detailsPayload.reason = reason;
  }

  if (!(minutes === '' && hours === '') && (minutes || hours)) {
    const mins = Number(minutes);
    const hrs = Number(hours);
    detailsPayload.datetime = dayjs()
      .add(isNaN(mins) ? 0 : mins, 'minute')
      .add(isNaN(hrs) ? 0 : hrs, 'hour')
      .toISOString();
  }

  const payload: Partial<CallOutcome> = {
    type: mainType,
    doctorId: null,
    hospitalId: null,
    hospitalRefId: null,
    details: detailsPayload,
    corporate_hospital_id: null,
  };

  if (doctorId && acceptedValue) {
    payload.doctorId = Number(doctorId);
  }
  if (acceptedValue === null || acceptedValue) {
    if (hospitalId) {
      payload.hospitalId = Number(hospitalId);
    }

    if (hospitalRefId) {
      payload.hospitalRefId = Number(hospitalRefId);
    }

    if (corporate_hospital_id) {
      payload.corporate_hospital_id = Number(corporate_hospital_id);
    }
  }

  if (parentTaskId) {
    payload.parentTaskId = Number(parentTaskId);
  }

  return payload;
};

export const getCallPhone = (call?: Call) => {
  return call?.customParameters.get('To');
};

export const hospitalPhonesFactory = (hospital?: Hospital) => () => {
  let items: string[] = [];
  if (!hospital) {
    return items;
  }
  if (hospital.phone) {
    items.push(hospital.phone);
  }
  if (hospital.backline_phones) {
    items = items.concat(
      hospital.backline_phones.flat().map((phone) => String(phone))
    );
  }
  return items;
};

export const numpadPhoneFactory =
  (callPhone: Nullable<string>, input: FieldInputProps<string>) =>
  (): { phone: string; isValidPhone: boolean; formattedPhone: string } => {
    let phone = callPhone ? callPhone : input.value;
    if (phone.length === 13 && phone.includes('+11')) {
      phone = phone.replace('+11', '+1');
    }
    let formattedPhone = formatPhone(phone, '');
    formattedPhone = formattedPhone.replace('+1', '');
    const isValidPhone = isValidPhoneNumber(phone);
    return { phone, isValidPhone, formattedPhone };
  };

export const isMineTask = (entity: ActionQueue | undefined, id?: number) => {
  let mine = false;
  if (entity?.assignTo) {
    const teamMemberComponents = entity?.assignTo?.split('_');
    const assignId = Number(teamMemberComponents?.pop());
    if (assignId && !isNaN(assignId)) {
      mine = id === assignId;
    }
  }
  return mine;
};

export const isMineInitializingTask = (
  entity: ActionQueue | undefined,
  id?: number
) => {
  let mine = false;
  if (entity?.initializing && entity.initializingBy) {
    const teamMemberComponents = entity.initializingBy.split('_');
    const initId = Number(teamMemberComponents?.pop());
    if (initId && !isNaN(initId)) {
      mine = id === initId;
    }
  }
  return mine;
};

export const getEmergencyHintConfig = (
  {
    sharedOnCall,
    afterHoursOnCall,
  }: {
    sharedOnCall: boolean;
    afterHoursOnCall: boolean;
  },
  hospitalId?: string
): DialogProps | undefined => {
  if (
    hospitalId &&
    Config.helpers.featureAvailableForHospital(hospitalId, 'TRIAGE_ASSISTANCE')
  ) {
    return {
      type: DialogType.Plain,
      text: (
        <FlexLayout flexDirection="column">
          This customer specifically does not want triage assistance.
          <ul>
            <Styles.ConditionListItem className="semibold">
              If an appointment
            </Styles.ConditionListItem>
            <li>
              Book an appointment per the{' '}
              <Styles.HighlightedText>Scheduling folder</Styles.HighlightedText>
            </li>
            <Styles.ConditionListItem className="semibold">
              If an emergency
            </Styles.ConditionListItem>
            <li>
              Refer to ER per the{' '}
              <Styles.HighlightedText>Emergency Folder</Styles.HighlightedText>
            </li>
            <Styles.ConditionListItem className="semibold">
              If Urgent Care
            </Styles.ConditionListItem>
            <li>
              Contact the{' '}
              <Styles.HighlightedText>backline</Styles.HighlightedText>
            </li>
          </ul>
        </FlexLayout>
      ),
    };
  } else if (afterHoursOnCall) {
    return {
      icon: <Icon.Info />,
      type: DialogType.Complex,
      title: 'This hospital is on-call',
      text: (
        <>
          Please double check the{' '}
          <span className="semibold">on-call calendar</span> and confirm the
          protocols were followed in the{' '}
          <span className="semibold">{CallFlowFolderName.Urgent} folder</span>
        </>
      ),
    };
  } else if (sharedOnCall) {
    return {
      icon: <Icon.Info />,
      type: DialogType.Complex,
      title: 'This hospital offers shared on-call',
      text: (
        <>
          Please review the{' '}
          <span className="semibold">{CallFlowFolderName.Urgent} folder</span>{' '}
          for the correct contact information if the issue is an emergency
        </>
      ),
    };
  }
};

const getPhone = (payload: FormikValues): string =>
  payload.phones.find(Boolean) ?? payload.home_phones.find(Boolean);

export const getClientPhonesInitialValues = (
  client?: Client
): Pick<Client, 'phones' | 'home_phones'> => {
  const { phone, phones = [], home_phones = [] } = client ?? {};

  let mobilePhones = [...phones];
  let homePhones = [...home_phones];

  if (!mobilePhones.length && !homePhones.length && phone) {
    mobilePhones.push(phone);
  }

  if (mobilePhones.length) {
    mobilePhones = mobilePhones.map((value) => transformPhone(value)!);
  }
  if (homePhones.length) {
    homePhones = home_phones.map((value) => transformPhone(value)!);
  }

  return { phones: mobilePhones, home_phones: homePhones };
};

export const removeEmptyStringsInArray = (value: any[]) =>
  value?.filter(Boolean) ?? [];

export const removeEmptyStringsInObjectArrays = <
  T extends Record<string, any>,
  U extends Record<string, any> = T,
>(
  value: T
): U =>
  Object.keys(value).reduce(
    (result, key) => {
      if (Array.isArray(value[key])) {
        result[key] = removeEmptyStringsInArray(value[key]);
      } else if (typeof value[key] === 'object') {
        result[key] = removeEmptyStringsInObjectArrays(value[key]);
      } else {
        result[key] = value[key];
      }
      return result;
    },
    {} as Record<string, any>
  ) as U;

export const getClientPhonesPayload = (
  payload: FormikValues
): Pick<Client, 'phone' | 'phones' | 'home_phones'> =>
  removeEmptyStringsInObjectArrays<
    FormikValues,
    Pick<Client, 'phone' | 'phones' | 'home_phones'>
  >({
    phones: payload.phones,
    phone: getPhone(payload),
    home_phones: payload.home_phones,
  });

export const getPriceRange = (subService?: ERSubService) => {
  const { maxPrice = 0, minPrice = 0 } = subService || {};

  const priceRange = [formatPrice(minPrice)];
  if (maxPrice !== minPrice) {
    priceRange.push(formatPrice(maxPrice));
  }

  return priceRange.join(' - ');
};

export const getUniqueErrorsMessage = (errors: any[]): string =>
  Array.from(
    new Set(errors.map((value) => Utils.Error.parseAxiosError(value.error)))
  ).join('\n');

export const areAllItemsSelected = (
  selectedIds: ActionItemData['ids'],
  dataIds?: ActionItemData['ids']
): boolean =>
  (dataIds &&
    dataIds.length > 0 &&
    selectedIds.length > 0 &&
    dataIds.every((id, index) => id === selectedIds[index])) ??
  false;

export const joinRoutes = (
  routes: string[],
  params?: Record<string, string | number>
) => {
  const route = replaceAll(joinStrings(routes, '/'), '//', '/');
  if (!params) {
    return route;
  }
  return generatePath(route, params);
};

export const isAlwaysOpen = (days: SimpleDays) => {
  const parsed = WorkingHoursUtils.getData(days).items;
  if (parsed.length !== 7) {
    return false;
  }
  const allTimeOpenDays = parsed.filter(
    (value) =>
      value.workingHours?.length === 1 &&
      value.workingHours[0].to?.minutes === 1439 &&
      value.workingHours[0].from?.minutes === 0
  );

  return parsed.length === allTimeOpenDays.length;
};

export const formatDoctorName = (name?: string) =>
  String(textValue(name, 'Dr. _value_'));

export const getCaseFormStepWithError = (
  errors: FormikErrors<FormikValues>
): ConsultationStep | undefined => {
  const assessmentErrors = Utils.Object.pick(errors, assessmentsFieldsNames);
  const callOutcomeErrors = errors?.callOutcome as FormCallOutcome | undefined;
  let errorStep: ConsultationStep | undefined;
  const outcomeSubTypeError =
    callOutcomeErrors &&
    !callOutcomeErrors.type &&
    Object.keys(callOutcomeErrors)[0];

  if (errors.client_id || errors.pets) {
    errorStep = ConsultationStep.PetOwner;
  } else if (errors.chief_complaints || errors.problem_summary) {
    errorStep = ConsultationStep.ProblemSummary;
  } else if (callOutcomeErrors?.type) {
    errorStep = ConsultationStep.CallOutcome;
  } else if (Object.values(assessmentErrors).length > 0) {
    errorStep = ConsultationStep.ProblemSummary;
  } else if (outcomeSubTypeError) {
    errorStep = ConsultationStep.CallOutcome;
  }

  return errorStep;
};

export const getMentions = (message = '', allMembersIds?: number[]) => {
  const ids: any[] = [];
  const delta = convertHtmlToDelta(message);
  delta.ops?.forEach((op) => {
    if (op.insert.mention) {
      const { id } = op.insert.mention;
      if (id) {
        ids.push(id);
      }
    }
  });

  if (!ids.length || !allMembersIds?.length) {
    return undefined;
  }
  if (ids.includes('mention-all-members')) {
    return allMembersIds;
  }

  return Array.from(new Set(ids))
    .filter((id) => !!id)
    .map((id) => +id);
};

export const getFileType = (file: File): FileType => {
  const type = file.type;
  if (type && type.startsWith(FileType.Image)) {
    return FileType.Image;
  }
  if (type && type.startsWith(FileType.Video)) {
    return FileType.Video;
  }
  return FileType.File;
};

export const getUnreadSum = (value: UnreadCountData) => {
  return Object.values(value).reduce((sum, curr) => sum + curr, 0);
};

export const percentage = (partial: number, total: number) =>
  (partial / total) * 100;

export const militaryToAmPm = (time: string) => {
  const hoursNumber = Number(time);
  const amPm = hoursNumber >= 12 ? 'PM' : 'AM';
  const hours12 = hoursNumber % 12 || 12;
  return `${hours12} ${amPm}`;
};

export const getCurrentUserStatus = (status?: NurseStatus) => {
  switch (status) {
    case NurseStatus.Break:
      return UserStatus.Break;
    case NurseStatus.Coaching:
      return UserStatus.Coaching;
    case NurseStatus.Offline:
      return UserStatus.Offline;
    default:
      return UserStatus.Online;
  }
};

export const getTaskDate = (
  date: Dayjs,
  {
    separator = ' - ',
    showTimezone = false,
  }: { separator?: string; showTimezone?: boolean } = {}
) => {
  const isToday = date.isToday();
  const isTomorrow = date.isTomorrow();

  return joinStrings(
    [
      isToday ? 'Today' : isTomorrow ? 'Tomorrow' : date.format('LL'),
      date.format(
        Utils.Helpers.joinStrings(['LT', showTimezone ? '(z)' : ''], ' ')
      ),
    ],
    separator
  );
};

export const generateUrl = (
  path: string,
  params: Record<string, any>
): string => {
  let url = generatePath(path, params);
  if (url.startsWith('http:/') && !url.startsWith('http://')) {
    url = url.replace('http:/', 'http://');
  }
  if (url.startsWith('https:/') && !url.startsWith('https://')) {
    url = url.replace('https:/', 'https://');
  }
  return url;
};

export const jsonSortedKeysReplacer = (_key: String, value: any) =>
  value instanceof Object && !(value instanceof Array)
    ? Object.keys(value)
        .sort()
        .reduce((sorted: Record<string, any>, key) => {
          sorted[key] = value[key];
          return sorted;
        }, {})
    : value;

export const isAcceptedAppointment = (
  value:
    | CallOutcomeAppointment.Scheduled
    | CallOutcomeAppointment.Rescheduled
    | CallOutcomeAppointment.Recommended,
  hospital?: Hospital
) =>
  hospital?.accepted_appointment_types
    ? hospital.accepted_appointment_types.includes(value)
    : true;

export const getHospitalAddress = (
  address?: string,
  city?: string,
  state?: State,
  zipCode?: string
) => {
  if (!address) {
    return undefined;
  }
  const addressParts = address.split(',');

  if (addressParts?.[1]?.trim()?.length) {
    return address;
  }

  return joinStrings([addressParts[0], city, state?.name, zipCode], ', ');
};

export const getIsERHospital = (hospitalSpeciality: Nullable<string>) => {
  return hospitalSpeciality
    ? ['Emergency', 'Emergency & Specialty'].includes(hospitalSpeciality)
    : false;
};

export const getIsSpecialityHospital = (
  hospitalSpeciality: Nullable<string>
) => {
  return hospitalSpeciality
    ? ['Specialist'].includes(hospitalSpeciality)
    : false;
};

export const getIsGPHospital = (hospitalSpeciality: Nullable<string>) => {
  return hospitalSpeciality
    ? ['General practice'].includes(hospitalSpeciality)
    : false;
};

export const getHospitalTasksTabsProps = ({
  hospital,
  taskCounts,
  hospitalSpeciality,
}: {
  hospital?: Hospital;
  taskCounts?: HospitalTasksCountData;
  hospitalSpeciality: Nullable<string>;
}) => {
  const isERHospital = getIsERHospital(hospitalSpeciality);
  const isMainHospital = hospital?.corporate_type === CorporateType.Main;
  const hasAccessToTransferBackline =
    Config.helpers.featureAvailableForHospital(
      hospital?.id,
      'CALL_OUTCOME_TRANSFER_TO_BACKLINE'
    );

  const result: any[] = [];
  const administrativeCount =
    Number(taskCounts?.administrative?.total) +
    (!isERHospital && taskCounts?.emergency?.total
      ? Number(taskCounts.emergency.total)
      : 0) +
    (hasAccessToTransferBackline && taskCounts?.transferToBackline?.total
      ? Number(taskCounts.transferToBackline.total)
      : 0);

  const virtualExamCount = taskCounts?.appointment?.[TaskType.VirtualExam] || 0;
  const appointmentCount =
    (taskCounts?.appointment?.total || 0) - virtualExamCount;

  if (isERHospital || isMainHospital) {
    result.push({
      label: 'On the way',
      to: TaskClass.Emergency,
      id: TaskClass.Emergency,
      badgeCount: taskCounts?.emergency?.total,
    });
  }

  if (!isERHospital || isMainHospital) {
    result.push(
      {
        label: 'Appointment',
        to: TaskClass.Appointment,
        id: TaskClass.Appointment,
        badgeCount: appointmentCount,
      },
      {
        label: 'Virtual exams',
        badgeCount: virtualExamCount,
        to: ExtendedTaskClass.VirtualExam,
        id: ExtendedTaskClass.VirtualExam,
      }
    );
  }

  result.push({
    label: 'Follow-ups',
    to: 'administrative',
    id: TaskClass.Administrative,
    badgeCount: administrativeCount,
  });

  const totalCount = result.reduce(
    (sum, { badgeCount }) => sum + badgeCount || 0,
    0
  );

  return { totalCount, tabs: result };
};

export const parseMessage = (data: any) => {
  const { author, ...rest } = data || {};
  return {
    ...rest,
    state: { author: author ?? rest?.state?.author },
  } as ChatMessage;
};

export const formatBoolean = (
  value?: Nullable<boolean>,
  skipUndefined: boolean = false,
  onlyTrue: boolean = false
) => {
  if (skipUndefined && typeof value !== 'boolean') {
    return undefined;
  }
  if (onlyTrue && !value) {
    return undefined;
  }
  return value ? 'Yes' : 'No';
};

export const composeName = (...values: string[]): string =>
  Utils.Helpers.joinStrings(values, '.');

export const getDynamicCaseData = ({
  values,
  caseForm,
  passedSteps,
  passedStepsFieldValues,
}: {
  values: FormikValues;
  caseForm: FormikValues;
  passedSteps: PassedStep[];
  passedStepsFieldValues: FormikValues;
}) => {
  try {
    const copy = mergeObjects(caseForm, passedStepsFieldValues);
    if (!copy.callOutcome) {
      copy.callOutcome = initialCallOutcome();
    }

    const { outcome, subOutcome } =
      passedSteps.find((step) => step.outcome && step.subOutcome) ?? {};

    if (outcome && subOutcome) {
      // copy.callOutcome.type = outcome;
      // copy.callOutcome[outcome].type = subOutcome;
      if (!copy.callOutcome.type) {
        copy.callOutcome.type = outcome;
      }
      if (!copy.callOutcome[outcome]?.type) {
        copy.callOutcome[outcome].type = subOutcome;
      }
      console.log('OutCome:', outcome, subOutcome);
    } else if (passedStepsFieldValues.callOutcome) {
      const [firstKey = CallOutcomeType.Administrative] = Object.keys(
        passedStepsFieldValues.callOutcome
      );
      copy.callOutcome.type = firstKey;
      const [subKey] = Object.keys(
        passedStepsFieldValues.callOutcome[firstKey]
      );
      copy.callOutcome[firstKey].type = subKey;
    }
    copy.pets = values.pets;
    copy.id = values.id;
    copy.submitRules = values.submitRules ?? [];
    copy.care_doctor_id = values.care_doctor_id;
    copy.care_hospital_id = values.care_hospital_id;
    copy.email_for_invoice =
      values.email_for_invoice || values.client?.emails[0];

    return copy;
  } catch (error) {
    return values;
  }
};

export const getStripeCentsFee = (withDeposit?: boolean) =>
  withDeposit ? 60 : 30;

/**
 * Calculates the total price of a payment considering various fees and conditions.
 *
 * @param {number} price - The base price of the service or item.
 * @param {Object} [options] - Optional parameters to adjust the calculation.
 * @param {number} [options.gvFee] - Additional percentage fee to be applied.
 * @param {boolean} [options.cents] - Indicates if the final total price should be in cents.
 * @param {boolean} [options.withDeposit] - Determines if a deposit should be included in the Stripe fee calculation.
 * @param {boolean} [options.priceInCents] - Indicates if the input price is already provided in cents.
 * @param {boolean} [options.isVirtualExam] - Specifies if the payment is for a virtual exam, which incurs an additional fee.
 * @param {boolean} [options.gvFeeIncludedInPrice] - Determines if the gvFee is already included in the input price and half of stripe fee is substracted from it.
 * @returns {Object} An object containing detailed fee values and the total price.
 */
export const getPaymentPrice = (
  price: number,
  options?: {
    gvFee?: number;
    cents?: boolean;
    withDeposit?: boolean;
    priceInCents?: boolean;
    isVirtualExam?: boolean;
    gvFeeIncludedInPrice?: boolean;
  }
) => {
  const {
    cents,
    withDeposit,
    priceInCents,
    isVirtualExam,
    gvFee: customFee,
    gvFeeIncludedInPrice,
  } = options || {};
  const virtualExamFeeCents = 25;
  const gvPercentFee = (customFee || gvFee) / 100;
  const stripePercentFee = 0.029;
  let priceValue = price;
  if (!priceInCents) {
    priceValue *= 100;
  }
  const stripeCentsFee = getStripeCentsFee(withDeposit);
  const totalPrice = Math.round(
    (priceValue + stripeCentsFee + (isVirtualExam ? virtualExamFeeCents : 0)) /
      (1 - (gvFeeIncludedInPrice ? 0 : gvPercentFee) - stripePercentFee)
  );
  let stripeFeeValue = Math.round(
    totalPrice * stripePercentFee + stripeCentsFee
  );
  let gvFeeValue = Math.round(
    (gvFeeIncludedInPrice ? priceValue : totalPrice) * gvPercentFee
  );
  if (gvFeeIncludedInPrice) {
    gvFeeValue -= Math.round(stripeFeeValue / 2);
  }
  let virtualExamFeeValue = virtualExamFeeCents;
  if (!cents) {
    virtualExamFeeValue = virtualExamFeeValue / 100;
    gvFeeValue /= 100;
    stripeFeeValue /= 100;
    priceValue /= 100;
  }
  const totalPriceValue = (
    priceValue +
    (gvFeeIncludedInPrice ? 0 : gvFeeValue) +
    stripeFeeValue +
    (isVirtualExam ? virtualExamFeeValue : 0)
  ).toFixed(2);

  return {
    gvFeeValue,
    stripeFeeValue,
    totalPriceValue,
    virtualExamFeeValue,
  };
};

export const getPaymentTextOut = (
  formValues: FormikValues,
  client?: Client,
  date?: string,
  hospital?: Hospital,
  asNode?: boolean
) => {
  const { pets, pet_id, currentInvoice } = formValues || {};
  const depositValue =
    currentInvoice?.depositFixed / 100 ||
    (currentInvoice?.totalPrice / 100) *
      (currentInvoice?.depositPercentage / 100);

  const clientPets = getCaseFormSelectedPets(client?.pets, pets, pet_id);

  const name = client?.name;
  const hospitalName = hospital?.name;
  const appointmentType = currentInvoice?.service?.name;
  const formatedDeposit = formatPrice(depositValue, '$0.00');
  const appointmentDate = formatDate(date, 'LL');
  const petName =
    clientPets && clientPets?.length > 0
      ? joinStrings(
          clientPets?.map((value) => value?.name),
          ', '
        )
      : undefined;
  const paymentTerm = currentInvoice?.paymentTerm
    ? dayjs
        .duration({
          milliseconds: +currentInvoice.paymentTerm,
        })
        .asHours()
    : undefined;

  let hello;
  let isWithPaymentTerm;
  let infoWithPaymentTerm;
  let infoWithoutPaymentTerm;
  let isWithoutPaymentTerm;

  if (asNode) {
    hello = (
      <>
        Hello <span className="semibold">{name}</span> 🐾
      </>
    );

    infoWithPaymentTerm = (
      <>
        We're delighted to confirm your appointment at{' '}
        <span className="semibold">{hospitalName}</span> for your beloved{' '}
        <span className="semibold">{petName}</span>{' '}
        <span className="semibold">{appointmentType}</span> on{' '}
        <span className="semibold">{appointmentDate}</span>. To secure your
        spot, please make a deposit of{' '}
        <span className="semibold">{formatedDeposit}</span> within the next{' '}
        <span className="semibold">{paymentTerm}</span> hours.
        <br />
        <span className="semibold">Important:</span> If the deposit is not paid
        within this timeframe, the appointment may be canceled.
      </>
    );

    infoWithoutPaymentTerm = (
      <>
        We're excited to let you know that your beloved{' '}
        <span className="semibold">{petName}</span> has an upcoming appointment
        at <span className="semibold">{hospitalName}</span> for a{' '}
        <span className="semibold">{appointmentType}</span> on{' '}
        <span className="semibold">{appointmentDate}</span>. To secure your
        spot, kindly pay a deposit of{' '}
        <span className="semibold">{formatedDeposit}</span> at your earliest
        convenience.
      </>
    );
  } else {
    hello = `Hello ${name} 🐾`;
    infoWithoutPaymentTerm = `We're excited to let you know that your beloved ${petName} has an upcoming appointment at ${hospitalName} for a ${appointmentType} on ${appointmentDate}. To secure your spot, kindly pay a deposit of ${formatedDeposit} at your earliest convenience.`;
    infoWithPaymentTerm = `We're delighted to confirm your appointment at ${hospitalName} for your beloved ${petName} ${appointmentType} on ${appointmentDate}. To secure your spot, please make a deposit of ${formatedDeposit} within the next ${paymentTerm} hours.\nImportant: If the deposit is not paid within this timeframe, the appointment may be canceled.`;
  }

  const endMessage = `${
    paymentTerm
      ? `Your pet's health is our priority, and we can't wait to provide the best care.`
      : `We're looking forward to seeing you and your furry friend!`
  } 🐶🏥`;

  return {
    hello,
    endMessage,
    info: paymentTerm ? infoWithPaymentTerm : infoWithoutPaymentTerm,
    paymentTermText: paymentTerm ? isWithPaymentTerm : isWithoutPaymentTerm,
  };
};

export const splitStringByLength = (str: string, length: number) => {
  const result: string[] = [];
  for (let i = 0; i < str.length; i += length) {
    result.push(str.slice(i, i + length));
  }
  return result;
};

export const getClientEmails = (client: Client): string[] => {
  const emails = new Set<string>([]);
  if (client.email) {
    emails.add(client.email);
  }
  if (client.emails) {
    client.emails.forEach(emails.add, emails);
  }
  return Array.from(emails);
};

export const clientAddress = (client?: Partial<Client>) => {
  if (client) {
    const { city, state, address, zip_code, addresses } = client;
    return joinStrings(
      [address, addresses?.join(', '), city, state, zip_code],
      ', ',
      null
    );
  }

  return null;
};

export const getPaymentTermsText = (value?: number) =>
  value ? getPlural('hour', +value, { rightCount: true }) : 'No payment terms';

export const paymentTermsItems = [0, 1, 3, 6].map((value) => ({
  value: value?.toString(),
  label: getPaymentTermsText(+value),
}));

export const removeFields = <T extends Record<string, any>>(
  object: T,
  fieldsToRemove: string[]
) => {
  let newObj = { ...object };
  fieldsToRemove.forEach((field) => delete newObj[field]);
  return newObj;
};

export const convertDollarsToCents = <T extends Record<string, any>>(
  values: T,
  fieldsToConvert: string[],
  isCentsToDollars?: boolean
) =>
  Object.entries(values).reduce((result, [key, value]) => {
    const keyStr = key as keyof typeof result;
    const changedResult = { ...result } as Record<string, any>;
    if (value && fieldsToConvert.includes(keyStr as string)) {
      const count = parseFloat(value as string);
      changedResult[key] = isCentsToDollars
        ? count / 100
        : Math.round(count * 100);
    } else {
      changedResult[key] = value;
    }
    return changedResult as T;
  }, {} as T);

export const isShallowEqual = (objA: any, objB: any) => {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  const len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    const key = aKeys[i];

    if (objA[key] !== objB[key] || !Object.hasOwn(objB, key)) {
      return false;
    }
  }

  return true;
};

export const petToString = (pet: Pet) =>
  joinStrings(
    [
      pet.name,
      pet.species,
      pet.breed,
      Utils.Date.getFormattedAge(pet.date_of_birth),
      pet.gender,
    ],
    ', '
  );

type AnyObject = Record<string, any>;

export const transformNestedObjectToFlat = (
  nestedObject: AnyObject
): AnyObject => {
  const flattenObject = (
    obj: AnyObject,
    parentKey = '',
    result: AnyObject = {}
  ) => {
    for (const [key, value] of Object.entries(obj)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value) &&
        Object.keys(value).length
      ) {
        flattenObject(value, newKey, result);
      } else {
        result[newKey] = value;
      }
    }
    return result;
  };

  return flattenObject(nestedObject);
};

export const transformFlatObjectToNested = (
  flatObject: AnyObject
): AnyObject => {
  const nestedObject: AnyObject = {};
  Object.keys(flatObject).forEach((key) => {
    const parts = key.split('.');
    let currentPart = nestedObject;
    for (let i = 0; i < parts.length; i++) {
      if (i === parts.length - 1) {
        currentPart[parts[i]] = flatObject[key];
      } else {
        currentPart[parts[i]] = currentPart[parts[i]] || {};
        currentPart = currentPart[parts[i]];
      }
    }
  });
  return nestedObject;
};

export const onlyDigits = (value: string) => value.replace(/\D+/g, '');

export const scrollToError = () => {
  setTimeout(() => {
    const errorNodes = document.getElementsByClassName('error-message');
    if (errorNodes.length) {
      scrollIntoView(errorNodes[0], {
        block: 'center',
        behavior: 'smooth',
      });
    }
  }, 100);
};

export const scrollCaseToError = (errors: FormikErrors<FormikValues>) => {
  console.log(`case form errors: ${JSON.stringify(errors)}`);
  scrollToError();
};

export const getClientControlCardProps = (
  onSignOut: (value: boolean) => void,
  path?: string,
  authClient?: AuthClient
) => {
  const formatedPhone = formatPhone(authClient?.phone);
  return {
    onSignOut: () => onSignOut(true),
    profileLink: path + Routes.Profile.Home,
    user: {
      role: formatedPhone,
      email: formatedPhone,
      name: authClient?.name,
    },
    userInfoProps: {
      placeholderIcon: <Styles.Pawprint />,
      placeholder: AvatarPlaceholderType.Icon,
    },
  };
};

export const formatCentsPrice = (price: number) =>
  formatPrice(price / 100, '$0,0[.]00');

export const formattedSubscriptionPrice = (
  product: SubscriptionPackage,
  options:
    | {
        customerPrice?: boolean;
      }
    | undefined = undefined
) =>
  `${formatPrice((options?.customerPrice ? product.customerPrice : product.price) / 100, '$0,0[.]00')}/${product.recurringInterval}`;

export const updateClientEmailIfNeeded = async (
  updateClient: ReturnType<typeof useUpdateMutation>[0],
  client: Client,
  email: string
) => {
  if (client.email === email) {
    return;
  }

  const emails = new Set([...(client?.emails || []), email]);

  await updateClient({
    email,
    client_id: client.id,
    emails: Array.from(emails),
  }).unwrap();
};

export const subscriptionWillExpireAfter = (currentPeriodEnd?: string) => {
  if (!currentPeriodEnd) {
    return null;
  }
  const date = dayjs(currentPeriodEnd);
  const updateAfter = date.diff(dayjs());
  if (updateAfter < 0) {
    return null;
  }
  return updateAfter + 5000; // give 5 sec to get update from stripe
};

/**
 * Converts the audio type of a participant into a standardized format for a video call.
 *
 * If an unrecognized audio type is provided, the function throws an error.
 */
export const convertAudio = (
  audio: Participant['audio']
): VideoCallParticipantAudio => {
  switch (audio) {
    case '':
      return VideoCallParticipantAudio.Empty;
    case 'phone':
      return VideoCallParticipantAudio.Phone;
    case 'computer':
      return VideoCallParticipantAudio.Computer;
    default:
      throw new Error('Invalid audio type');
  }
};
