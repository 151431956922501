import { URL } from '../constants';
import { apiLoaderInstance } from '../instance';

export const getBitwerxInstanceList = () =>
  apiLoaderInstance.get(URL.BITWERX_PRACTICES);

export const assignBitwerxPractice = (
  hospitalInfo: any,
  pimsName: any,
  hospitalId: any
) => {
  return apiLoaderInstance.post(URL.ASSIGN_BITWERX, {
    pims: pimsName,
    hospital_id: hospitalId,
    practice_id: hospitalInfo,
  });
};

export const getBitwerxPracticeStatus = (hospitalId: any) =>
  apiLoaderInstance.get(URL.CHECK_BITWERX_PRACTICES + '/' + hospitalId);

export const unAssignBitwerxPractice = (hospitalId: any) => {
  return apiLoaderInstance.post(URL.UNASSIGN_BITWERX, {
    hospital_id: hospitalId,
  });
};
