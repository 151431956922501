import { useState } from 'react';
import { Realtime, RealtimeChannel } from 'ably';
import { LogLevel, ChatClient } from '@ably/chat';

import { api } from 'api';
import { Config } from 'config';
import { getToken } from 'utils/storage';

import { UseAblyProps } from './types';

export const useAbly = ({ userName }: UseAblyProps) => {
  const [ablyClient, setAblyClient] = useState<Realtime | undefined>(undefined);
  const [chatClient, setChatClient] = useState<any>(null);
  const [ablyChannel, setAblyChannel] = useState<RealtimeChannel | undefined>(
    undefined
  );
  const [isAblyConnected, setAblyConnected] = useState<boolean>(false);
  const [ablyError, setAblyError] = useState<any>(undefined);

  const destroyAbly = () => {
    setAblyError(undefined);
    if (ablyChannel) {
      ablyChannel.off();
      setAblyChannel(undefined);
    }
    if (ablyClient) {
      ablyClient.close();
      setAblyClient(undefined);
    }
    setAblyConnected(false);
  };

  const connectAbly = async () => {
    if (ablyClient) {
      console.log('ably client already exist');
      return;
    }
    destroyAbly();

    if (userName && ablyClient === undefined) {
      try {
        const name = userName.trim();
        const ablyToken = await api.comm.getAblyToken(name);

        const client = new Realtime({
          logLevel: 2,
          clientId: name,
          useTokenAuth: true,
          token: ablyToken.data,
          authCallback: async (_params, callback) => {
            if (userName) {
              try {
                const newToken = await api.comm.getAblyToken(name);
                callback('', newToken.data);
              } catch (error) {
                callback((error as any).message, '');
              }
            }
          },
        });
        setAblyClient(client);
        const newClient = new Realtime({
          logLevel: 1,
          clientId: name,
          key: process.env.REACT_APP_PUBLIC_ABLY_API_KEY ?? '',
        });

        const realtimeChatClient = new ChatClient(newClient, {
          logLevel: 'error' as LogLevel,
        });
        setChatClient(realtimeChatClient);

        const channel = client.channels.get(Config.ablyChannel);
        setAblyChannel(channel);

        if (Config.portalType.isTeam) {
          client.connection.on((state) => {
            setAblyConnected(state.current === 'connected');
            switch (state.current) {
              case 'connected':
                channel.presence.enter({
                  token: getToken(),
                });
                break;
              case 'closed':
                channel.presence.leave({ token: getToken() });
                break;
              default:
                break;
            }
          });
        }
      } catch (error) {
        setAblyError(error);
      }
    }
  };

  return {
    ablyError,
    ablyClient,
    chatClient,
    ablyChannel,
    connectAbly,
    destroyAbly,
    isAblyConnected,
  };
};
