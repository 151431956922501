import dayjs from 'dayjs';
import { Utils } from '@gv/triage-components';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';

import { Config, Routes } from 'config';
import { AppDispatch } from 'store/store';
import { logout } from 'store/slices/auth';
import { setLoading } from 'store/slices/loader';
import { getToken, saveTokens } from 'utils/storage';

import { URL } from './constants';

export const apiInstance = axios.create({ baseURL: Config.baseURL });
export const apiLoaderInstance = axios.create({ baseURL: Config.baseURL });

export const apiLongTimeoutInstance = axios.create({
  baseURL: Config.baseBackendURL,
});

let defaultHeaders: Record<string, any> = {
  'x-api-version': 'v3',
};

if (Config.sentry.release) {
  defaultHeaders['x-client-version'] = Config.sentry.release;
}

const instances = [apiInstance, apiLoaderInstance, apiLongTimeoutInstance];

instances.forEach((instance) => {
  instance.defaults.headers.common = defaultHeaders;
});

const configRequest = (axiosConfig: InternalAxiosRequestConfig) => {
  const isRefresh =
    axiosConfig.url ===
    (Config.portalType.isPetOwner ? URL.PO_AUTH_REFRESH : URL.REFRESH);
  const token = getToken(isRefresh ? 'refreshToken' : 'accessToken');
  const config = { ...axiosConfig };
  console.log('token', token);
  if (token) {
    config.headers = config.headers.concat({
      ...defaultHeaders,
      authorization: token,
    });
  } else {
    config.headers.delete('authorization');
  }

  return config;
};

let isRefreshing = false;
let subscribers: ((token: string) => void)[] = [];

function onRefreshed(token: string) {
  subscribers.map((cb) => cb(token));
  subscribers = [];
}

function subscribeTokenRefresh(cb: (token: string) => void) {
  subscribers.push(cb);
}

const handleResponseError = async (
  dispatch: AppDispatch,
  instance: AxiosInstance,
  error: AxiosError
) => {
  // potential server data is in error.response.data
  if (error && error.response) {
    const { config, response } = error;
    const { status } = response;

    switch (status) {
      case 401:
        const hasRefreshToken = getToken('refreshToken');
        const skipTokenRefresh =
          config?.url &&
          [
            URL.LOGIN,
            URL.LOGOUT,
            URL.REFRESH,
            URL.PO_AUTH_REFRESH,
            URL.FORGOT_PASSWORD,
            URL.RECOVER_PASSWORD,
          ].includes(config.url);

        if (skipTokenRefresh) {
          break;
        } else if (!hasRefreshToken) {
          dispatch(logout({ error }));
          return Promise.reject(error);
        } else {
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              const { data } = await instance.post(
                Config.portalType.isPetOwner ? URL.PO_AUTH_REFRESH : URL.REFRESH
              );
              const tokens = data.data;
              saveTokens(tokens);
              isRefreshing = false;
              onRefreshed(tokens.token);
            } catch (err) {
              isRefreshing = false;
              console.error(
                "can't refresh token:",
                Utils.Error.parseAxiosError(err)
              );
              dispatch(logout({ error }));
              return Promise.reject(error);
            }
            return instance.request(config!);
          } else {
            const originalRequest = config!;
            return new Promise((resolve) => {
              subscribeTokenRefresh(() => {
                resolve(instance.request(originalRequest));
              });
            });
          }
        }
        break;
      case 404:
        if (
          config?.method === 'get' &&
          !config?.url?.includes('talk/') &&
          (!config?.headers ||
            !(config.headers as Record<string, any>)['allow-not-found'])
        ) {
          console.error('not found from', config?.url);
          if (!localStorage.getItem('allow-404')) {
            window.location.href = Routes.NotFound;
          }
        }
        break;
    }
  }
  return Promise.reject(error);
};

const ignoredURLs = ['v3/team/online'];

const isAllowToLog = (config?: AxiosRequestConfig) => {
  if (!config?.url) {
    return false;
  }
  const ignore = ignoredURLs.find((url) => config.url?.includes(url));
  return !ignore;
};

const getConfigString = (config?: AxiosRequestConfig) => {
  return `${dayjs().format(
    'HH:mm:ss.SSS'
  )} | ${config?.method?.toUpperCase()}: ${config?.url}`;
};

const reportStart = (config: AxiosRequestConfig) => {
  if (isAllowToLog(config)) {
    console.log('started', getConfigString(config));
  }
};

const reportEnd = (response: AxiosResponse) => {
  if (isAllowToLog(response.config)) {
    console.log('finished', getConfigString(response.config));
  }
};

const reportFailed = (error: any) => {
  if (isAllowToLog(error.config)) {
    console.error('finished with error', getConfigString(error.config));
  }
};

apiInstance.interceptors.request.use((config) => {
  reportStart(config);
  return configRequest(config);
});

apiLongTimeoutInstance.interceptors.request.use((config) => {
  reportStart(config);
  return configRequest(config);
});

export const interceptor = (dispatch: AppDispatch) => {
  apiLoaderInstance.interceptors.request.use(
    (config) => {
      reportStart(config);
      dispatch(setLoading(true));
      return configRequest(config);
    },
    (error) => {
      dispatch(setLoading(false));
      return error;
    }
  );

  apiLoaderInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      dispatch(setLoading(false));
      reportEnd(response);
      return response;
    },
    (error: AxiosError) => {
      reportFailed(error);
      dispatch(setLoading(false));
      return handleResponseError(dispatch, apiLoaderInstance, error);
    }
  );

  apiInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      reportEnd(response);
      return response;
    },
    (error: AxiosError) => {
      reportFailed(error);
      return handleResponseError(dispatch, apiInstance, error);
    }
  );

  apiLongTimeoutInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      reportEnd(response);
      return response;
    },
    (error: AxiosError) => {
      reportFailed(error);
      return handleResponseError(dispatch, apiInstance, error);
    }
  );
};
