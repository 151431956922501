import {
  Utils,
  FlexLayout,
  ButtonColors,
  StandardButton,
  ActionQueueType,
} from '@gv/triage-components';

import * as Styles from './styles';
import { ConversationBadgePopupProps } from './types';

const { textValue, formatPhone } = Utils.Helpers;

const ConversationBadgePopup = ({
  name,
  type,
  close,
  phone,
  onClick,
}: ConversationBadgePopupProps) => {
  let text;
  if (type === ActionQueueType.Voice) {
    text = 'call';
  } else if (type === ActionQueueType.Video) {
    text = 'video call';
  } else {
    text = 'chat';
  }

  return (
    <Styles.Container>
      <FlexLayout flexDirection="column">
        <Styles.Name>{textValue(name)}</Styles.Name>
        <p>{formatPhone(phone)}</p>
      </FlexLayout>

      <StandardButton
        fullWidth
        text={`Back to ${text}`}
        colorType={ButtonColors.Secondary}
        onClick={() => {
          onClick();
          if (close) {
            close();
          }
        }}
      />
    </Styles.Container>
  );
};

export { ConversationBadgePopup };
