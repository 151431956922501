import { RealtimeChannel } from 'ably';
import { useState, useEffect, useContext } from 'react';

import { CommContext } from 'context/comm';

export const useAblyChatChannel = (name?: string) => {
  const { chatClient } = useContext(CommContext);
  const [ablyChannel, setAblyChannel] = useState<RealtimeChannel | undefined>(
    undefined
  );

  useEffect(() => {
    if (chatClient && name) {
      const channel = chatClient.realtime.channels.get(name);
      setAblyChannel(channel);
    } else {
      setAblyChannel(undefined);
    }
  }, [name, chatClient]);
  return ablyChannel;
};
