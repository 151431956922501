import { useMemo, useState, useEffect, useCallback } from 'react';
import {
  Utils,
  useAlert,
  Messages,
  ChatSection,
  ChatSectionProps,
} from '@gv/triage-components';

import { api } from 'api';
import { parseMessage } from 'utils/helpers';
import { useAppDispatch, useAppSelector } from 'store';
import { useAblyChannel, useAblyEventChannel } from 'hooks';
import { selectAuthForGVTalk, selectAuthDataForChat } from 'store/slices/auth';
import {
  loadOldMessages,
  sendChatMessage,
} from 'store/slices/chat-messages/thunks';
import {
  addMessage,
  setMessages,
  removeMessage,
  updateMessage,
  selectChatMessages,
} from 'store/slices/chat-messages';

import { ChatProps } from './types';

export const ChatSms = ({
  task,
  className,
  initialLoadByComponent,
  ...props
}: ChatProps): JSX.Element | null => {
  const [isMessagesLoading, setMessagesLoading] = useState(false);

  let { channel_name: channelName } = task ?? {};
  if (!channelName) {
    channelName = task?.channelName;
  }

  const { handleError, showErrorAlert } = useAlert();

  const dispatch = useAppDispatch();
  const gvTalkAuth = useAppSelector(selectAuthForGVTalk);
  const author = useAppSelector(selectAuthDataForChat);
  const { nextId, messages } = useAppSelector(selectChatMessages(channelName));

  const parsedMessages = useMemo(
    () => messages?.map((msg) => parseMessage(msg)) ?? [],
    [messages]
  );
  console.log('initialLoadByComponent', initialLoadByComponent, channelName);
  useEffect(() => {
    if (!initialLoadByComponent || !channelName) {
      return;
    }
    const load = async () => {
      try {
        setMessagesLoading(true);
        const chatMessagesResponse = await api.comm.getChatMessages({
          channelName,
        });
        dispatch(
          setMessages({
            channelName,
            data: chatMessagesResponse.data.data,
          })
        );
      } catch (error) {
        handleError(error);
      } finally {
        setMessagesLoading(false);
      }
    };
    load();
  }, [channelName, initialLoadByComponent]);

  const onMessage = useCallback(
    (message: any) => {
      if (channelName) {
        dispatch(
          addMessage({
            channelName,
            message: message.data,
          })
        );
      }
    },
    [channelName]
  );

  const channel = useAblyChannel(channelName, true);

  useAblyEventChannel('incoming-message', onMessage, channel);

  const sendMessage = async ({
    id,
    ...rest
  }: {
    id: string | number;
  } & Parameters<typeof sendChatMessage>[0]) => {
    if (!channelName) {
      showErrorAlert(Messages.NO_TASK);
      return;
    }

    try {
      await dispatch(sendChatMessage(rest)).unwrap();

      dispatch(
        removeMessage({
          id,
          channelName,
        })
      );
    } catch (error) {
      dispatch(
        updateMessage({
          id,
          channelName,
          isError: true,
          isSending: false,
        })
      );

      handleError(error);
    }
  };

  const handleSendMessage: ChatSectionProps['handleSendMessage'] = async (
    values,
    resetForm
  ) => {
    if (!channelName) {
      showErrorAlert(Messages.NO_TASK);
      return;
    }

    const sendingMsgId = Date.now();

    try {
      dispatch(
        addMessage({
          channelName,
          message: {
            thread_id: '',
            updated_at: '',
            created_at: '',
            media_url: null,
            isSending: true,
            id: sendingMsgId,
            media_type: null,
            html: values.message,
            msg_text: values.message,
            author_id: author.authorId,
            state: { author: author.name },
            formatting: {
              ops: Utils.Quill.convertHtmlToDelta(values.message).ops,
            },
          },
        })
      );

      await sendMessage({ id: sendingMsgId, ...values });
    } finally {
      resetForm();
    }
  };

  const onTopReached = async () => {
    if (channelName && nextId) {
      try {
        setMessagesLoading(true);
        await dispatch(loadOldMessages({ nextId, channelName })).unwrap();
      } catch (error) {
        handleError(error);
      } finally {
        setMessagesLoading(false);
      }
    }
  };

  const onRetry: ChatSectionProps['onRetry'] = async ({
    id,
    msg_text,
    ...rest
  }) => {
    if (!channelName) {
      showErrorAlert(Messages.NO_TASK);
      return;
    }

    dispatch(
      updateMessage({
        id,
        channelName,
        isError: false,
        isSending: true,
      })
    );

    await sendMessage({ id, message: msg_text, ...rest });
  };

  return (
    <ChatSection
      task={task}
      onRetry={onRetry}
      className={className}
      parseOptions={gvTalkAuth}
      messages={parsedMessages}
      isLoading={isMessagesLoading}
      handleSendMessage={handleSendMessage}
      onTopReached={nextId ? onTopReached : undefined}
      {...props}
    />
  );
};
