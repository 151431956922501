import { useMemo, useState } from 'react';
import {
  Utils,
  Event,
  Badge,
  isTablet,
  mapQuery,
  isDesktop,
  EventType,
  BadgeColor,
  BadgePadding,
  UserRoleType,
} from '@gv/triage-components';

import { Config } from 'config';
import { useAppSelector } from 'store';
import { MessageRecipientLabel } from 'types/data';
import { useListQuery } from 'store/api/case-logs';
import { selectHasRoles } from 'store/slices/auth';

import * as Styles from './styles';
import { CaseEvents } from './case-events';
import { SelectedEvent } from './selected-event';
import { LogProps, SelectedEventState } from './types';

/**
 * CommunicationLog component displays a log of communication events for a specific case in the case view.
 */
export const CommunicationLog = ({
  currentCase,
  asSidePanel,
  inlineView = false,
}: LogProps): JSX.Element => {
  const tablet = isTablet();
  const desktop = isDesktop();
  const isSuperAdmin = useAppSelector(
    selectHasRoles([UserRoleType.SuperAdmin])
  );
  const id = currentCase?.id;

  const { data = [], ...props } = useListQuery(id!, { skip: !id });

  const logs = useMemo(
    () =>
      data
        .filter(
          (caseLog) => caseLog.type !== null && caseLog.type !== undefined
        )
        .map((caseLog) => ({
          caseId: caseLog.caseId,
          callId: caseLog.callId,
          caseType: caseLog.type,
          createdAt: caseLog.created_at,
          events: caseLog.events
            .filter((event) =>
              (Object.values(EventType) as string[]).includes(event.type)
            )
            .map((event): Event => {
              const base = {
                id: event.id,
                createdAt: event.timestamp,
                type: event.type as EventType,
              };

              switch (event.type) {
                case EventType.TextOut: {
                  const details =
                    event.details && 'body' in event.details
                      ? event.details
                      : undefined;
                  return {
                    ...base,
                    role:
                      event.receiver?.role ||
                      (details?.textOutType &&
                        MessageRecipientLabel[details.textOutType]),
                    name: Utils.Helpers.textValue(
                      event.receiver?.name ??
                        event.client?.name ??
                        Utils.Helpers.formatPhone(details?.phone)
                    ) as string,
                  };
                }

                case EventType.Call:
                  return {
                    ...base,
                    name: Utils.Helpers.textValue(
                      caseLog.attendedBy
                        ?.map((item) =>
                          Utils.Helpers.joinStrings(
                            [item.name, item.role],
                            ' • '
                          )
                        )
                        .join(', ')
                    ) as string,
                  };

                case EventType.Transfer: {
                  const details =
                    event.details && 'phone' in event.details
                      ? event.details
                      : undefined;
                  return {
                    ...base,
                    role: event.receiver?.role,
                    name: Utils.Helpers.textValue(
                      event.receiver?.name ??
                        Utils.Helpers.formatPhone(details?.phone)
                    ) as string,
                  };
                }

                case EventType.Submit:
                  const isAutoSubmit =
                    isSuperAdmin &&
                    event.details &&
                    'wasAutoSubmitted' in event.details &&
                    event.details.wasAutoSubmitted;
                  return {
                    ...base,
                    role: event.initiator?.role,
                    name: Utils.Helpers.textValue(
                      event.initiator?.name
                    ) as string,
                    description: isAutoSubmit
                      ? 'Automatical submit case instead of'
                      : undefined,
                    content: isAutoSubmit ? (
                      <div>
                        <Badge
                          text="Autosubmit"
                          styleType={BadgeColor.Red}
                          padding={BadgePadding.Medium}
                        />
                      </div>
                    ) : undefined,
                  };

                default:
                  return {
                    ...base,
                    role: event.initiator?.role,
                    name: Utils.Helpers.textValue(
                      event.initiator?.name
                    ) as string,
                  };
              }
            }),
        })),
    [data]
  );

  const visibleLogs = useMemo(() => {
    if (Config.portalType.isTeam) {
      return logs;
    }
    return logs.map((log) => ({
      ...log,
      events: log.events.filter(
        (event) =>
          event.type === EventType.Chat || event.type === EventType.Call
      ),
    }));
  }, [logs]);

  const [selectedEvent, setSelectedEvent] =
    useState<SelectedEventState['selectedEvent']>(null);

  const caseEvents = (
    <CaseEvents
      logs={visibleLogs}
      currentCase={currentCase}
      setSelectedEvent={setSelectedEvent}
    />
  );

  const selectedEventNode = useMemo(() => {
    if (!selectedEvent) {
      return undefined;
    }
    var relatedEvents: Event[] = [];
    if (selectedEvent.type === EventType.Call) {
      for (const caseLog of logs) {
        const index = caseLog.events.findIndex(
          (event) => event.id === selectedEvent.id
        );
        if (index > 0) {
          relatedEvents = caseLog.events.slice(0, index);
          for (let i = index - 1; i > 0; i--) {
            if (relatedEvents[i].type === EventType.Call) {
              relatedEvents = relatedEvents.slice(i + 1, index);
              break;
            }
          }
          break;
        }
      }
    }
    return (
      <SelectedEvent
        event={selectedEvent}
        currentCase={currentCase}
        relatedEvents={relatedEvents}
        onSelectEvent={setSelectedEvent}
      />
    );
  }, [selectedEvent, logs]);

  return (
    <Styles.LogsDetails {...mapQuery(props)}>
      {tablet || inlineView ? (
        <Styles.TabletContainer>
          <Styles.EventsContainer>{caseEvents}</Styles.EventsContainer>

          <Styles.EventContainer>
            {selectedEventNode ?? (
              <Styles.Placeholder>
                Please select event from communication log to listen call record
                or check chat history
              </Styles.Placeholder>
            )}
          </Styles.EventContainer>
        </Styles.TabletContainer>
      ) : (
        <Styles.Container withLeftBorder={desktop && asSidePanel}>
          {selectedEventNode ?? caseEvents}
        </Styles.Container>
      )}
    </Styles.LogsDetails>
  );
};
