import { useMemo } from 'react';
import { useField } from 'formik';
import {
  Col,
  Row,
  Dialog,
  InputField,
  RadioFieldGroup,
} from '@gv/triage-components';

import { Config } from 'config';
import { isAcceptedAppointment } from 'utils/helpers';
import { Administrative } from 'components/cases/fields/actions';
import {
  useFormEntities,
  callOutcomeModel,
  CheckProtocolsInfo,
  CorporateOptionsProps,
} from 'components/cases/form';
import {
  CallOutcomeType,
  CallOutcomeEmergency,
  CallOutcomeAppointment,
  CallOutcomeTransferToBackline,
} from 'types';
import {
  CallOutcomeEmergencyTitle,
  CallOutcomeAppointmentTitle,
  CallOutcomeTransferToBacklineTitle,
} from 'types/data';

import { DepartmentSelect } from './department-select';

export const CallOutcomeAction = ({
  corporateOptions,
  isCorporateFetching,
}: CorporateOptionsProps) => {
  const { hospital, hospitalId } = useFormEntities();
  const [type] = useField(callOutcomeModel.type);
  const [modalField, , modalHelpers] = useField('isWarningModalShown');

  const {
    hideCallOutcomeTypes,
    blockedCallOutcomeTypes,
    hospitalCallOutcomeTitles,
  } = useMemo(
    () => ({
      hideCallOutcomeTypes: Config.helpers.getHideCallOutcomeTypes(hospitalId),
      hospitalCallOutcomeTitles:
        Config.helpers.getCallOutcomeTitles(hospitalId),
      blockedCallOutcomeTypes:
        Config.helpers.getBlockedCallOutcomeTypes(hospitalId),
    }),
    [hospitalId]
  );

  const hasAccessToItem = (value: string): boolean =>
    Boolean(hospitalId && !blockedCallOutcomeTypes?.includes(value));

  const onModalClose = () => {
    modalHelpers.setValue(true);
  };

  const isEmergency = type.value === CallOutcomeType.Emergency;
  const isAppointment = type.value === CallOutcomeType.Appointment;

  return (
    <>
      {isAppointment && (
        <div>
          <RadioFieldGroup
            label="Select option"
            name={callOutcomeModel.appointment.type}
            items={[
              {
                value: CallOutcomeAppointment.Scheduled,
                label:
                  CallOutcomeAppointmentTitle[CallOutcomeAppointment.Scheduled],
                hasAccess: isAcceptedAppointment(
                  CallOutcomeAppointment.Scheduled,
                  hospital
                ),
              },
              {
                value: CallOutcomeAppointment.Recommended,
                label:
                  CallOutcomeAppointmentTitle[
                    CallOutcomeAppointment.Recommended
                  ],
                hasAccess:
                  hasAccessToItem(CallOutcomeAppointment.Recommended) &&
                  isAcceptedAppointment(
                    CallOutcomeAppointment.Recommended,
                    hospital
                  ),
              },
              {
                value: CallOutcomeAppointment.Cancelled,
                label:
                  hospitalCallOutcomeTitles?.[
                    CallOutcomeAppointment.Cancelled
                  ] ??
                  CallOutcomeAppointmentTitle[CallOutcomeAppointment.Cancelled],
              },
              {
                value: CallOutcomeAppointment.Declined,
                label:
                  CallOutcomeAppointmentTitle[CallOutcomeAppointment.Declined],
              },
              {
                value: CallOutcomeAppointment.Rescheduled,
                label:
                  CallOutcomeAppointmentTitle[
                    CallOutcomeAppointment.Rescheduled
                  ],
                hasAccess:
                  hasAccessToItem(CallOutcomeAppointment.Rescheduled) &&
                  isAcceptedAppointment(
                    CallOutcomeAppointment.Rescheduled,
                    hospital
                  ),
              },
            ]}
          />
        </div>
      )}

      {isEmergency && (
        <div>
          <RadioFieldGroup
            label="Refer to"
            name={callOutcomeModel.emergency.type}
            items={[
              {
                value: CallOutcomeEmergency.ReferToOnCall,
                label:
                  CallOutcomeEmergencyTitle[CallOutcomeEmergency.ReferToOnCall],
                hasAccess:
                  hasAccessToItem(CallOutcomeEmergency.ReferToOnCall) &&
                  hospital &&
                  hospital.speciality !== 'Emergency' &&
                  !hideCallOutcomeTypes?.includes(
                    CallOutcomeEmergency.ReferToOnCall
                  ),
              },
              {
                value: CallOutcomeEmergency.ReferToEr,
                hasAccess: hospital && hospital.speciality !== 'Emergency',
                label:
                  CallOutcomeEmergencyTitle[CallOutcomeEmergency.ReferToEr],
              },
              {
                value: CallOutcomeEmergency.ReferToPPH,
                label:
                  hospitalCallOutcomeTitles?.[
                    CallOutcomeEmergency.ReferToPPH
                  ] ??
                  CallOutcomeEmergencyTitle[CallOutcomeEmergency.ReferToPPH],
                hasAccess:
                  hospital &&
                  hospital.speciality !== 'Emergency' &&
                  !hideCallOutcomeTypes?.includes(
                    CallOutcomeEmergency.ReferToOnCall
                  ),
              },
              {
                value: CallOutcomeEmergency.Inbound,
                hasAccess: hospital && hospital.speciality === 'Emergency',
                label:
                  hospitalCallOutcomeTitles?.[CallOutcomeEmergency.Inbound] ??
                  CallOutcomeEmergencyTitle[CallOutcomeEmergency.Inbound],
              },
              {
                value: CallOutcomeEmergency.ClientDeclined,
                hasAccess: hospital && hospital.speciality === 'Emergency',
                label:
                  hospitalCallOutcomeTitles?.[
                    CallOutcomeEmergency.ClientDeclined
                  ] ??
                  CallOutcomeEmergencyTitle[
                    CallOutcomeEmergency.ClientDeclined
                  ],
              },
            ]}
          />
        </div>
      )}

      {type.value === CallOutcomeType.Administrative && (
        <>
          <DepartmentSelect
            isDisabled={isCorporateFetching}
            options={corporateOptions?.call}
            name={callOutcomeModel.administrative.call.corporateHospitalId}
          />

          <CheckProtocolsInfo>
            <Administrative
              name={callOutcomeModel.administrative.call.outcomes}
            />
          </CheckProtocolsInfo>
        </>
      )}

      {type.value === CallOutcomeType.TransferToBackline && (
        <div>
          <Row>
            <Col>
              <RadioFieldGroup
                label="Select options"
                name={callOutcomeModel.transferToBackline.type}
                items={Object.values(CallOutcomeTransferToBackline).map(
                  (value) => ({
                    value,
                    label:
                      hospitalCallOutcomeTitles?.[value] ??
                      CallOutcomeTransferToBacklineTitle[value],
                  })
                )}
              />
            </Col>
          </Row>
          <InputField
            label="Notes"
            placeholder="Add note"
            name="additional_notes"
            type="textarea-autosize"
          />
        </div>
      )}

      <Dialog
        title="Please note:"
        onClose={onModalClose}
        onConfirm={onModalClose}
        closeOnDocumentClick={false}
        open={!modalField.value && (isEmergency || isAppointment)}
        text="Unless the pet owner has an administrative issue, we should recommend an appointment or refer the case to the ER. Please explain to the pet owner what the complications could be without a physical exam and why it is recommended."
      />
    </>
  );
};
