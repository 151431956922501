/* eslint-disable perfectionist/sort-objects */
const ivr = '/ivr';
const team = '/team';
const cases = '/cases';
const inbox = '/inbox';
const tasks = '/tasks';
const office = '/office';
const explore = '/explore';
const contacts = '/contacts';
const invoices = '/invoices';
const calendar = '/calendar';
const outbound = '/outbound';
const onTheWay = '/onTheWay';
const marketing = '/marketing';
const hospitals = '/hospitals';
const recordings = '/recordings';
const voicemails = '/voicemails';
const helpCenter = '/help-center';
const ringGroups = '/ring-groups';
const formBuilder = 'form-builder';
const actionItems = '/action-items';
const dynamicFlow = '/dynamic-flow';
const actionCenter = '/action-center';
const subscriptions = '/subscriptions';
const masterRecords = '/master-records';
const schedulingMatrix = 'scheduling-matrix';
const callNotifications = '/call-notifications';
const poSubscriptions = '/pet-owner-subscriptions';

const Auth = {
  Login: '/login',
  SignUp: '/sign-up',
  VerifyOtp: '/verify-otp/:token',
  ResetPassword: '/reset-password',
  ForgotPassword: '/forgot-password',
  SubmitFeedback: '/submit-feedback-form',
};

const Outbound = {
  Home: outbound,
  Tabs: {
    Comm: '/comm',
    History: '/history',
  },
};

const Clients = {
  Index: '/clients',
  New: 'new',
  Edit: ':clientId/edit',
  Details: {
    Index: ':clientId',
    Edit: 'pet/:petId',
    New: 'pet/new',
    Cases: 'cases',
  },
};

const DynamicFlows = {
  Index: dynamicFlow,
  Builder: formBuilder,
  Create: `${formBuilder}/new`,
  EditForm: `${formBuilder}/:dynamicFormId`,
};

const Cases = {
  Home: cases,
  List: '/',
  Details: {
    Home: `${cases}/:caseId`,
    Consultation: {
      Index: 'consultation',
      Edit: 'consultation/edit',
    },
    Hospital: 'hospital',
    Invoices: 'invoices',
    Log: 'log',
    Acceptance: 'acceptance',
    HTML: 'html',
    PDF: 'pdf',
    ChatHTML: 'chat-html',
    ChatPDF: 'chat-pdf',
  },
};

const ActionItems = {
  Home: actionItems,
  OnTheWay: onTheWay,
  Tasks: tasks,
  Voicemails: {
    Home: voicemails,
    Details: {
      Contact: 'contact',
      Voicemail: 'voicemail',
      AddClient: '/add-client',
      NewClient: '/new-client',
      Home: `${voicemails}/:voicemailId`,
    },
  },
};

const Hospitals = {
  Home: hospitals,
  New: 'new',
  Edit: ':id/edit',
  Details: {
    Clients,
    DynamicFlows,
    Home: `${hospitals}/:id`,
    HomeInfo: '/hospital',
    Acceptance: '/acceptance',
    Whiteboard: '/whiteboard',
    AppointmentTypes: {
      Index: '/appointment-types',
    },
    HospitalPricing: {
      Index: '/hospital-pricing',
      New: 'new',
      Edit: 'edit/:priceId',
    },
    PayoutDetails: {
      Index: '/payout-details',
      New: 'new',
      Edit: 'edit',
    },
    Payout: {
      Index: '/payout-settings',
      Edit: 'edit',
    },
    General: {
      Index: '/general',
      Edit: 'edit',
    },
    POService: {
      Index: '/pet-owner-service',
    },
    Pricing: {
      Index: '/pricing',
      Edit: 'edit/:groupId',
      New: 'new',
    },
    Templates: {
      Index: '/templates',
    },
    Team: {
      Index: '/team',
      New: 'new',
      Edit: ':teamMemberId/edit',
      Details: ':teamMemberId',
    },
    ClinicDvms: {
      Index: '/clinic-dvms',
      Edit: ':doctorId/edit',
      Details: ':doctorId',
    },
    Calendar: '/calendar',
    Analytics: '/analytics',
    CallFlows: {
      Index: '/call-flows',
      Details: ':flowId',
      New: 'new',
      Edit: ':flowId/edit',
    },
    Workflows: {
      Index: '/workflows',
      New: 'new',
      Details: ':workflowId',
      Edit: ':workflowId/edit',
    },
    PhoneTree: {
      Index: '/phone-tree',
      Edit: 'edit/:branchId',
      New: 'new',
    },
    CorporateGroup: {
      Index: '/corporate-group',
      New: ':type/new',
      Edit: ':type/edit/:groupId',
    },
    ERProtocol: {
      Index: '/er-protocol',
      New: 'new',
      Details: ':ref',
      Edit: ':ref/edit',
      ServiceNew: 'service/new',
      ServiceDetails: 'service/:serviceId',
      ServiceEdit: 'service/edit/:serviceId',
    },
    IVRGreeting: {
      Index: '/ivr-greeting',
      New: 'new',
      Edit: 'edit/:greetingId',
    },
    CaseGreeting: {
      Index: '/case-greeting',
    },
    Permission: {
      Index: '/permission',
      New: 'new',
      Edit: 'edit/:roleId',
    },
    Billing: {
      History: '/billing-history',
      Overview: '/billing-overview',
      Details: '/billing-history/:invoiceId',
    },
    CaseNotifications: { Index: '/case-notifications' },
    SchedulingMatrix: {
      Index: `/${schedulingMatrix}`,
      Create: `${schedulingMatrix}/new`,
    },
    PetOwnerSubscriptions: {
      Index: `${poSubscriptions}`,
      Create: 'new',
      Edit: ':id',
    },
  },
};

const LineTesting = {
  Home: '/line-testing',
};

const Team = {
  Home: team,
  New: 'new',
  Details: ':id',
  List: {
    Team: '/list',
    Analytics: '/analytics',
  },
};

const Feedback = {
  Home: '/feedback',
};

const CustomerFeedback = {
  Home: '/customer-feedback',
};

const Analytics = {
  Home: '/analytics',
  CommLog: {
    Home: 'communication-log',
    Performance: 'performance',
    History: 'history',
  },
  Team: {
    Home: 'team',
    AllCalls: 'all/calls',
    AllShifts: 'all/shifts',
    AllSubmitted: 'all/submitted',
  },
};

const Profile = {
  Home: '/profile',
  Settings: {
    Index: '/settings',
    Edit: 'edit',
  },
  Cards: {
    Index: '/cards',
    New: 'new',
  },
  Subscription: '/subscription',
  Notifications: '/notifications',
};

const ActionCenter = {
  Home: actionCenter,
  List: {
    InQueue: '/queue',
    Active: '/active',
    TeamWorkload: '/team',
    MyChats: '/my-chats',
  },
  Comm: `${actionCenter}/comm`,
  InComm: {
    AddClient: '/add-client',
    EditClient: '/edit-client',
    Clients: '/clients',
    NewPet: '/pet/new',
    EditPet: '/pet/:petId',
    PetMedicalRecords: '/pet/:petId/medical-records',
    SearchClient: '/search-client',
    Addend: {
      Home: '/addend',
      Details: '/:caseId',
    },
  },
};

const Tasks = {
  Home: tasks,
  Details: {
    Log: 'log',
    Case: 'case',
    Summary: 'summary',
    Invoices: 'invoices',
    Addendums: 'addendums',
  },
};

const Calendar = {
  Home: calendar,
};

const HelpCenter = {
  Home: helpCenter,
  Coda: '/coda',
};

const Office = {
  Home: office,
  Contacts: { Home: contacts, Details: ':id' },
  Explore: { Home: explore, Favorites: 'favorites' },
  Marketing: {
    Home: marketing,
    Details: 'details/:id',
    New: 'new',
    Edit: ':id/edit',
    Subscribers: {
      Home: 'subscribers',
      Details: ':id',
    },
  },
  Inbox: {
    Home: inbox,
    MyTasks: 'my-tasks',
    Mentions: 'mentions',

    CustomersQueue: 'customers-queue',
    UnassignedTasks: 'unassigned-tasks',
    AllConversations: 'all-conversations',
    Chat: {
      Info: 'info',
      Home: '/chat',
      Video: '/video',
      New: '/chat/new',
      Details: '/:chatId',
    },
    Group: {
      New: 'group/new',
    },
    Channel: {
      New: 'channel/new',
    },
  },
};

const MasterRecords = {
  Home: masterRecords,
  Species: '/species',
  Breeds: '/breeds',
  Genders: '/genders',
  States: '/states',
  Timezones: '/timezones',
  Medications: '/medications',
  ChiefComplaints: '/chief-complaints',
  DynamicFlows: {
    Home: dynamicFlow,
    Edit: `${formBuilder}/:dynamicFormId`,
  },
};
const BitwerxMasterRecords = {
  Home: masterRecords,
  Species: '/species/bitwerx',
  Breeds: '/breeds/bitwerx',
  Genders: '/genders/bitwerx',
  Rooms: '/rooms/bitwerx',
  Providers: '/providers/bitwerx',
  Employes: '/employes/bitwerx',
};
const RingGroups = {
  Home: ringGroups,
};

const Recordings = {
  Home: recordings,
};

const IVR = {
  Home: ivr,
  New: 'new',
  Edit: ':id/edit',
};

const CallNotifications = {
  Home: callNotifications,
};

const Dashboard = {
  Home: '/dashboard',
  GVAfterHours: 'gv-after-hours',
  EmergencyCare: 'emergency-care',
  VirtualFrontDesk: 'virtual-front-desk',
  Upgrade: {
    Home: 'upgrade',
    Advanced: 'advanced',
    Professional: 'professional',
  },
};

const InternalTeamTasks = {
  Home: '/internal-tasks',
  Details: {
    Summary: 'summary',
    Comments: 'comments',
  },
};

const PetOwnerDashboard = {
  base: '/:hospitalId',
  Home: '/',
  Subscribe: {
    Home: 'subscribe',
    Pay: ':id',
  },
  Appointment: {
    Home: 'appointment',
    AddPet: 'add-pet',
  },
  FrontDesk: {
    Home: 'front-desk',
  },
  RxRefill: {
    Home: 'rx-refill',
  },
  VirtualExam: {
    AddPet: 'add-pet',
    Home: 'virtual-exam',
  },
  MedicalRecords: {
    Home: 'medical-records',
  },
};

const Invoices = {
  Home: invoices,
  Details: ':id',
};

const Subscriptions = {
  Edit: 'edit/:id',
  Create: 'create',
  Home: subscriptions,
  Tabs: {
    Discount: '/discount',
    PackageTemplates: '/package-templates',
  },
};

export const Routes = {
  IVR,
  Auth,
  Team,
  Cases,
  Tasks,
  Office,
  Profile,
  Clients,
  Outbound,
  Feedback,
  CustomerFeedback,
  Calendar,
  Invoices,
  Dashboard,
  Home: '/',
  Hospitals,
  Analytics,
  RingGroups,
  HelpCenter,
  Recordings,
  ActionItems,
  LineTesting,
  ActionCenter,
  MasterRecords,
  Subscriptions,
  PetOwnerDashboard,
  InternalTeamTasks,
  CallNotifications,
  BitwerxMasterRecords,
  NotFound: '/not-found',
};
